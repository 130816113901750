<template>
  <label>
    <slot></slot>
    <i v-if="help" class="fas fa-question-circle" v-tooltip.top="help"></i>
  </label>
</template>

<script>
export default {
  props: {
    help: null
  }
};
</script>

<style></style>
