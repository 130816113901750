import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import { getLocalStorageState } from "../shared";

const isDebug = true;

const requiresValidEmail = function(to, from, next) {
  next();
};

const requiresRegistrationType = function(to, from, next) {
  if (isDebug) {
    next();
    return;
  }

  if (store.getters.hasRegistrationType) {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/edl/:userData",
    name: "edevlet-login",
    component: () => import("../views/EmailValidation.vue")
  },
  {
    path: "/validation/:stateId",
    name: "email-validation",
    component: () => import("../views/EmailValidation.vue"),
    beforeEnter: requiresRegistrationType
  },
  {
    path: "/check-membership/:stateId",
    name: "check-membership",
    component: () => import("../views/CheckMembership.vue"),
    beforeEnter: requiresValidEmail
  },
  {
    path: "/choose-association/:stateId",
    name: "choose-association",
    component: () => import("../views/ChooseAssociation.vue"),
    beforeEnter: function(to, from, next) {
      if (isDebug) {
        next();
        return;
      }

      const appData = store.getters.getApplicationData;

      if (appData.email && appData.registrationType && appData.taxNumber) {
        next();
        return;
      }

      if (appData.email && appData.registrationType) {
        next("/check-membership");
        return;
      }

      next("/");
    }
  },
  {
    path: "/applicant/:stateId",
    name: "applicant",
    component: () => import("../views/Applicant.vue")
  },
  {
    path: "/company-info/:stateId",
    name: "company-info",
    component: () => import("../views/CompanyInfo.vue")
  },
  {
    path: "/company-address/:stateId",
    name: "company-address",
    redirect: (to) => {
      return "/company-address-hq-" + to.params.stateId;
    }
  },
  {
    name: "company-address-with-param",
    path: "/company-address-:addressType/:stateId",
    component: () => import("../views/CompanyAddress.vue"),
    beforeEnter: (to, from, next) => {
      if (to.params.addressType != "hq" && to.params.addressType != "branch") {
        next("/company-address-hq/" + to.params.stateId);
        return;
      }

      next();
    }
  },
  {
    path: "/company-contacts/:stateId",
    name: "company-contacts",
    component: () => import("../views/CompanyContacts.vue")
  },
  {
    path: "/notification-settings/:stateId",
    name: "notification-settings",
    component: () => import("../views/NotificationSettings.vue"),
    beforeEnter: (to, from, next) => {
      const appData = store.getters.getApplicationData;

      const rc = appData.companyInfo?.companyType?.requiredContacts || [];

      if (rc.length === 0) {
        next("/review/" + to.params.stateId);
      } else {
        next();
      }
    }
  },
  {
    path: "/review/:stateId",
    name: "review",
    component: () => import("../views/Review.vue")
  },
  {
    path: "/status/:stateId",
    name: "status",
    component: () => import("../views/Status.vue")
  },
  {
    path: "/result/:stateId",
    name: "result",
    component: () => import("../views/Result.vue")
  },
  {
    path: "/upload/:stateId",
    name: "upload",
    component: () => import("../views/Upload.vue")
  },
  {
    path: "/continue/:applicationCode",
    name: "continue-with-application-code",
    component: () => import("../views/Continue.vue")
  },
  {
    path: "/continue",
    name: "continue",
    component: () => import("../views/Continue.vue")
  },
  {
    path: "/start/:payload",
    name: "start",
    component: Home
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/Test.vue")
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
    //console.log("scroll", savedPosition);
    // return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const appData = store.getters.getApplicationData;

  let fromSavedState = false;

  if (!appData.stateId && to.params.stateId) {
    const savedState = getLocalStorageState(to.params.stateId);

    if (savedState) {
      fromSavedState = true;
      store.dispatch("updateApplicationData", {
        ...savedState
      });
    }
  }

  store.dispatch("updateApplicationData", {
    currentStep: {
      path: to.path,
      view: 0
    },
    previousStep: fromSavedState
      ? appData.previousStep
      : {
          path: from.path,
          view: 0
        }
  });
  next();
});

//const anonymousRoutes = ["home", "email-validation"];

export default router;
