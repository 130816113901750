<template>
  <card>
    <template #pre v-if="showProgress">
      <div class="progress-wrapper">
        <p-progress-bar :value="progress" :showValue="false"></p-progress-bar>
      </div>
    </template>
    <title-desc :title="title" :class="{ [titleClass]: true }">
      <slot name="description"></slot>
    </title-desc>
    <slot></slot>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import Card from "@/components/UI/Card.vue";
import TitleDesc from "@/components/UI/TitleDesc.vue";
import PProgressBar from "primevue/progressbar";

export default {
  name: "CardWizard",
  components: {
    TitleDesc,
    Card,
    PProgressBar
  },
  props: {
    title: String,
    titleClass: String,
    showProgress: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      progress: "getProgress"
    })
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/variables";
@import "../../scss/mixins";

.progress-wrapper {
  overflow: hidden;
  border-top-right-radius: $border-radius-lg;
  border-top-left-radius: $border-radius-lg;

  .p-progressbar {
    border-radius: 0;
    height: 14px;
  }
}
</style>
