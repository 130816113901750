<template>
  <div class="d-flex justify-content-between app-wizard-buttons mt-5">
    <button
      type="button"
      :class="[
        'btn btn-outline-turquoise',
        prevButtonIsLoading ? 'btn-loading' : ''
      ]"
      :disabled="prevButtonDisabled"
      v-if="prevButtonVisible"
      @click="prevButtonClick"
    >
      <span>{{ prevButtonText }}</span>
    </button>
    <div>&nbsp;</div>
    <button
      :class="['btn btn-primary', nextButtonIsLoading ? 'btn-loading' : '']"
      :disabled="nextButtonDisabled"
      v-if="nextButtonVisible"
      @click="nextButtonClick"
    >
      <span>{{ nextButtonText }}</span>

      <p-progress-spinner
        v-show="nextButtonIsLoading"
        style="width:18px;height:18px; margin-left:10px;"
        strokeWidth="4px"
        animationDuration=".5s"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "WizardButtons",
  props: {
    prevButtonText: {
      type: String,
      default: "Önceki Adıma Geri Dön"
    },
    prevButtonDisabled: {
      type: Boolean,
      default: false
    },
    prevButtonVisible: {
      type: Boolean,
      default: true
    },
    prevButtonIsLoading: {
      type: Boolean,
      default: false
    },
    nextButtonText: {
      type: String,
      default: "Devam Et"
    },
    nextButtonDisabled: {
      type: Boolean,
      default: false
    },
    nextButtonVisible: {
      type: Boolean,
      default: true
    },
    nextButtonIsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  emits: ["next-button-click", "prev-button-click"],
  methods: {
    prevButtonClick() {
      this.$emit("prev-button-click");
    },
    nextButtonClick() {
      this.$emit("next-button-click");
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/_variables";

.app-wizard-buttons {
  button {
    display: flex;
    align-items: center;

    &.btn-loading {
    }
    .p-progress-spinner-circle {
      animation-name: p-progress-spinner-dash, p-progress-spinner-white;
    }
  }

  @keyframes p-progress-spinner-white {
    0%,
    100% {
      stroke: #ffd;
    }
  }
}
</style>
