import { Base64 } from "js-base64";

// locale specific chars
// IMPORTANT: name of locale must be always in lower case (for "tr-TR" locale - "tr-tr") !!!
var localeInfos = {
    "tr-tr": {
      lower: { i: "İ", ı: "I", ş: "Ş", ğ: "Ğ", ü: "Ü", ç: "Ç", ö: "Ö" },
      upper: { İ: "i", I: "ı", Ş: "ş", Ğ: "ğ", Ü: "ü", Ç: "ç", Ö: "ö" }
    }
  },
  localeInfo;
// helper vars
const mask = "\\s:\\-", // add additional delimiters chars to the mask if needed
  rg = new RegExp("([^" + mask + "])([^" + mask + "]*)", "g");

var fnToLocaleLower = function(s) {
    return localeInfo.upper[s];
  },
  fnToLocaleUpper = function(s) {
    return localeInfo.lower[s];
  },
  fnToProper = function($0, $1, $2) {
    if (localeInfo) {
      if (localeInfo.lower.hasOwnProperty.call($1)) $1 = localeInfo.lower[$1];
      $2 = $2.replace(localeInfo.upperSearchRegExp, fnToLocaleLower);
    }
    return $1.toUpperCase() + $2.toLowerCase();
  };
// helper calculations
var localeInfosKeys = Object.keys(localeInfos);

for (let i = 0; (localeInfo = localeInfos[localeInfosKeys[i]]); i++) {
  localeInfo.lowerSearchRegExp = new RegExp(
    "[" + Object.keys(localeInfo.lower).join("") + "]",
    "g"
  );
  localeInfo.upperSearchRegExp = new RegExp(
    "[" + Object.keys(localeInfo.upper).join("") + "]",
    "g"
  );
}

export const defaultLocale = "tr-tr";

// extending String.prototype
export const toLocaleProperCase = function(val, locale) {
  localeInfo = localeInfos[locale || defaultLocale];
  return val.replace(rg, fnToProper);
};

export const toLocaleLowerCase = function(val, locale) {
  return (localeInfo == localeInfos[locale || defaultLocale]
    ? val.replace(localeInfo.upperSearchRegExp, fnToLocaleLower)
    : val
  ).toLowerCase();
};

export const toLocaleUpperCase = function(val, locale) {
  return (localeInfo == localeInfos[locale || defaultLocale]
    ? val.replace(localeInfo.lowerSearchRegExp, fnToLocaleUpper)
    : val
  ).toUpperCase();
};

export const slugify = (text) => {
  return text
    .toString()
    .replace(/ı/g, "i") // Replace Turkish I with i
    .replace(/I/g, "i") // Replace Turkish I with i
    .replace(/İ/g, "i") // Replace Turkish I with i
    .normalize("NFD") // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");
};

export const stripNumbers = (text) => text.replace(/\D/g, "");

export const makeId = (length) => {
  length = length || 12;
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getEnvironment = () => {
  const hostName = window.location.hostname.toLowerCase();

  if (hostName === "localhost") return "dev";
  if (hostName.startsWith("staging")) return "staging";
  return "production";
};

export const decodeBase64 = base64encodedText => {
  return Base64.decode(base64encodedText);
};

export const encodeBase64 = textToEncode => {
  return Base64.encode(textToEncode);
};
