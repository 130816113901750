<template>
  <div class="title-desc">
    <div class="title">{{ title }}</div>
    <div class="desc"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "TitleDesc",
  props: {
    title: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../scss/_variables";

.title-desc {
  .title {
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
    font-size: 24px;
    color: $primary-alt;
    margin-bottom: 10px;
  }

  &.lg {
    .title {
      font-weight: $font-weight-extra-bold;
      font-size: 32px;
      margin-bottom: 20px;
    }
  }

  &.sm {
    .title {
      font-size: 22px;
    }
  }

  &.dark {
    .title {
      color: #000;
    }
  }

  &.gold {
    .title {
      color: $gold;
    }
  }

  &.turquoise {
    .title {
      color: $turquoise;
    }
  }
}
</style>
