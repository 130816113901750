const counties = [
  {
    id: "1757",
    cityId: "01",
    name: "Aladağ",
    slug: "aladag"
  },
  {
    id: "1219",
    cityId: "01",
    name: "Ceyhan",
    slug: "ceyhan"
  },
  {
    id: "2033",
    cityId: "01",
    name: "Çukurova",
    slug: "cukurova"
  },
  {
    id: "1329",
    cityId: "01",
    name: "Feke",
    slug: "feke"
  },
  {
    id: "1806",
    cityId: "01",
    name: "İmamoğlu",
    slug: "imamoglu"
  },
  {
    id: "1437",
    cityId: "01",
    name: "Karaisalı",
    slug: "karaisali"
  },
  {
    id: "1443",
    cityId: "01",
    name: "Karataş",
    slug: "karatas"
  },
  {
    id: "1486",
    cityId: "01",
    name: "Kozan",
    slug: "kozan"
  },
  {
    id: "1580",
    cityId: "01",
    name: "Pozantı",
    slug: "pozanti"
  },
  {
    id: "1588",
    cityId: "01",
    name: "Saimbeyli",
    slug: "saimbeyli"
  },
  {
    id: "2032",
    cityId: "01",
    name: "Sarıçam",
    slug: "saricam"
  },
  {
    id: "1104",
    cityId: "01",
    name: "Seyhan",
    slug: "seyhan"
  },
  {
    id: "1687",
    cityId: "01",
    name: "Tufanbeyli",
    slug: "tufanbeyli"
  },
  {
    id: "1734",
    cityId: "01",
    name: "Yumurtalık",
    slug: "yumurtalik"
  },
  {
    id: "1748",
    cityId: "01",
    name: "Yüreğir",
    slug: "yuregir"
  },
  {
    id: "1182",
    cityId: "02",
    name: "Besni",
    slug: "besni"
  },
  {
    id: "1246",
    cityId: "02",
    name: "Çelikhan",
    slug: "celikhan"
  },
  {
    id: "1347",
    cityId: "02",
    name: "Gerger",
    slug: "gerger"
  },
  {
    id: "1354",
    cityId: "02",
    name: "Gölbaşı",
    slug: "golbasi"
  },
  {
    id: "1425",
    cityId: "02",
    name: "Kahta",
    slug: "kahta"
  },
  {
    id: "1105",
    cityId: "02",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1592",
    cityId: "02",
    name: "Samsat",
    slug: "samsat"
  },
  {
    id: "1985",
    cityId: "02",
    name: "Sincik",
    slug: "sincik"
  },
  {
    id: "1989",
    cityId: "02",
    name: "Tut",
    slug: "tut"
  },
  {
    id: "1771",
    cityId: "03",
    name: "Başmakçı",
    slug: "basmakci"
  },
  {
    id: "1773",
    cityId: "03",
    name: "Bayat",
    slug: "bayat"
  },
  {
    id: "1200",
    cityId: "03",
    name: "Bolvadin",
    slug: "bolvadin"
  },
  {
    id: "1239",
    cityId: "03",
    name: "Çay",
    slug: "cay"
  },
  {
    id: "1906",
    cityId: "03",
    name: "Çobanlar",
    slug: "cobanlar"
  },
  {
    id: "1267",
    cityId: "03",
    name: "Dazkırı",
    slug: "dazkiri"
  },
  {
    id: "1281",
    cityId: "03",
    name: "Dinar",
    slug: "dinar"
  },
  {
    id: "1306",
    cityId: "03",
    name: "Emirdağ",
    slug: "emirdag"
  },
  {
    id: "1923",
    cityId: "03",
    name: "Evciler",
    slug: "evciler"
  },
  {
    id: "1944",
    cityId: "03",
    name: "Hocalar",
    slug: "hocalar"
  },
  {
    id: "1404",
    cityId: "03",
    name: "İhsaniye",
    slug: "ihsaniye"
  },
  {
    id: "1809",
    cityId: "03",
    name: "İscehisar",
    slug: "iscehisar"
  },
  {
    id: "1961",
    cityId: "03",
    name: "Kızılören",
    slug: "kiziloren"
  },
  {
    id: "1108",
    cityId: "03",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1594",
    cityId: "03",
    name: "Sandıklı",
    slug: "sandikli"
  },
  {
    id: "1626",
    cityId: "03",
    name: "Sinanpaşa",
    slug: "sinanpasa"
  },
  {
    id: "1639",
    cityId: "03",
    name: "Sultandağı",
    slug: "sultandagi"
  },
  {
    id: "1664",
    cityId: "03",
    name: "Şuhut",
    slug: "suhut"
  },
  {
    id: "1283",
    cityId: "04",
    name: "Diyadin",
    slug: "diyadin"
  },
  {
    id: "1287",
    cityId: "04",
    name: "Doğubayazıt",
    slug: "dogubayazit"
  },
  {
    id: "1301",
    cityId: "04",
    name: "Eleşkirt",
    slug: "eleskirt"
  },
  {
    id: "1379",
    cityId: "04",
    name: "Hamur",
    slug: "hamur"
  },
  {
    id: "1111",
    cityId: "04",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1568",
    cityId: "04",
    name: "Patnos",
    slug: "patnos"
  },
  {
    id: "1667",
    cityId: "04",
    name: "Taşlıçay",
    slug: "taslicay"
  },
  {
    id: "1691",
    cityId: "04",
    name: "Tutak",
    slug: "tutak"
  },
  {
    id: "1363",
    cityId: "05",
    name: "Göynücek",
    slug: "goynucek"
  },
  {
    id: "1368",
    cityId: "05",
    name: "Gümüşhacıköy",
    slug: "gumushacikoy"
  },
  {
    id: "1938",
    cityId: "05",
    name: "Hamamözü",
    slug: "hamamozu"
  },
  {
    id: "1134",
    cityId: "05",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1524",
    cityId: "05",
    name: "Merzifon",
    slug: "merzifon"
  },
  {
    id: "1641",
    cityId: "05",
    name: "Suluova",
    slug: "suluova"
  },
  {
    id: "1668",
    cityId: "05",
    name: "Taşova",
    slug: "tasova"
  },
  {
    id: "1872",
    cityId: "06",
    name: "Akyurt",
    slug: "akyurt"
  },
  {
    id: "1130",
    cityId: "06",
    name: "Altındağ",
    slug: "altindag"
  },
  {
    id: "1157",
    cityId: "06",
    name: "Ayaş",
    slug: "ayas"
  },
  {
    id: "1167",
    cityId: "06",
    name: "Bala",
    slug: "bala"
  },
  {
    id: "1187",
    cityId: "06",
    name: "Beypazarı",
    slug: "beypazari"
  },
  {
    id: "1227",
    cityId: "06",
    name: "Çamlıdere",
    slug: "camlidere"
  },
  {
    id: "1231",
    cityId: "06",
    name: "Çankaya",
    slug: "cankaya"
  },
  {
    id: "1260",
    cityId: "06",
    name: "Çubuk",
    slug: "cubuk"
  },
  {
    id: "1302",
    cityId: "06",
    name: "Elmadağ",
    slug: "elmadag"
  },
  {
    id: "1922",
    cityId: "06",
    name: "Etimesgut",
    slug: "etimesgut"
  },
  {
    id: "1924",
    cityId: "06",
    name: "Evren",
    slug: "evren"
  },
  {
    id: "1744",
    cityId: "06",
    name: "Gölbaşı",
    slug: "golbasi"
  },
  {
    id: "1365",
    cityId: "06",
    name: "Güdül",
    slug: "gudul"
  },
  {
    id: "1387",
    cityId: "06",
    name: "Haymana",
    slug: "haymana"
  },
  {
    id: "1815",
    cityId: "06",
    name: "Kahramankazan",
    slug: "kahramankazan"
  },
  {
    id: "1427",
    cityId: "06",
    name: "Kalecik",
    slug: "kalecik"
  },
  {
    id: "1745",
    cityId: "06",
    name: "Keçiören",
    slug: "kecioren"
  },
  {
    id: "1473",
    cityId: "06",
    name: "Kızılcahamam",
    slug: "kizilcahamam"
  },
  {
    id: "1746",
    cityId: "06",
    name: "Mamak",
    slug: "mamak"
  },
  {
    id: "1539",
    cityId: "06",
    name: "Nallıhan",
    slug: "nallihan"
  },
  {
    id: "1578",
    cityId: "06",
    name: "Polatlı",
    slug: "polatli"
  },
  {
    id: "2034",
    cityId: "06",
    name: "Pursaklar",
    slug: "pursaklar"
  },
  {
    id: "1747",
    cityId: "06",
    name: "Sincan",
    slug: "sincan"
  },
  {
    id: "1658",
    cityId: "06",
    name: "Şereflikoçhisar",
    slug: "sereflikochisar"
  },
  {
    id: "1723",
    cityId: "06",
    name: "Yenimahalle",
    slug: "yenimahalle"
  },
  {
    id: "1121",
    cityId: "07",
    name: "Akseki",
    slug: "akseki"
  },
  {
    id: "2035",
    cityId: "07",
    name: "Aksu",
    slug: "aksu"
  },
  {
    id: "1126",
    cityId: "07",
    name: "Alanya",
    slug: "alanya"
  },
  {
    id: "1811",
    cityId: "07",
    name: "Demre",
    slug: "demre"
  },
  {
    id: "2036",
    cityId: "07",
    name: "Döşemealtı",
    slug: "dosemealti"
  },
  {
    id: "1303",
    cityId: "07",
    name: "Elmalı",
    slug: "elmali"
  },
  {
    id: "1333",
    cityId: "07",
    name: "Finike",
    slug: "finike"
  },
  {
    id: "1337",
    cityId: "07",
    name: "Gazipaşa",
    slug: "gazipasa"
  },
  {
    id: "1370",
    cityId: "07",
    name: "Gündoğmuş",
    slug: "gundogmus"
  },
  {
    id: "1946",
    cityId: "07",
    name: "İbradı",
    slug: "ibradi"
  },
  {
    id: "1451",
    cityId: "07",
    name: "Kaş",
    slug: "kas"
  },
  {
    id: "1959",
    cityId: "07",
    name: "Kemer",
    slug: "kemer"
  },
  {
    id: "2037",
    cityId: "07",
    name: "Kepez",
    slug: "kepez"
  },
  {
    id: "2038",
    cityId: "07",
    name: "Konyaaltı",
    slug: "konyaalti"
  },
  {
    id: "1483",
    cityId: "07",
    name: "Korkuteli",
    slug: "korkuteli"
  },
  {
    id: "1492",
    cityId: "07",
    name: "Kumluca",
    slug: "kumluca"
  },
  {
    id: "1512",
    cityId: "07",
    name: "Manavgat",
    slug: "manavgat"
  },
  {
    id: "2039",
    cityId: "07",
    name: "Muratpaşa",
    slug: "muratpasa"
  },
  {
    id: "1616",
    cityId: "07",
    name: "Serik",
    slug: "serik"
  },
  {
    id: "1145",
    cityId: "08",
    name: "Ardanuç",
    slug: "ardanuc"
  },
  {
    id: "1147",
    cityId: "08",
    name: "Arhavi",
    slug: "arhavi"
  },
  {
    id: "1202",
    cityId: "08",
    name: "Borçka",
    slug: "borcka"
  },
  {
    id: "1395",
    cityId: "08",
    name: "Hopa",
    slug: "hopa"
  },
  {
    id: "2105",
    cityId: "08",
    name: "Kemalpaşa",
    slug: "kemalpasa"
  },
  {
    id: "1152",
    cityId: "08",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1828",
    cityId: "08",
    name: "Murgul",
    slug: "murgul"
  },
  {
    id: "1653",
    cityId: "08",
    name: "Şavşat",
    slug: "savsat"
  },
  {
    id: "1736",
    cityId: "08",
    name: "Yusufeli",
    slug: "yusufeli"
  },
  {
    id: "1206",
    cityId: "09",
    name: "Bozdoğan",
    slug: "bozdogan"
  },
  {
    id: "1781",
    cityId: "09",
    name: "Buharkent",
    slug: "buharkent"
  },
  {
    id: "1256",
    cityId: "09",
    name: "Çine",
    slug: "cine"
  },
  {
    id: "2000",
    cityId: "09",
    name: "Didim",
    slug: "didim"
  },
  {
    id: "2076",
    cityId: "09",
    name: "Efeler",
    slug: "efeler"
  },
  {
    id: "1348",
    cityId: "09",
    name: "Germencik",
    slug: "germencik"
  },
  {
    id: "1807",
    cityId: "09",
    name: "İncirliova",
    slug: "incirliova"
  },
  {
    id: "1435",
    cityId: "09",
    name: "Karacasu",
    slug: "karacasu"
  },
  {
    id: "1957",
    cityId: "09",
    name: "Karpuzlu",
    slug: "karpuzlu"
  },
  {
    id: "1479",
    cityId: "09",
    name: "Koçarlı",
    slug: "kocarli"
  },
  {
    id: "1968",
    cityId: "09",
    name: "Köşk",
    slug: "kosk"
  },
  {
    id: "1497",
    cityId: "09",
    name: "Kuşadası",
    slug: "kusadasi"
  },
  {
    id: "1498",
    cityId: "09",
    name: "Kuyucak",
    slug: "kuyucak"
  },
  {
    id: "1542",
    cityId: "09",
    name: "Nazilli",
    slug: "nazilli"
  },
  {
    id: "1637",
    cityId: "09",
    name: "Söke",
    slug: "soke"
  },
  {
    id: "1640",
    cityId: "09",
    name: "Sultanhisar",
    slug: "sultanhisar"
  },
  {
    id: "1724",
    cityId: "09",
    name: "Yenipazar",
    slug: "yenipazar"
  },
  {
    id: "2077",
    cityId: "10",
    name: "Altıeylül",
    slug: "altieylul"
  },
  {
    id: "1161",
    cityId: "10",
    name: "Ayvalık",
    slug: "ayvalik"
  },
  {
    id: "1169",
    cityId: "10",
    name: "Balya",
    slug: "balya"
  },
  {
    id: "1171",
    cityId: "10",
    name: "Bandırma",
    slug: "bandirma"
  },
  {
    id: "1191",
    cityId: "10",
    name: "Bigadiç",
    slug: "bigadic"
  },
  {
    id: "1216",
    cityId: "10",
    name: "Burhaniye",
    slug: "burhaniye"
  },
  {
    id: "1291",
    cityId: "10",
    name: "Dursunbey",
    slug: "dursunbey"
  },
  {
    id: "1294",
    cityId: "10",
    name: "Edremit",
    slug: "edremit"
  },
  {
    id: "1310",
    cityId: "10",
    name: "Erdek",
    slug: "erdek"
  },
  {
    id: "1928",
    cityId: "10",
    name: "Gömeç",
    slug: "gomec"
  },
  {
    id: "1360",
    cityId: "10",
    name: "Gönen",
    slug: "gonen"
  },
  {
    id: "1384",
    cityId: "10",
    name: "Havran",
    slug: "havran"
  },
  {
    id: "1418",
    cityId: "10",
    name: "İvrindi",
    slug: "ivrindi"
  },
  {
    id: "2078",
    cityId: "10",
    name: "Karesi",
    slug: "karesi"
  },
  {
    id: "1462",
    cityId: "10",
    name: "Kepsut",
    slug: "kepsut"
  },
  {
    id: "1514",
    cityId: "10",
    name: "Manyas",
    slug: "manyas"
  },
  {
    id: "1824",
    cityId: "10",
    name: "Marmara",
    slug: "marmara"
  },
  {
    id: "1608",
    cityId: "10",
    name: "Savaştepe",
    slug: "savastepe"
  },
  {
    id: "1619",
    cityId: "10",
    name: "Sındırgı",
    slug: "sindirgi"
  },
  {
    id: "1644",
    cityId: "10",
    name: "Susurluk",
    slug: "susurluk"
  },
  {
    id: "1210",
    cityId: "11",
    name: "Bozüyük",
    slug: "bozuyuk"
  },
  {
    id: "1359",
    cityId: "11",
    name: "Gölpazarı",
    slug: "golpazari"
  },
  {
    id: "1948",
    cityId: "11",
    name: "İnhisar",
    slug: "inhisar"
  },
  {
    id: "1192",
    cityId: "11",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1559",
    cityId: "11",
    name: "Osmaneli",
    slug: "osmaneli"
  },
  {
    id: "1571",
    cityId: "11",
    name: "Pazaryeri",
    slug: "pazaryeri"
  },
  {
    id: "1636",
    cityId: "11",
    name: "Söğüt",
    slug: "sogut"
  },
  {
    id: "1857",
    cityId: "11",
    name: "Yenipazar",
    slug: "yenipazar"
  },
  {
    id: "1750",
    cityId: "12",
    name: "Adaklı",
    slug: "adakli"
  },
  {
    id: "1344",
    cityId: "12",
    name: "Genç",
    slug: "genc"
  },
  {
    id: "1446",
    cityId: "12",
    name: "Karlıova",
    slug: "karliova"
  },
  {
    id: "1475",
    cityId: "12",
    name: "Kiğı",
    slug: "kigi"
  },
  {
    id: "1193",
    cityId: "12",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1633",
    cityId: "12",
    name: "Solhan",
    slug: "solhan"
  },
  {
    id: "1855",
    cityId: "12",
    name: "Yayladere",
    slug: "yayladere"
  },
  {
    id: "1996",
    cityId: "12",
    name: "Yedisu",
    slug: "yedisu"
  },
  {
    id: "1106",
    cityId: "13",
    name: "Adilcevaz",
    slug: "adilcevaz"
  },
  {
    id: "1112",
    cityId: "13",
    name: "Ahlat",
    slug: "ahlat"
  },
  {
    id: "1798",
    cityId: "13",
    name: "Güroymak",
    slug: "guroymak"
  },
  {
    id: "1394",
    cityId: "13",
    name: "Hizan",
    slug: "hizan"
  },
  {
    id: "1196",
    cityId: "13",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1537",
    cityId: "13",
    name: "Mutki",
    slug: "mutki"
  },
  {
    id: "1669",
    cityId: "13",
    name: "Tatvan",
    slug: "tatvan"
  },
  {
    id: "1916",
    cityId: "14",
    name: "Dörtdivan",
    slug: "dortdivan"
  },
  {
    id: "1346",
    cityId: "14",
    name: "Gerede",
    slug: "gerede"
  },
  {
    id: "1364",
    cityId: "14",
    name: "Göynük",
    slug: "goynuk"
  },
  {
    id: "1466",
    cityId: "14",
    name: "Kıbrıscık",
    slug: "kibriscik"
  },
  {
    id: "1522",
    cityId: "14",
    name: "Mengen",
    slug: "mengen"
  },
  {
    id: "1199",
    cityId: "14",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1531",
    cityId: "14",
    name: "Mudurnu",
    slug: "mudurnu"
  },
  {
    id: "1610",
    cityId: "14",
    name: "Seben",
    slug: "seben"
  },
  {
    id: "1997",
    cityId: "14",
    name: "Yeniçağa",
    slug: "yenicaga"
  },
  {
    id: "1109",
    cityId: "15",
    name: "Ağlasun",
    slug: "aglasun"
  },
  {
    id: "1874",
    cityId: "15",
    name: "Altınyayla",
    slug: "altinyayla"
  },
  {
    id: "1211",
    cityId: "15",
    name: "Bucak",
    slug: "bucak"
  },
  {
    id: "1899",
    cityId: "15",
    name: "Çavdır",
    slug: "cavdir"
  },
  {
    id: "1903",
    cityId: "15",
    name: "Çeltikçi",
    slug: "celtikci"
  },
  {
    id: "1357",
    cityId: "15",
    name: "Gölhisar",
    slug: "golhisar"
  },
  {
    id: "1813",
    cityId: "15",
    name: "Karamanlı",
    slug: "karamanli"
  },
  {
    id: "1816",
    cityId: "15",
    name: "Kemer",
    slug: "kemer"
  },
  {
    id: "1215",
    cityId: "15",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1672",
    cityId: "15",
    name: "Tefenni",
    slug: "tefenni"
  },
  {
    id: "1728",
    cityId: "15",
    name: "Yeşilova",
    slug: "yesilova"
  },
  {
    id: "1783",
    cityId: "16",
    name: "Büyükorhan",
    slug: "buyukorhan"
  },
  {
    id: "1343",
    cityId: "16",
    name: "Gemlik",
    slug: "gemlik"
  },
  {
    id: "1935",
    cityId: "16",
    name: "Gürsu",
    slug: "gursu"
  },
  {
    id: "1799",
    cityId: "16",
    name: "Harmancık",
    slug: "harmancik"
  },
  {
    id: "1411",
    cityId: "16",
    name: "İnegöl",
    slug: "inegol"
  },
  {
    id: "1420",
    cityId: "16",
    name: "İznik",
    slug: "iznik"
  },
  {
    id: "1434",
    cityId: "16",
    name: "Karacabey",
    slug: "karacabey"
  },
  {
    id: "1457",
    cityId: "16",
    name: "Keles",
    slug: "keles"
  },
  {
    id: "1960",
    cityId: "16",
    name: "Kestel",
    slug: "kestel"
  },
  {
    id: "1530",
    cityId: "16",
    name: "Mudanya",
    slug: "mudanya"
  },
  {
    id: "1535",
    cityId: "16",
    name: "Mustafakemalpaşa",
    slug: "mustafakemalpasa"
  },
  {
    id: "1829",
    cityId: "16",
    name: "Nilüfer",
    slug: "nilufer"
  },
  {
    id: "1553",
    cityId: "16",
    name: "Orhaneli",
    slug: "orhaneli"
  },
  {
    id: "1554",
    cityId: "16",
    name: "Orhangazi",
    slug: "orhangazi"
  },
  {
    id: "1832",
    cityId: "16",
    name: "Osmangazi",
    slug: "osmangazi"
  },
  {
    id: "1725",
    cityId: "16",
    name: "Yenişehir",
    slug: "yenisehir"
  },
  {
    id: "1859",
    cityId: "16",
    name: "Yıldırım",
    slug: "yildirim"
  },
  {
    id: "1160",
    cityId: "17",
    name: "Ayvacık",
    slug: "ayvacik"
  },
  {
    id: "1180",
    cityId: "17",
    name: "Bayramiç",
    slug: "bayramic"
  },
  {
    id: "1190",
    cityId: "17",
    name: "Biga",
    slug: "biga"
  },
  {
    id: "1205",
    cityId: "17",
    name: "Bozcaada",
    slug: "bozcaada"
  },
  {
    id: "1229",
    cityId: "17",
    name: "Çan",
    slug: "can"
  },
  {
    id: "1293",
    cityId: "17",
    name: "Eceabat",
    slug: "eceabat"
  },
  {
    id: "1326",
    cityId: "17",
    name: "Ezine",
    slug: "ezine"
  },
  {
    id: "1340",
    cityId: "17",
    name: "Gelibolu",
    slug: "gelibolu"
  },
  {
    id: "1408",
    cityId: "17",
    name: "Gökçeada",
    slug: "gokceada"
  },
  {
    id: "1503",
    cityId: "17",
    name: "Lapseki",
    slug: "lapseki"
  },
  {
    id: "1230",
    cityId: "17",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1722",
    cityId: "17",
    name: "Yenice",
    slug: "yenice"
  },
  {
    id: "1765",
    cityId: "18",
    name: "Atkaracalar",
    slug: "atkaracalar"
  },
  {
    id: "1885",
    cityId: "18",
    name: "Bayramören",
    slug: "bayramoren"
  },
  {
    id: "1248",
    cityId: "18",
    name: "Çerkeş",
    slug: "cerkes"
  },
  {
    id: "1300",
    cityId: "18",
    name: "Eldivan",
    slug: "eldivan"
  },
  {
    id: "1399",
    cityId: "18",
    name: "Ilgaz",
    slug: "ilgaz"
  },
  {
    id: "1817",
    cityId: "18",
    name: "Kızılırmak",
    slug: "kizilirmak"
  },
  {
    id: "1963",
    cityId: "18",
    name: "Korgun",
    slug: "korgun"
  },
  {
    id: "1494",
    cityId: "18",
    name: "Kurşunlu",
    slug: "kursunlu"
  },
  {
    id: "1232",
    cityId: "18",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1555",
    cityId: "18",
    name: "Orta",
    slug: "orta"
  },
  {
    id: "1649",
    cityId: "18",
    name: "Şabanözü",
    slug: "sabanozu"
  },
  {
    id: "1718",
    cityId: "18",
    name: "Yapraklı",
    slug: "yaprakli"
  },
  {
    id: "1124",
    cityId: "19",
    name: "Alaca",
    slug: "alaca"
  },
  {
    id: "1177",
    cityId: "19",
    name: "Bayat",
    slug: "bayat"
  },
  {
    id: "1778",
    cityId: "19",
    name: "Boğazkale",
    slug: "bogazkale"
  },
  {
    id: "1911",
    cityId: "19",
    name: "Dodurga",
    slug: "dodurga"
  },
  {
    id: "1414",
    cityId: "19",
    name: "İskilip",
    slug: "iskilip"
  },
  {
    id: "1445",
    cityId: "19",
    name: "Kargı",
    slug: "kargi"
  },
  {
    id: "1972",
    cityId: "19",
    name: "Laçin",
    slug: "lacin"
  },
  {
    id: "1520",
    cityId: "19",
    name: "Mecitözü",
    slug: "mecitozu"
  },
  {
    id: "1259",
    cityId: "19",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1976",
    cityId: "19",
    name: "Oğuzlar",
    slug: "oguzlar"
  },
  {
    id: "1556",
    cityId: "19",
    name: "Ortaköy",
    slug: "ortakoy"
  },
  {
    id: "1558",
    cityId: "19",
    name: "Osmancık",
    slug: "osmancik"
  },
  {
    id: "1642",
    cityId: "19",
    name: "Sungurlu",
    slug: "sungurlu"
  },
  {
    id: "1850",
    cityId: "19",
    name: "Uğurludağ",
    slug: "ugurludag"
  },
  {
    id: "1102",
    cityId: "20",
    name: "Acıpayam",
    slug: "acipayam"
  },
  {
    id: "1769",
    cityId: "20",
    name: "Babadağ",
    slug: "babadag"
  },
  {
    id: "1881",
    cityId: "20",
    name: "Baklan",
    slug: "baklan"
  },
  {
    id: "1774",
    cityId: "20",
    name: "Bekilli",
    slug: "bekilli"
  },
  {
    id: "1888",
    cityId: "20",
    name: "Beyağaç",
    slug: "beyagac"
  },
  {
    id: "1889",
    cityId: "20",
    name: "Bozkurt",
    slug: "bozkurt"
  },
  {
    id: "1214",
    cityId: "20",
    name: "Buldan",
    slug: "buldan"
  },
  {
    id: "1224",
    cityId: "20",
    name: "Çal",
    slug: "cal"
  },
  {
    id: "1226",
    cityId: "20",
    name: "Çameli",
    slug: "cameli"
  },
  {
    id: "1233",
    cityId: "20",
    name: "Çardak",
    slug: "cardak"
  },
  {
    id: "1257",
    cityId: "20",
    name: "Çivril",
    slug: "civril"
  },
  {
    id: "1371",
    cityId: "20",
    name: "Güney",
    slug: "guney"
  },
  {
    id: "1803",
    cityId: "20",
    name: "Honaz",
    slug: "honaz"
  },
  {
    id: "1426",
    cityId: "20",
    name: "Kale",
    slug: "kale"
  },
  {
    id: "2079",
    cityId: "20",
    name: "Merkezefendi",
    slug: "merkezefendi"
  },
  {
    id: "1871",
    cityId: "20",
    name: "Pamukkale",
    slug: "pamukkale"
  },
  {
    id: "1597",
    cityId: "20",
    name: "Sarayköy",
    slug: "saraykoy"
  },
  {
    id: "1840",
    cityId: "20",
    name: "Serinhisar",
    slug: "serinhisar"
  },
  {
    id: "1670",
    cityId: "20",
    name: "Tavas",
    slug: "tavas"
  },
  {
    id: "2040",
    cityId: "21",
    name: "Bağlar",
    slug: "baglar"
  },
  {
    id: "1195",
    cityId: "21",
    name: "Bismil",
    slug: "bismil"
  },
  {
    id: "1249",
    cityId: "21",
    name: "Çermik",
    slug: "cermik"
  },
  {
    id: "1253",
    cityId: "21",
    name: "Çınar",
    slug: "cinar"
  },
  {
    id: "1263",
    cityId: "21",
    name: "Çüngüş",
    slug: "cungus"
  },
  {
    id: "1278",
    cityId: "21",
    name: "Dicle",
    slug: "dicle"
  },
  {
    id: "1791",
    cityId: "21",
    name: "Eğil",
    slug: "egil"
  },
  {
    id: "1315",
    cityId: "21",
    name: "Ergani",
    slug: "ergani"
  },
  {
    id: "1381",
    cityId: "21",
    name: "Hani",
    slug: "hani"
  },
  {
    id: "1389",
    cityId: "21",
    name: "Hazro",
    slug: "hazro"
  },
  {
    id: "2041",
    cityId: "21",
    name: "Kayapınar",
    slug: "kayapinar"
  },
  {
    id: "1962",
    cityId: "21",
    name: "Kocaköy",
    slug: "kocakoy"
  },
  {
    id: "1490",
    cityId: "21",
    name: "Kulp",
    slug: "kulp"
  },
  {
    id: "1504",
    cityId: "21",
    name: "Lice",
    slug: "lice"
  },
  {
    id: "1624",
    cityId: "21",
    name: "Silvan",
    slug: "silvan"
  },
  {
    id: "2042",
    cityId: "21",
    name: "Sur",
    slug: "sur"
  },
  {
    id: "2043",
    cityId: "21",
    name: "Yenişehir",
    slug: "yenisehir"
  },
  {
    id: "1307",
    cityId: "22",
    name: "Enez",
    slug: "enez"
  },
  {
    id: "1385",
    cityId: "22",
    name: "Havsa",
    slug: "havsa"
  },
  {
    id: "1412",
    cityId: "22",
    name: "İpsala",
    slug: "ipsala"
  },
  {
    id: "1464",
    cityId: "22",
    name: "Keşan",
    slug: "kesan"
  },
  {
    id: "1502",
    cityId: "22",
    name: "Lalapaşa",
    slug: "lalapasa"
  },
  {
    id: "1523",
    cityId: "22",
    name: "Meriç",
    slug: "meric"
  },
  {
    id: "1295",
    cityId: "22",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1988",
    cityId: "22",
    name: "Süloğlu",
    slug: "suloglu"
  },
  {
    id: "1705",
    cityId: "22",
    name: "Uzunköprü",
    slug: "uzunkopru"
  },
  {
    id: "1110",
    cityId: "23",
    name: "Ağın",
    slug: "agin"
  },
  {
    id: "1873",
    cityId: "23",
    name: "Alacakaya",
    slug: "alacakaya"
  },
  {
    id: "1762",
    cityId: "23",
    name: "Arıcak",
    slug: "aricak"
  },
  {
    id: "1173",
    cityId: "23",
    name: "Baskil",
    slug: "baskil"
  },
  {
    id: "1438",
    cityId: "23",
    name: "Karakoçan",
    slug: "karakocan"
  },
  {
    id: "1455",
    cityId: "23",
    name: "Keban",
    slug: "keban"
  },
  {
    id: "1820",
    cityId: "23",
    name: "Kovancılar",
    slug: "kovancilar"
  },
  {
    id: "1506",
    cityId: "23",
    name: "Maden",
    slug: "maden"
  },
  {
    id: "1298",
    cityId: "23",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1566",
    cityId: "23",
    name: "Palu",
    slug: "palu"
  },
  {
    id: "1631",
    cityId: "23",
    name: "Sivrice",
    slug: "sivrice"
  },
  {
    id: "1243",
    cityId: "24",
    name: "Çayırlı",
    slug: "cayirli"
  },
  {
    id: "1406",
    cityId: "24",
    name: "İliç",
    slug: "ilic"
  },
  {
    id: "1459",
    cityId: "24",
    name: "Kemah",
    slug: "kemah"
  },
  {
    id: "1460",
    cityId: "24",
    name: "Kemaliye",
    slug: "kemaliye"
  },
  {
    id: "1318",
    cityId: "24",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1977",
    cityId: "24",
    name: "Otlukbeli",
    slug: "otlukbeli"
  },
  {
    id: "1583",
    cityId: "24",
    name: "Refahiye",
    slug: "refahiye"
  },
  {
    id: "1675",
    cityId: "24",
    name: "Tercan",
    slug: "tercan"
  },
  {
    id: "1853",
    cityId: "24",
    name: "Üzümlü",
    slug: "uzumlu"
  },
  {
    id: "1153",
    cityId: "25",
    name: "Aşkale",
    slug: "askale"
  },
  {
    id: "1945",
    cityId: "25",
    name: "Aziziye",
    slug: "aziziye"
  },
  {
    id: "1235",
    cityId: "25",
    name: "Çat",
    slug: "cat"
  },
  {
    id: "1392",
    cityId: "25",
    name: "Hınıs",
    slug: "hinis"
  },
  {
    id: "1396",
    cityId: "25",
    name: "Horasan",
    slug: "horasan"
  },
  {
    id: "1416",
    cityId: "25",
    name: "İspir",
    slug: "ispir"
  },
  {
    id: "1812",
    cityId: "25",
    name: "Karaçoban",
    slug: "karacoban"
  },
  {
    id: "1444",
    cityId: "25",
    name: "Karayazı",
    slug: "karayazi"
  },
  {
    id: "1967",
    cityId: "25",
    name: "Köprüköy",
    slug: "koprukoy"
  },
  {
    id: "1540",
    cityId: "25",
    name: "Narman",
    slug: "narman"
  },
  {
    id: "1550",
    cityId: "25",
    name: "Oltu",
    slug: "oltu"
  },
  {
    id: "1551",
    cityId: "25",
    name: "Olur",
    slug: "olur"
  },
  {
    id: "2044",
    cityId: "25",
    name: "Palandöken",
    slug: "palandoken"
  },
  {
    id: "1567",
    cityId: "25",
    name: "Pasinler",
    slug: "pasinler"
  },
  {
    id: "1865",
    cityId: "25",
    name: "Pazaryolu",
    slug: "pazaryolu"
  },
  {
    id: "1657",
    cityId: "25",
    name: "Şenkaya",
    slug: "senkaya"
  },
  {
    id: "1674",
    cityId: "25",
    name: "Tekman",
    slug: "tekman"
  },
  {
    id: "1683",
    cityId: "25",
    name: "Tortum",
    slug: "tortum"
  },
  {
    id: "1851",
    cityId: "25",
    name: "Uzundere",
    slug: "uzundere"
  },
  {
    id: "2045",
    cityId: "25",
    name: "Yakutiye",
    slug: "yakutiye"
  },
  {
    id: "1759",
    cityId: "26",
    name: "Alpu",
    slug: "alpu"
  },
  {
    id: "1777",
    cityId: "26",
    name: "Beylikova",
    slug: "beylikova"
  },
  {
    id: "1255",
    cityId: "26",
    name: "Çifteler",
    slug: "cifteler"
  },
  {
    id: "1934",
    cityId: "26",
    name: "Günyüzü",
    slug: "gunyuzu"
  },
  {
    id: "1939",
    cityId: "26",
    name: "Han",
    slug: "han"
  },
  {
    id: "1808",
    cityId: "26",
    name: "İnönü",
    slug: "inonu"
  },
  {
    id: "1508",
    cityId: "26",
    name: "Mahmudiye",
    slug: "mahmudiye"
  },
  {
    id: "1973",
    cityId: "26",
    name: "Mihalgazi",
    slug: "mihalgazi"
  },
  {
    id: "1527",
    cityId: "26",
    name: "Mihalıççık",
    slug: "mihaliccik"
  },
  {
    id: "2046",
    cityId: "26",
    name: "Odunpazarı",
    slug: "odunpazari"
  },
  {
    id: "1599",
    cityId: "26",
    name: "Sarıcakaya",
    slug: "saricakaya"
  },
  {
    id: "1618",
    cityId: "26",
    name: "Seyitgazi",
    slug: "seyitgazi"
  },
  {
    id: "1632",
    cityId: "26",
    name: "Sivrihisar",
    slug: "sivrihisar"
  },
  {
    id: "2047",
    cityId: "26",
    name: "Tepebaşı",
    slug: "tepebasi"
  },
  {
    id: "1139",
    cityId: "27",
    name: "Araban",
    slug: "araban"
  },
  {
    id: "1415",
    cityId: "27",
    name: "İslahiye",
    slug: "islahiye"
  },
  {
    id: "1956",
    cityId: "27",
    name: "Karkamış",
    slug: "karkamis"
  },
  {
    id: "1546",
    cityId: "27",
    name: "Nizip",
    slug: "nizip"
  },
  {
    id: "1974",
    cityId: "27",
    name: "Nurdağı",
    slug: "nurdagi"
  },
  {
    id: "1549",
    cityId: "27",
    name: "Oğuzeli",
    slug: "oguzeli"
  },
  {
    id: "1841",
    cityId: "27",
    name: "Şahinbey",
    slug: "sahinbey"
  },
  {
    id: "1844",
    cityId: "27",
    name: "Şehitkamil",
    slug: "sehitkamil"
  },
  {
    id: "1720",
    cityId: "27",
    name: "Yavuzeli",
    slug: "yavuzeli"
  },
  {
    id: "1133",
    cityId: "28",
    name: "Alucra",
    slug: "alucra"
  },
  {
    id: "1212",
    cityId: "28",
    name: "Bulancak",
    slug: "bulancak"
  },
  {
    id: "1893",
    cityId: "28",
    name: "Çamoluk",
    slug: "camoluk"
  },
  {
    id: "1894",
    cityId: "28",
    name: "Çanakçı",
    slug: "canakci"
  },
  {
    id: "1272",
    cityId: "28",
    name: "Dereli",
    slug: "dereli"
  },
  {
    id: "1912",
    cityId: "28",
    name: "Doğankent",
    slug: "dogankent"
  },
  {
    id: "1320",
    cityId: "28",
    name: "Espiye",
    slug: "espiye"
  },
  {
    id: "1324",
    cityId: "28",
    name: "Eynesil",
    slug: "eynesil"
  },
  {
    id: "1361",
    cityId: "28",
    name: "Görele",
    slug: "gorele"
  },
  {
    id: "1930",
    cityId: "28",
    name: "Güce",
    slug: "guce"
  },
  {
    id: "1465",
    cityId: "28",
    name: "Keşap",
    slug: "kesap"
  },
  {
    id: "1352",
    cityId: "28",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1837",
    cityId: "28",
    name: "Piraziz",
    slug: "piraziz"
  },
  {
    id: "1654",
    cityId: "28",
    name: "Şebinkarahisar",
    slug: "sebinkarahisar"
  },
  {
    id: "1678",
    cityId: "28",
    name: "Tirebolu",
    slug: "tirebolu"
  },
  {
    id: "1854",
    cityId: "28",
    name: "Yağlıdere",
    slug: "yaglidere"
  },
  {
    id: "1458",
    cityId: "29",
    name: "Kelkit",
    slug: "kelkit"
  },
  {
    id: "1822",
    cityId: "29",
    name: "Köse",
    slug: "kose"
  },
  {
    id: "1971",
    cityId: "29",
    name: "Kürtün",
    slug: "kurtun"
  },
  {
    id: "1369",
    cityId: "29",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1660",
    cityId: "29",
    name: "Şiran",
    slug: "siran"
  },
  {
    id: "1684",
    cityId: "29",
    name: "Torul",
    slug: "torul"
  },
  {
    id: "1261",
    cityId: "30",
    name: "Çukurca",
    slug: "cukurca"
  },
  {
    id: "2107",
    cityId: "30",
    name: "Derecik",
    slug: "derecik"
  },
  {
    id: "1377",
    cityId: "30",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1656",
    cityId: "30",
    name: "Şemdinli",
    slug: "semdinli"
  },
  {
    id: "1737",
    cityId: "30",
    name: "Yüksekova",
    slug: "yuksekova"
  },
  {
    id: "1131",
    cityId: "31",
    name: "Altınözü",
    slug: "altinozu"
  },
  {
    id: "2080",
    cityId: "31",
    name: "Antakya",
    slug: "antakya"
  },
  {
    id: "2081",
    cityId: "31",
    name: "Arsuz",
    slug: "arsuz"
  },
  {
    id: "1887",
    cityId: "31",
    name: "Belen",
    slug: "belen"
  },
  {
    id: "2082",
    cityId: "31",
    name: "Defne",
    slug: "defne"
  },
  {
    id: "1289",
    cityId: "31",
    name: "Dörtyol",
    slug: "dortyol"
  },
  {
    id: "1792",
    cityId: "31",
    name: "Erzin",
    slug: "erzin"
  },
  {
    id: "1382",
    cityId: "31",
    name: "Hassa",
    slug: "hassa"
  },
  {
    id: "1413",
    cityId: "31",
    name: "İskenderun",
    slug: "iskenderun"
  },
  {
    id: "1468",
    cityId: "31",
    name: "Kırıkhan",
    slug: "kirikhan"
  },
  {
    id: "1970",
    cityId: "31",
    name: "Kumlu",
    slug: "kumlu"
  },
  {
    id: "2083",
    cityId: "31",
    name: "Payas",
    slug: "payas"
  },
  {
    id: "1585",
    cityId: "31",
    name: "Reyhanlı",
    slug: "reyhanli"
  },
  {
    id: "1591",
    cityId: "31",
    name: "Samandağ",
    slug: "samandag"
  },
  {
    id: "1721",
    cityId: "31",
    name: "Yayladağı",
    slug: "yayladagi"
  },
  {
    id: "1755",
    cityId: "32",
    name: "Aksu",
    slug: "aksu"
  },
  {
    id: "1154",
    cityId: "32",
    name: "Atabey",
    slug: "atabey"
  },
  {
    id: "1297",
    cityId: "32",
    name: "Eğirdir",
    slug: "egirdir"
  },
  {
    id: "1341",
    cityId: "32",
    name: "Gelendost",
    slug: "gelendost"
  },
  {
    id: "1929",
    cityId: "32",
    name: "Gönen",
    slug: "gonen"
  },
  {
    id: "1456",
    cityId: "32",
    name: "Keçiborlu",
    slug: "keciborlu"
  },
  {
    id: "1401",
    cityId: "32",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1615",
    cityId: "32",
    name: "Senirkent",
    slug: "senirkent"
  },
  {
    id: "1648",
    cityId: "32",
    name: "Sütçüler",
    slug: "sutculer"
  },
  {
    id: "1651",
    cityId: "32",
    name: "Şarkikaraağaç",
    slug: "sarkikaraagac"
  },
  {
    id: "1699",
    cityId: "32",
    name: "Uluborlu",
    slug: "uluborlu"
  },
  {
    id: "1717",
    cityId: "32",
    name: "Yalvaç",
    slug: "yalvac"
  },
  {
    id: "2001",
    cityId: "32",
    name: "Yenişarbademli",
    slug: "yenisarbademli"
  },
  {
    id: "2064",
    cityId: "33",
    name: "Akdeniz",
    slug: "akdeniz"
  },
  {
    id: "1135",
    cityId: "33",
    name: "Anamur",
    slug: "anamur"
  },
  {
    id: "1766",
    cityId: "33",
    name: "Aydıncık",
    slug: "aydincik"
  },
  {
    id: "1779",
    cityId: "33",
    name: "Bozyazı",
    slug: "bozyazi"
  },
  {
    id: "1892",
    cityId: "33",
    name: "Çamlıyayla",
    slug: "camliyayla"
  },
  {
    id: "1311",
    cityId: "33",
    name: "Erdemli",
    slug: "erdemli"
  },
  {
    id: "1366",
    cityId: "33",
    name: "Gülnar",
    slug: "gulnar"
  },
  {
    id: "2065",
    cityId: "33",
    name: "Mezitli",
    slug: "mezitli"
  },
  {
    id: "1536",
    cityId: "33",
    name: "Mut",
    slug: "mut"
  },
  {
    id: "1621",
    cityId: "33",
    name: "Silifke",
    slug: "silifke"
  },
  {
    id: "1665",
    cityId: "33",
    name: "Tarsus",
    slug: "tarsus"
  },
  {
    id: "2066",
    cityId: "33",
    name: "Toroslar",
    slug: "toroslar"
  },
  {
    id: "2067",
    cityId: "33",
    name: "Yenişehir",
    slug: "yenisehir"
  },
  {
    id: "1103",
    cityId: "34",
    name: "Adalar",
    slug: "adalar"
  },
  {
    id: "2048",
    cityId: "34",
    name: "Arnavutköy",
    slug: "arnavutkoy"
  },
  {
    id: "2049",
    cityId: "34",
    name: "Ataşehir",
    slug: "atasehir"
  },
  {
    id: "2003",
    cityId: "34",
    name: "Avcılar",
    slug: "avcilar"
  },
  {
    id: "2004",
    cityId: "34",
    name: "Bağcılar",
    slug: "bagcilar"
  },
  {
    id: "2005",
    cityId: "34",
    name: "Bahçelievler",
    slug: "bahcelievler"
  },
  {
    id: "1166",
    cityId: "34",
    name: "Bakırköy",
    slug: "bakirkoy"
  },
  {
    id: "2050",
    cityId: "34",
    name: "Başakşehir",
    slug: "basaksehir"
  },
  {
    id: "1886",
    cityId: "34",
    name: "Bayrampaşa",
    slug: "bayrampasa"
  },
  {
    id: "1183",
    cityId: "34",
    name: "Beşiktaş",
    slug: "besiktas"
  },
  {
    id: "1185",
    cityId: "34",
    name: "Beykoz",
    slug: "beykoz"
  },
  {
    id: "2051",
    cityId: "34",
    name: "Beylikdüzü",
    slug: "beylikduzu"
  },
  {
    id: "1186",
    cityId: "34",
    name: "Beyoğlu",
    slug: "beyoglu"
  },
  {
    id: "1782",
    cityId: "34",
    name: "Büyükçekmece",
    slug: "buyukcekmece"
  },
  {
    id: "1237",
    cityId: "34",
    name: "Çatalca",
    slug: "catalca"
  },
  {
    id: "2052",
    cityId: "34",
    name: "Çekmeköy",
    slug: "cekmekoy"
  },
  {
    id: "2016",
    cityId: "34",
    name: "Esenler",
    slug: "esenler"
  },
  {
    id: "2053",
    cityId: "34",
    name: "Esenyurt",
    slug: "esenyurt"
  },
  {
    id: "1325",
    cityId: "34",
    name: "Eyüpsultan",
    slug: "eyupsultan"
  },
  {
    id: "1327",
    cityId: "34",
    name: "Fatih",
    slug: "fatih"
  },
  {
    id: "1336",
    cityId: "34",
    name: "Gaziosmanpaşa",
    slug: "gaziosmanpasa"
  },
  {
    id: "2010",
    cityId: "34",
    name: "Güngören",
    slug: "gungoren"
  },
  {
    id: "1421",
    cityId: "34",
    name: "Kadıköy",
    slug: "kadikoy"
  },
  {
    id: "1810",
    cityId: "34",
    name: "Kağıthane",
    slug: "kagithane"
  },
  {
    id: "1449",
    cityId: "34",
    name: "Kartal",
    slug: "kartal"
  },
  {
    id: "1823",
    cityId: "34",
    name: "Küçükçekmece",
    slug: "kucukcekmece"
  },
  {
    id: "2012",
    cityId: "34",
    name: "Maltepe",
    slug: "maltepe"
  },
  {
    id: "1835",
    cityId: "34",
    name: "Pendik",
    slug: "pendik"
  },
  {
    id: "2054",
    cityId: "34",
    name: "Sancaktepe",
    slug: "sancaktepe"
  },
  {
    id: "1604",
    cityId: "34",
    name: "Sarıyer",
    slug: "sariyer"
  },
  {
    id: "1622",
    cityId: "34",
    name: "Silivri",
    slug: "silivri"
  },
  {
    id: "2014",
    cityId: "34",
    name: "Sultanbeyli",
    slug: "sultanbeyli"
  },
  {
    id: "2055",
    cityId: "34",
    name: "Sultangazi",
    slug: "sultangazi"
  },
  {
    id: "1659",
    cityId: "34",
    name: "Şile",
    slug: "sile"
  },
  {
    id: "1663",
    cityId: "34",
    name: "Şişli",
    slug: "sisli"
  },
  {
    id: "2015",
    cityId: "34",
    name: "Tuzla",
    slug: "tuzla"
  },
  {
    id: "1852",
    cityId: "34",
    name: "Ümraniye",
    slug: "umraniye"
  },
  {
    id: "1708",
    cityId: "34",
    name: "Üsküdar",
    slug: "uskudar"
  },
  {
    id: "1739",
    cityId: "34",
    name: "Zeytinburnu",
    slug: "zeytinburnu"
  },
  {
    id: "1128",
    cityId: "35",
    name: "Aliağa",
    slug: "aliaga"
  },
  {
    id: "2006",
    cityId: "35",
    name: "Balçova",
    slug: "balcova"
  },
  {
    id: "1178",
    cityId: "35",
    name: "Bayındır",
    slug: "bayindir"
  },
  {
    id: "2056",
    cityId: "35",
    name: "Bayraklı",
    slug: "bayrakli"
  },
  {
    id: "1181",
    cityId: "35",
    name: "Bergama",
    slug: "bergama"
  },
  {
    id: "1776",
    cityId: "35",
    name: "Beydağ",
    slug: "beydag"
  },
  {
    id: "1203",
    cityId: "35",
    name: "Bornova",
    slug: "bornova"
  },
  {
    id: "1780",
    cityId: "35",
    name: "Buca",
    slug: "buca"
  },
  {
    id: "1251",
    cityId: "35",
    name: "Çeşme",
    slug: "cesme"
  },
  {
    id: "2007",
    cityId: "35",
    name: "Çiğli",
    slug: "cigli"
  },
  {
    id: "1280",
    cityId: "35",
    name: "Dikili",
    slug: "dikili"
  },
  {
    id: "1334",
    cityId: "35",
    name: "Foça",
    slug: "foca"
  },
  {
    id: "2009",
    cityId: "35",
    name: "Gaziemir",
    slug: "gaziemir"
  },
  {
    id: "2018",
    cityId: "35",
    name: "Güzelbahçe",
    slug: "guzelbahce"
  },
  {
    id: "2057",
    cityId: "35",
    name: "Karabağlar",
    slug: "karabaglar"
  },
  {
    id: "1432",
    cityId: "35",
    name: "Karaburun",
    slug: "karaburun"
  },
  {
    id: "1448",
    cityId: "35",
    name: "Karşıyaka",
    slug: "karsiyaka"
  },
  {
    id: "1461",
    cityId: "35",
    name: "Kemalpaşa",
    slug: "kemalpasa"
  },
  {
    id: "1467",
    cityId: "35",
    name: "Kınık",
    slug: "kinik"
  },
  {
    id: "1477",
    cityId: "35",
    name: "Kiraz",
    slug: "kiraz"
  },
  {
    id: "1819",
    cityId: "35",
    name: "Konak",
    slug: "konak"
  },
  {
    id: "1826",
    cityId: "35",
    name: "Menderes",
    slug: "menderes"
  },
  {
    id: "1521",
    cityId: "35",
    name: "Menemen",
    slug: "menemen"
  },
  {
    id: "2013",
    cityId: "35",
    name: "Narlıdere",
    slug: "narlidere"
  },
  {
    id: "1563",
    cityId: "35",
    name: "Ödemiş",
    slug: "odemis"
  },
  {
    id: "1611",
    cityId: "35",
    name: "Seferihisar",
    slug: "seferihisar"
  },
  {
    id: "1612",
    cityId: "35",
    name: "Selçuk",
    slug: "selcuk"
  },
  {
    id: "1677",
    cityId: "35",
    name: "Tire",
    slug: "tire"
  },
  {
    id: "1682",
    cityId: "35",
    name: "Torbalı",
    slug: "torbali"
  },
  {
    id: "1703",
    cityId: "35",
    name: "Urla",
    slug: "urla"
  },
  {
    id: "1756",
    cityId: "36",
    name: "Akyaka",
    slug: "akyaka"
  },
  {
    id: "1149",
    cityId: "36",
    name: "Arpaçay",
    slug: "arpacay"
  },
  {
    id: "1279",
    cityId: "36",
    name: "Digor",
    slug: "digor"
  },
  {
    id: "1424",
    cityId: "36",
    name: "Kağızman",
    slug: "kagizman"
  },
  {
    id: "1447",
    cityId: "36",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1601",
    cityId: "36",
    name: "Sarıkamış",
    slug: "sarikamis"
  },
  {
    id: "1614",
    cityId: "36",
    name: "Selim",
    slug: "selim"
  },
  {
    id: "1645",
    cityId: "36",
    name: "Susuz",
    slug: "susuz"
  },
  {
    id: "1101",
    cityId: "37",
    name: "Abana",
    slug: "abana"
  },
  {
    id: "1867",
    cityId: "37",
    name: "Ağlı",
    slug: "agli"
  },
  {
    id: "1140",
    cityId: "37",
    name: "Araç",
    slug: "arac"
  },
  {
    id: "1162",
    cityId: "37",
    name: "Azdavay",
    slug: "azdavay"
  },
  {
    id: "1208",
    cityId: "37",
    name: "Bozkurt",
    slug: "bozkurt"
  },
  {
    id: "1221",
    cityId: "37",
    name: "Cide",
    slug: "cide"
  },
  {
    id: "1238",
    cityId: "37",
    name: "Çatalzeytin",
    slug: "catalzeytin"
  },
  {
    id: "1264",
    cityId: "37",
    name: "Daday",
    slug: "daday"
  },
  {
    id: "1277",
    cityId: "37",
    name: "Devrekani",
    slug: "devrekani"
  },
  {
    id: "1915",
    cityId: "37",
    name: "Doğanyurt",
    slug: "doganyurt"
  },
  {
    id: "1940",
    cityId: "37",
    name: "Hanönü",
    slug: "hanonu"
  },
  {
    id: "1805",
    cityId: "37",
    name: "İhsangazi",
    slug: "ihsangazi"
  },
  {
    id: "1410",
    cityId: "37",
    name: "İnebolu",
    slug: "inebolu"
  },
  {
    id: "1499",
    cityId: "37",
    name: "Küre",
    slug: "kure"
  },
  {
    id: "1450",
    cityId: "37",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1836",
    cityId: "37",
    name: "Pınarbaşı",
    slug: "pinarbasi"
  },
  {
    id: "1984",
    cityId: "37",
    name: "Seydiler",
    slug: "seydiler"
  },
  {
    id: "1845",
    cityId: "37",
    name: "Şenpazar",
    slug: "senpazar"
  },
  {
    id: "1666",
    cityId: "37",
    name: "Taşköprü",
    slug: "taskopru"
  },
  {
    id: "1685",
    cityId: "37",
    name: "Tosya",
    slug: "tosya"
  },
  {
    id: "1752",
    cityId: "38",
    name: "Akkışla",
    slug: "akkisla"
  },
  {
    id: "1218",
    cityId: "38",
    name: "Bünyan",
    slug: "bunyan"
  },
  {
    id: "1275",
    cityId: "38",
    name: "Develi",
    slug: "develi"
  },
  {
    id: "1330",
    cityId: "38",
    name: "Felahiye",
    slug: "felahiye"
  },
  {
    id: "1936",
    cityId: "38",
    name: "Hacılar",
    slug: "hacilar"
  },
  {
    id: "1409",
    cityId: "38",
    name: "İncesu",
    slug: "incesu"
  },
  {
    id: "1863",
    cityId: "38",
    name: "Kocasinan",
    slug: "kocasinan"
  },
  {
    id: "1864",
    cityId: "38",
    name: "Melikgazi",
    slug: "melikgazi"
  },
  {
    id: "1978",
    cityId: "38",
    name: "Özvatan",
    slug: "ozvatan"
  },
  {
    id: "1576",
    cityId: "38",
    name: "Pınarbaşı",
    slug: "pinarbasi"
  },
  {
    id: "1603",
    cityId: "38",
    name: "Sarıoğlan",
    slug: "sarioglan"
  },
  {
    id: "1605",
    cityId: "38",
    name: "Sarız",
    slug: "sariz"
  },
  {
    id: "1846",
    cityId: "38",
    name: "Talas",
    slug: "talas"
  },
  {
    id: "1680",
    cityId: "38",
    name: "Tomarza",
    slug: "tomarza"
  },
  {
    id: "1715",
    cityId: "38",
    name: "Yahyalı",
    slug: "yahyali"
  },
  {
    id: "1727",
    cityId: "38",
    name: "Yeşilhisar",
    slug: "yesilhisar"
  },
  {
    id: "1163",
    cityId: "39",
    name: "Babaeski",
    slug: "babaeski"
  },
  {
    id: "1270",
    cityId: "39",
    name: "Demirköy",
    slug: "demirkoy"
  },
  {
    id: "1480",
    cityId: "39",
    name: "Kofçaz",
    slug: "kofcaz"
  },
  {
    id: "1505",
    cityId: "39",
    name: "Lüleburgaz",
    slug: "luleburgaz"
  },
  {
    id: "1471",
    cityId: "39",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1572",
    cityId: "39",
    name: "Pehlivanköy",
    slug: "pehlivankoy"
  },
  {
    id: "1577",
    cityId: "39",
    name: "Pınarhisar",
    slug: "pinarhisar"
  },
  {
    id: "1714",
    cityId: "39",
    name: "Vize",
    slug: "vize"
  },
  {
    id: "1869",
    cityId: "40",
    name: "Akçakent",
    slug: "akcakent"
  },
  {
    id: "1754",
    cityId: "40",
    name: "Akpınar",
    slug: "akpinar"
  },
  {
    id: "1890",
    cityId: "40",
    name: "Boztepe",
    slug: "boztepe"
  },
  {
    id: "1254",
    cityId: "40",
    name: "Çiçekdağı",
    slug: "cicekdagi"
  },
  {
    id: "1429",
    cityId: "40",
    name: "Kaman",
    slug: "kaman"
  },
  {
    id: "1472",
    cityId: "40",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1529",
    cityId: "40",
    name: "Mucur",
    slug: "mucur"
  },
  {
    id: "2058",
    cityId: "41",
    name: "Başiskele",
    slug: "basiskele"
  },
  {
    id: "2059",
    cityId: "41",
    name: "Çayırova",
    slug: "cayirova"
  },
  {
    id: "2060",
    cityId: "41",
    name: "Darıca",
    slug: "darica"
  },
  {
    id: "2030",
    cityId: "41",
    name: "Derince",
    slug: "derince"
  },
  {
    id: "2061",
    cityId: "41",
    name: "Dilovası",
    slug: "dilovasi"
  },
  {
    id: "1338",
    cityId: "41",
    name: "Gebze",
    slug: "gebze"
  },
  {
    id: "1355",
    cityId: "41",
    name: "Gölcük",
    slug: "golcuk"
  },
  {
    id: "2062",
    cityId: "41",
    name: "İzmit",
    slug: "izmit"
  },
  {
    id: "1430",
    cityId: "41",
    name: "Kandıra",
    slug: "kandira"
  },
  {
    id: "1440",
    cityId: "41",
    name: "Karamürsel",
    slug: "karamursel"
  },
  {
    id: "2063",
    cityId: "41",
    name: "Kartepe",
    slug: "kartepe"
  },
  {
    id: "1821",
    cityId: "41",
    name: "Körfez",
    slug: "korfez"
  },
  {
    id: "1868",
    cityId: "42",
    name: "Ahırlı",
    slug: "ahirli"
  },
  {
    id: "1753",
    cityId: "42",
    name: "Akören",
    slug: "akoren"
  },
  {
    id: "1122",
    cityId: "42",
    name: "Akşehir",
    slug: "aksehir"
  },
  {
    id: "1760",
    cityId: "42",
    name: "Altınekin",
    slug: "altinekin"
  },
  {
    id: "1188",
    cityId: "42",
    name: "Beyşehir",
    slug: "beysehir"
  },
  {
    id: "1207",
    cityId: "42",
    name: "Bozkır",
    slug: "bozkir"
  },
  {
    id: "1222",
    cityId: "42",
    name: "Cihanbeyli",
    slug: "cihanbeyli"
  },
  {
    id: "1902",
    cityId: "42",
    name: "Çeltik",
    slug: "celtik"
  },
  {
    id: "1262",
    cityId: "42",
    name: "Çumra",
    slug: "cumra"
  },
  {
    id: "1907",
    cityId: "42",
    name: "Derbent",
    slug: "derbent"
  },
  {
    id: "1789",
    cityId: "42",
    name: "Derebucak",
    slug: "derebucak"
  },
  {
    id: "1285",
    cityId: "42",
    name: "Doğanhisar",
    slug: "doganhisar"
  },
  {
    id: "1920",
    cityId: "42",
    name: "Emirgazi",
    slug: "emirgazi"
  },
  {
    id: "1312",
    cityId: "42",
    name: "Ereğli",
    slug: "eregli"
  },
  {
    id: "1933",
    cityId: "42",
    name: "Güneysınır",
    slug: "guneysinir"
  },
  {
    id: "1375",
    cityId: "42",
    name: "Hadim",
    slug: "hadim"
  },
  {
    id: "1937",
    cityId: "42",
    name: "Halkapınar",
    slug: "halkapinar"
  },
  {
    id: "1804",
    cityId: "42",
    name: "Hüyük",
    slug: "huyuk"
  },
  {
    id: "1400",
    cityId: "42",
    name: "Ilgın",
    slug: "ilgin"
  },
  {
    id: "1422",
    cityId: "42",
    name: "Kadınhanı",
    slug: "kadinhani"
  },
  {
    id: "1441",
    cityId: "42",
    name: "Karapınar",
    slug: "karapinar"
  },
  {
    id: "1814",
    cityId: "42",
    name: "Karatay",
    slug: "karatay"
  },
  {
    id: "1491",
    cityId: "42",
    name: "Kulu",
    slug: "kulu"
  },
  {
    id: "1827",
    cityId: "42",
    name: "Meram",
    slug: "meram"
  },
  {
    id: "1598",
    cityId: "42",
    name: "Sarayönü",
    slug: "sarayonu"
  },
  {
    id: "1839",
    cityId: "42",
    name: "Selçuklu",
    slug: "selcuklu"
  },
  {
    id: "1617",
    cityId: "42",
    name: "Seydişehir",
    slug: "seydisehir"
  },
  {
    id: "1848",
    cityId: "42",
    name: "Taşkent",
    slug: "taskent"
  },
  {
    id: "1990",
    cityId: "42",
    name: "Tuzlukçu",
    slug: "tuzlukcu"
  },
  {
    id: "1994",
    cityId: "42",
    name: "Yalıhüyük",
    slug: "yalihuyuk"
  },
  {
    id: "1735",
    cityId: "42",
    name: "Yunak",
    slug: "yunak"
  },
  {
    id: "1132",
    cityId: "43",
    name: "Altıntaş",
    slug: "altintas"
  },
  {
    id: "1764",
    cityId: "43",
    name: "Aslanapa",
    slug: "aslanapa"
  },
  {
    id: "1898",
    cityId: "43",
    name: "Çavdarhisar",
    slug: "cavdarhisar"
  },
  {
    id: "1288",
    cityId: "43",
    name: "Domaniç",
    slug: "domanic"
  },
  {
    id: "1790",
    cityId: "43",
    name: "Dumlupınar",
    slug: "dumlupinar"
  },
  {
    id: "1304",
    cityId: "43",
    name: "Emet",
    slug: "emet"
  },
  {
    id: "1339",
    cityId: "43",
    name: "Gediz",
    slug: "gediz"
  },
  {
    id: "1802",
    cityId: "43",
    name: "Hisarcık",
    slug: "hisarcik"
  },
  {
    id: "1500",
    cityId: "43",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1979",
    cityId: "43",
    name: "Pazarlar",
    slug: "pazarlar"
  },
  {
    id: "1625",
    cityId: "43",
    name: "Simav",
    slug: "simav"
  },
  {
    id: "1843",
    cityId: "43",
    name: "Şaphane",
    slug: "saphane"
  },
  {
    id: "1671",
    cityId: "43",
    name: "Tavşanlı",
    slug: "tavsanli"
  },
  {
    id: "1114",
    cityId: "44",
    name: "Akçadağ",
    slug: "akcadag"
  },
  {
    id: "1143",
    cityId: "44",
    name: "Arapgir",
    slug: "arapgir"
  },
  {
    id: "1148",
    cityId: "44",
    name: "Arguvan",
    slug: "arguvan"
  },
  {
    id: "1772",
    cityId: "44",
    name: "Battalgazi",
    slug: "battalgazi"
  },
  {
    id: "1265",
    cityId: "44",
    name: "Darende",
    slug: "darende"
  },
  {
    id: "1286",
    cityId: "44",
    name: "Doğanşehir",
    slug: "dogansehir"
  },
  {
    id: "1914",
    cityId: "44",
    name: "Doğanyol",
    slug: "doganyol"
  },
  {
    id: "1390",
    cityId: "44",
    name: "Hekimhan",
    slug: "hekimhan"
  },
  {
    id: "1953",
    cityId: "44",
    name: "Kale",
    slug: "kale"
  },
  {
    id: "1969",
    cityId: "44",
    name: "Kuluncak",
    slug: "kuluncak"
  },
  {
    id: "1582",
    cityId: "44",
    name: "Pütürge",
    slug: "puturge"
  },
  {
    id: "1995",
    cityId: "44",
    name: "Yazıhan",
    slug: "yazihan"
  },
  {
    id: "1729",
    cityId: "44",
    name: "Yeşilyurt",
    slug: "yesilyurt"
  },
  {
    id: "1751",
    cityId: "45",
    name: "Ahmetli",
    slug: "ahmetli"
  },
  {
    id: "1118",
    cityId: "45",
    name: "Akhisar",
    slug: "akhisar"
  },
  {
    id: "1127",
    cityId: "45",
    name: "Alaşehir",
    slug: "alasehir"
  },
  {
    id: "1269",
    cityId: "45",
    name: "Demirci",
    slug: "demirci"
  },
  {
    id: "1793",
    cityId: "45",
    name: "Gölmarmara",
    slug: "golmarmara"
  },
  {
    id: "1362",
    cityId: "45",
    name: "Gördes",
    slug: "gordes"
  },
  {
    id: "1470",
    cityId: "45",
    name: "Kırkağaç",
    slug: "kirkagac"
  },
  {
    id: "1965",
    cityId: "45",
    name: "Köprübaşı",
    slug: "koprubasi"
  },
  {
    id: "1489",
    cityId: "45",
    name: "Kula",
    slug: "kula"
  },
  {
    id: "1590",
    cityId: "45",
    name: "Salihli",
    slug: "salihli"
  },
  {
    id: "1600",
    cityId: "45",
    name: "Sarıgöl",
    slug: "sarigol"
  },
  {
    id: "1606",
    cityId: "45",
    name: "Saruhanlı",
    slug: "saruhanli"
  },
  {
    id: "1613",
    cityId: "45",
    name: "Selendi",
    slug: "selendi"
  },
  {
    id: "1634",
    cityId: "45",
    name: "Soma",
    slug: "soma"
  },
  {
    id: "2086",
    cityId: "45",
    name: "Şehzadeler",
    slug: "sehzadeler"
  },
  {
    id: "1689",
    cityId: "45",
    name: "Turgutlu",
    slug: "turgutlu"
  },
  {
    id: "2087",
    cityId: "45",
    name: "Yunusemre",
    slug: "yunusemre"
  },
  {
    id: "1107",
    cityId: "46",
    name: "Afşin",
    slug: "afsin"
  },
  {
    id: "1136",
    cityId: "46",
    name: "Andırın",
    slug: "andirin"
  },
  {
    id: "1785",
    cityId: "46",
    name: "Çağlayancerit",
    slug: "caglayancerit"
  },
  {
    id: "2084",
    cityId: "46",
    name: "Dulkadiroğlu",
    slug: "dulkadiroglu"
  },
  {
    id: "1919",
    cityId: "46",
    name: "Ekinözü",
    slug: "ekinozu"
  },
  {
    id: "1299",
    cityId: "46",
    name: "Elbistan",
    slug: "elbistan"
  },
  {
    id: "1353",
    cityId: "46",
    name: "Göksun",
    slug: "goksun"
  },
  {
    id: "1975",
    cityId: "46",
    name: "Nurhak",
    slug: "nurhak"
  },
  {
    id: "2085",
    cityId: "46",
    name: "Onikişubat",
    slug: "onikisubat"
  },
  {
    id: "1570",
    cityId: "46",
    name: "Pazarcık",
    slug: "pazarcik"
  },
  {
    id: "1694",
    cityId: "46",
    name: "Türkoğlu",
    slug: "turkoglu"
  },
  {
    id: "2088",
    cityId: "47",
    name: "Artuklu",
    slug: "artuklu"
  },
  {
    id: "1787",
    cityId: "47",
    name: "Dargeçit",
    slug: "dargecit"
  },
  {
    id: "1273",
    cityId: "47",
    name: "Derik",
    slug: "derik"
  },
  {
    id: "1474",
    cityId: "47",
    name: "Kızıltepe",
    slug: "kiziltepe"
  },
  {
    id: "1519",
    cityId: "47",
    name: "Mazıdağı",
    slug: "mazidagi"
  },
  {
    id: "1526",
    cityId: "47",
    name: "Midyat",
    slug: "midyat"
  },
  {
    id: "1547",
    cityId: "47",
    name: "Nusaybin",
    slug: "nusaybin"
  },
  {
    id: "1564",
    cityId: "47",
    name: "Ömerli",
    slug: "omerli"
  },
  {
    id: "1609",
    cityId: "47",
    name: "Savur",
    slug: "savur"
  },
  {
    id: "2002",
    cityId: "47",
    name: "Yeşilli",
    slug: "yesilli"
  },
  {
    id: "1197",
    cityId: "48",
    name: "Bodrum",
    slug: "bodrum"
  },
  {
    id: "1742",
    cityId: "48",
    name: "Dalaman",
    slug: "dalaman"
  },
  {
    id: "1266",
    cityId: "48",
    name: "Datça",
    slug: "datca"
  },
  {
    id: "1331",
    cityId: "48",
    name: "Fethiye",
    slug: "fethiye"
  },
  {
    id: "1958",
    cityId: "48",
    name: "Kavaklıdere",
    slug: "kavaklidere"
  },
  {
    id: "1488",
    cityId: "48",
    name: "Köyceğiz",
    slug: "koycegiz"
  },
  {
    id: "1517",
    cityId: "48",
    name: "Marmaris",
    slug: "marmaris"
  },
  {
    id: "2089",
    cityId: "48",
    name: "Menteşe",
    slug: "mentese"
  },
  {
    id: "1528",
    cityId: "48",
    name: "Milas",
    slug: "milas"
  },
  {
    id: "1831",
    cityId: "48",
    name: "Ortaca",
    slug: "ortaca"
  },
  {
    id: "2090",
    cityId: "48",
    name: "Seydikemer",
    slug: "seydikemer"
  },
  {
    id: "1695",
    cityId: "48",
    name: "Ula",
    slug: "ula"
  },
  {
    id: "1719",
    cityId: "48",
    name: "Yatağan",
    slug: "yatagan"
  },
  {
    id: "1213",
    cityId: "49",
    name: "Bulanık",
    slug: "bulanik"
  },
  {
    id: "1801",
    cityId: "49",
    name: "Hasköy",
    slug: "haskoy"
  },
  {
    id: "1964",
    cityId: "49",
    name: "Korkut",
    slug: "korkut"
  },
  {
    id: "1510",
    cityId: "49",
    name: "Malazgirt",
    slug: "malazgirt"
  },
  {
    id: "1534",
    cityId: "49",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1711",
    cityId: "49",
    name: "Varto",
    slug: "varto"
  },
  {
    id: "1749",
    cityId: "50",
    name: "Acıgöl",
    slug: "acigol"
  },
  {
    id: "1155",
    cityId: "50",
    name: "Avanos",
    slug: "avanos"
  },
  {
    id: "1274",
    cityId: "50",
    name: "Derinkuyu",
    slug: "derinkuyu"
  },
  {
    id: "1367",
    cityId: "50",
    name: "Gülşehir",
    slug: "gulsehir"
  },
  {
    id: "1374",
    cityId: "50",
    name: "Hacıbektaş",
    slug: "hacibektas"
  },
  {
    id: "1485",
    cityId: "50",
    name: "Kozaklı",
    slug: "kozakli"
  },
  {
    id: "1543",
    cityId: "50",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1707",
    cityId: "50",
    name: "Ürgüp",
    slug: "urgup"
  },
  {
    id: "1876",
    cityId: "51",
    name: "Altunhisar",
    slug: "altunhisar"
  },
  {
    id: "1201",
    cityId: "51",
    name: "Bor",
    slug: "bor"
  },
  {
    id: "1225",
    cityId: "51",
    name: "Çamardı",
    slug: "camardi"
  },
  {
    id: "1904",
    cityId: "51",
    name: "Çiftlik",
    slug: "ciftlik"
  },
  {
    id: "1544",
    cityId: "51",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1700",
    cityId: "51",
    name: "Ulukışla",
    slug: "ulukisla"
  },
  {
    id: "1119",
    cityId: "52",
    name: "Akkuş",
    slug: "akkus"
  },
  {
    id: "2103",
    cityId: "52",
    name: "Altınordu",
    slug: "altinordu"
  },
  {
    id: "1158",
    cityId: "52",
    name: "Aybastı",
    slug: "aybasti"
  },
  {
    id: "1891",
    cityId: "52",
    name: "Çamaş",
    slug: "camas"
  },
  {
    id: "1897",
    cityId: "52",
    name: "Çatalpınar",
    slug: "catalpinar"
  },
  {
    id: "1900",
    cityId: "52",
    name: "Çaybaşı",
    slug: "caybasi"
  },
  {
    id: "1328",
    cityId: "52",
    name: "Fatsa",
    slug: "fatsa"
  },
  {
    id: "1358",
    cityId: "52",
    name: "Gölköy",
    slug: "golkoy"
  },
  {
    id: "1795",
    cityId: "52",
    name: "Gülyalı",
    slug: "gulyali"
  },
  {
    id: "1797",
    cityId: "52",
    name: "Gürgentepe",
    slug: "gurgentepe"
  },
  {
    id: "1947",
    cityId: "52",
    name: "İkizce",
    slug: "ikizce"
  },
  {
    id: "1950",
    cityId: "52",
    name: "Kabadüz",
    slug: "kabaduz"
  },
  {
    id: "1951",
    cityId: "52",
    name: "Kabataş",
    slug: "kabatas"
  },
  {
    id: "1482",
    cityId: "52",
    name: "Korgan",
    slug: "korgan"
  },
  {
    id: "1493",
    cityId: "52",
    name: "Kumru",
    slug: "kumru"
  },
  {
    id: "1525",
    cityId: "52",
    name: "Mesudiye",
    slug: "mesudiye"
  },
  {
    id: "1573",
    cityId: "52",
    name: "Perşembe",
    slug: "persembe"
  },
  {
    id: "1696",
    cityId: "52",
    name: "Ulubey",
    slug: "ulubey"
  },
  {
    id: "1706",
    cityId: "52",
    name: "Ünye",
    slug: "unye"
  },
  {
    id: "1146",
    cityId: "53",
    name: "Ardeşen",
    slug: "ardesen"
  },
  {
    id: "1228",
    cityId: "53",
    name: "Çamlıhemşin",
    slug: "camlihemsin"
  },
  {
    id: "1241",
    cityId: "53",
    name: "Çayeli",
    slug: "cayeli"
  },
  {
    id: "1908",
    cityId: "53",
    name: "Derepazarı",
    slug: "derepazari"
  },
  {
    id: "1332",
    cityId: "53",
    name: "Fındıklı",
    slug: "findikli"
  },
  {
    id: "1796",
    cityId: "53",
    name: "Güneysu",
    slug: "guneysu"
  },
  {
    id: "1943",
    cityId: "53",
    name: "Hemşin",
    slug: "hemsin"
  },
  {
    id: "1405",
    cityId: "53",
    name: "İkizdere",
    slug: "ikizdere"
  },
  {
    id: "1949",
    cityId: "53",
    name: "İyidere",
    slug: "iyidere"
  },
  {
    id: "1428",
    cityId: "53",
    name: "Kalkandere",
    slug: "kalkandere"
  },
  {
    id: "1586",
    cityId: "53",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1569",
    cityId: "53",
    name: "Pazar",
    slug: "pazar"
  },
  {
    id: "2068",
    cityId: "54",
    name: "Adapazarı",
    slug: "adapazari"
  },
  {
    id: "1123",
    cityId: "54",
    name: "Akyazı",
    slug: "akyazi"
  },
  {
    id: "2069",
    cityId: "54",
    name: "Arifiye",
    slug: "arifiye"
  },
  {
    id: "2070",
    cityId: "54",
    name: "Erenler",
    slug: "erenler"
  },
  {
    id: "1925",
    cityId: "54",
    name: "Ferizli",
    slug: "ferizli"
  },
  {
    id: "1351",
    cityId: "54",
    name: "Geyve",
    slug: "geyve"
  },
  {
    id: "1391",
    cityId: "54",
    name: "Hendek",
    slug: "hendek"
  },
  {
    id: "1955",
    cityId: "54",
    name: "Karapürçek",
    slug: "karapurcek"
  },
  {
    id: "1442",
    cityId: "54",
    name: "Karasu",
    slug: "karasu"
  },
  {
    id: "1453",
    cityId: "54",
    name: "Kaynarca",
    slug: "kaynarca"
  },
  {
    id: "1818",
    cityId: "54",
    name: "Kocaali",
    slug: "kocaali"
  },
  {
    id: "1833",
    cityId: "54",
    name: "Pamukova",
    slug: "pamukova"
  },
  {
    id: "1595",
    cityId: "54",
    name: "Sapanca",
    slug: "sapanca"
  },
  {
    id: "2071",
    cityId: "54",
    name: "Serdivan",
    slug: "serdivan"
  },
  {
    id: "1986",
    cityId: "54",
    name: "Söğütlü",
    slug: "sogutlu"
  },
  {
    id: "1847",
    cityId: "54",
    name: "Taraklı",
    slug: "tarakli"
  },
  {
    id: "1125",
    cityId: "55",
    name: "Alaçam",
    slug: "alacam"
  },
  {
    id: "1763",
    cityId: "55",
    name: "Asarcık",
    slug: "asarcik"
  },
  {
    id: "2072",
    cityId: "55",
    name: "Atakum",
    slug: "atakum"
  },
  {
    id: "1879",
    cityId: "55",
    name: "Ayvacık",
    slug: "ayvacik"
  },
  {
    id: "1164",
    cityId: "55",
    name: "Bafra",
    slug: "bafra"
  },
  {
    id: "2073",
    cityId: "55",
    name: "Canik",
    slug: "canik"
  },
  {
    id: "1234",
    cityId: "55",
    name: "Çarşamba",
    slug: "carsamba"
  },
  {
    id: "1386",
    cityId: "55",
    name: "Havza",
    slug: "havza"
  },
  {
    id: "2074",
    cityId: "55",
    name: "İlkadım",
    slug: "ilkadim"
  },
  {
    id: "1452",
    cityId: "55",
    name: "Kavak",
    slug: "kavak"
  },
  {
    id: "1501",
    cityId: "55",
    name: "Ladik",
    slug: "ladik"
  },
  {
    id: "1838",
    cityId: "55",
    name: "Salıpazarı",
    slug: "salipazari"
  },
  {
    id: "1849",
    cityId: "55",
    name: "Tekkeköy",
    slug: "tekkekoy"
  },
  {
    id: "1676",
    cityId: "55",
    name: "Terme",
    slug: "terme"
  },
  {
    id: "1712",
    cityId: "55",
    name: "Vezirköprü",
    slug: "vezirkopru"
  },
  {
    id: "1993",
    cityId: "55",
    name: "Yakakent",
    slug: "yakakent"
  },
  {
    id: "1830",
    cityId: "55",
    name: "19 Mayıs",
    slug: "19-mayis"
  },
  {
    id: "1179",
    cityId: "56",
    name: "Baykan",
    slug: "baykan"
  },
  {
    id: "1317",
    cityId: "56",
    name: "Eruh",
    slug: "eruh"
  },
  {
    id: "1495",
    cityId: "56",
    name: "Kurtalan",
    slug: "kurtalan"
  },
  {
    id: "1620",
    cityId: "56",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1575",
    cityId: "56",
    name: "Pervari",
    slug: "pervari"
  },
  {
    id: "1662",
    cityId: "56",
    name: "Şirvan",
    slug: "sirvan"
  },
  {
    id: "1878",
    cityId: "56",
    name: "Tillo",
    slug: "tillo"
  },
  {
    id: "1156",
    cityId: "57",
    name: "Ayancık",
    slug: "ayancik"
  },
  {
    id: "1204",
    cityId: "57",
    name: "Boyabat",
    slug: "boyabat"
  },
  {
    id: "1910",
    cityId: "57",
    name: "Dikmen",
    slug: "dikmen"
  },
  {
    id: "1290",
    cityId: "57",
    name: "Durağan",
    slug: "duragan"
  },
  {
    id: "1314",
    cityId: "57",
    name: "Erfelek",
    slug: "erfelek"
  },
  {
    id: "1349",
    cityId: "57",
    name: "Gerze",
    slug: "gerze"
  },
  {
    id: "1627",
    cityId: "57",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1981",
    cityId: "57",
    name: "Saraydüzü",
    slug: "sarayduzu"
  },
  {
    id: "1693",
    cityId: "57",
    name: "Türkeli",
    slug: "turkeli"
  },
  {
    id: "1870",
    cityId: "58",
    name: "Akıncılar",
    slug: "akincilar"
  },
  {
    id: "1875",
    cityId: "58",
    name: "Altınyayla",
    slug: "altinyayla"
  },
  {
    id: "1282",
    cityId: "58",
    name: "Divriği",
    slug: "divrigi"
  },
  {
    id: "1913",
    cityId: "58",
    name: "Doğanşar",
    slug: "dogansar"
  },
  {
    id: "1342",
    cityId: "58",
    name: "Gemerek",
    slug: "gemerek"
  },
  {
    id: "1927",
    cityId: "58",
    name: "Gölova",
    slug: "golova"
  },
  {
    id: "1373",
    cityId: "58",
    name: "Gürün",
    slug: "gurun"
  },
  {
    id: "1376",
    cityId: "58",
    name: "Hafik",
    slug: "hafik"
  },
  {
    id: "1407",
    cityId: "58",
    name: "İmranlı",
    slug: "imranli"
  },
  {
    id: "1431",
    cityId: "58",
    name: "Kangal",
    slug: "kangal"
  },
  {
    id: "1484",
    cityId: "58",
    name: "Koyulhisar",
    slug: "koyulhisar"
  },
  {
    id: "1628",
    cityId: "58",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1646",
    cityId: "58",
    name: "Suşehri",
    slug: "susehri"
  },
  {
    id: "1650",
    cityId: "58",
    name: "Şarkışla",
    slug: "sarkisla"
  },
  {
    id: "1991",
    cityId: "58",
    name: "Ulaş",
    slug: "ulas"
  },
  {
    id: "1731",
    cityId: "58",
    name: "Yıldızeli",
    slug: "yildizeli"
  },
  {
    id: "1738",
    cityId: "58",
    name: "Zara",
    slug: "zara"
  },
  {
    id: "1250",
    cityId: "59",
    name: "Çerkezköy",
    slug: "cerkezkoy"
  },
  {
    id: "1258",
    cityId: "59",
    name: "Çorlu",
    slug: "corlu"
  },
  {
    id: "2094",
    cityId: "59",
    name: "Ergene",
    slug: "ergene"
  },
  {
    id: "1388",
    cityId: "59",
    name: "Hayrabolu",
    slug: "hayrabolu"
  },
  {
    id: "2095",
    cityId: "59",
    name: "Kapaklı",
    slug: "kapakli"
  },
  {
    id: "1511",
    cityId: "59",
    name: "Malkara",
    slug: "malkara"
  },
  {
    id: "1825",
    cityId: "59",
    name: "Marmaraereğlisi",
    slug: "marmaraereglisi"
  },
  {
    id: "1538",
    cityId: "59",
    name: "Muratlı",
    slug: "muratli"
  },
  {
    id: "1596",
    cityId: "59",
    name: "Saray",
    slug: "saray"
  },
  {
    id: "2096",
    cityId: "59",
    name: "Süleymanpaşa",
    slug: "suleymanpasa"
  },
  {
    id: "1652",
    cityId: "59",
    name: "Şarköy",
    slug: "sarkoy"
  },
  {
    id: "1129",
    cityId: "60",
    name: "Almus",
    slug: "almus"
  },
  {
    id: "1151",
    cityId: "60",
    name: "Artova",
    slug: "artova"
  },
  {
    id: "1883",
    cityId: "60",
    name: "Başçiftlik",
    slug: "basciftlik"
  },
  {
    id: "1308",
    cityId: "60",
    name: "Erbaa",
    slug: "erbaa"
  },
  {
    id: "1679",
    cityId: "60",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1545",
    cityId: "60",
    name: "Niksar",
    slug: "niksar"
  },
  {
    id: "1834",
    cityId: "60",
    name: "Pazar",
    slug: "pazar"
  },
  {
    id: "1584",
    cityId: "60",
    name: "Reşadiye",
    slug: "resadiye"
  },
  {
    id: "1987",
    cityId: "60",
    name: "Sulusaray",
    slug: "sulusaray"
  },
  {
    id: "1690",
    cityId: "60",
    name: "Turhal",
    slug: "turhal"
  },
  {
    id: "1858",
    cityId: "60",
    name: "Yeşilyurt",
    slug: "yesilyurt"
  },
  {
    id: "1740",
    cityId: "60",
    name: "Zile",
    slug: "zile"
  },
  {
    id: "1113",
    cityId: "61",
    name: "Akçaabat",
    slug: "akcaabat"
  },
  {
    id: "1141",
    cityId: "61",
    name: "Araklı",
    slug: "arakli"
  },
  {
    id: "1150",
    cityId: "61",
    name: "Arsin",
    slug: "arsin"
  },
  {
    id: "1775",
    cityId: "61",
    name: "Beşikdüzü",
    slug: "besikduzu"
  },
  {
    id: "1896",
    cityId: "61",
    name: "Çarşıbaşı",
    slug: "carsibasi"
  },
  {
    id: "1244",
    cityId: "61",
    name: "Çaykara",
    slug: "caykara"
  },
  {
    id: "1909",
    cityId: "61",
    name: "Dernekpazarı",
    slug: "dernekpazari"
  },
  {
    id: "1917",
    cityId: "61",
    name: "Düzköy",
    slug: "duzkoy"
  },
  {
    id: "1942",
    cityId: "61",
    name: "Hayrat",
    slug: "hayrat"
  },
  {
    id: "1966",
    cityId: "61",
    name: "Köprübaşı",
    slug: "koprubasi"
  },
  {
    id: "1507",
    cityId: "61",
    name: "Maçka",
    slug: "macka"
  },
  {
    id: "1548",
    cityId: "61",
    name: "Of",
    slug: "of"
  },
  {
    id: "2097",
    cityId: "61",
    name: "Ortahisar",
    slug: "ortahisar"
  },
  {
    id: "1647",
    cityId: "61",
    name: "Sürmene",
    slug: "surmene"
  },
  {
    id: "1842",
    cityId: "61",
    name: "Şalpazarı",
    slug: "salpazari"
  },
  {
    id: "1681",
    cityId: "61",
    name: "Tonya",
    slug: "tonya"
  },
  {
    id: "1709",
    cityId: "61",
    name: "Vakfıkebir",
    slug: "vakfikebir"
  },
  {
    id: "1732",
    cityId: "61",
    name: "Yomra",
    slug: "yomra"
  },
  {
    id: "1247",
    cityId: "62",
    name: "Çemişgezek",
    slug: "cemisgezek"
  },
  {
    id: "1397",
    cityId: "62",
    name: "Hozat",
    slug: "hozat"
  },
  {
    id: "1518",
    cityId: "62",
    name: "Mazgirt",
    slug: "mazgirt"
  },
  {
    id: "1688",
    cityId: "62",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1541",
    cityId: "62",
    name: "Nazımiye",
    slug: "nazimiye"
  },
  {
    id: "1562",
    cityId: "62",
    name: "Ovacık",
    slug: "ovacik"
  },
  {
    id: "1574",
    cityId: "62",
    name: "Pertek",
    slug: "pertek"
  },
  {
    id: "1581",
    cityId: "62",
    name: "Pülümür",
    slug: "pulumur"
  },
  {
    id: "1115",
    cityId: "63",
    name: "Akçakale",
    slug: "akcakale"
  },
  {
    id: "1194",
    cityId: "63",
    name: "Birecik",
    slug: "birecik"
  },
  {
    id: "1209",
    cityId: "63",
    name: "Bozova",
    slug: "bozova"
  },
  {
    id: "1220",
    cityId: "63",
    name: "Ceylanpınar",
    slug: "ceylanpinar"
  },
  {
    id: "2091",
    cityId: "63",
    name: "Eyyübiye",
    slug: "eyyubiye"
  },
  {
    id: "1378",
    cityId: "63",
    name: "Halfeti",
    slug: "halfeti"
  },
  {
    id: "2092",
    cityId: "63",
    name: "Haliliye",
    slug: "haliliye"
  },
  {
    id: "1800",
    cityId: "63",
    name: "Harran",
    slug: "harran"
  },
  {
    id: "1393",
    cityId: "63",
    name: "Hilvan",
    slug: "hilvan"
  },
  {
    id: "2093",
    cityId: "63",
    name: "Karaköprü",
    slug: "karakopru"
  },
  {
    id: "1630",
    cityId: "63",
    name: "Siverek",
    slug: "siverek"
  },
  {
    id: "1643",
    cityId: "63",
    name: "Suruç",
    slug: "suruc"
  },
  {
    id: "1713",
    cityId: "63",
    name: "Viranşehir",
    slug: "viransehir"
  },
  {
    id: "1170",
    cityId: "64",
    name: "Banaz",
    slug: "banaz"
  },
  {
    id: "1323",
    cityId: "64",
    name: "Eşme",
    slug: "esme"
  },
  {
    id: "1436",
    cityId: "64",
    name: "Karahallı",
    slug: "karahalli"
  },
  {
    id: "1704",
    cityId: "64",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1629",
    cityId: "64",
    name: "Sivaslı",
    slug: "sivasli"
  },
  {
    id: "1697",
    cityId: "64",
    name: "Ulubey",
    slug: "ulubey"
  },
  {
    id: "1770",
    cityId: "65",
    name: "Bahçesaray",
    slug: "bahcesaray"
  },
  {
    id: "1175",
    cityId: "65",
    name: "Başkale",
    slug: "baskale"
  },
  {
    id: "1786",
    cityId: "65",
    name: "Çaldıran",
    slug: "caldiran"
  },
  {
    id: "1236",
    cityId: "65",
    name: "Çatak",
    slug: "catak"
  },
  {
    id: "1918",
    cityId: "65",
    name: "Edremit",
    slug: "edremit"
  },
  {
    id: "1309",
    cityId: "65",
    name: "Erciş",
    slug: "ercis"
  },
  {
    id: "1350",
    cityId: "65",
    name: "Gevaş",
    slug: "gevas"
  },
  {
    id: "1372",
    cityId: "65",
    name: "Gürpınar",
    slug: "gurpinar"
  },
  {
    id: "2098",
    cityId: "65",
    name: "İpekyolu",
    slug: "ipekyolu"
  },
  {
    id: "1533",
    cityId: "65",
    name: "Muradiye",
    slug: "muradiye"
  },
  {
    id: "1565",
    cityId: "65",
    name: "Özalp",
    slug: "ozalp"
  },
  {
    id: "1980",
    cityId: "65",
    name: "Saray",
    slug: "saray"
  },
  {
    id: "2099",
    cityId: "65",
    name: "Tuşba",
    slug: "tusba"
  },
  {
    id: "1117",
    cityId: "66",
    name: "Akdağmadeni",
    slug: "akdagmadeni"
  },
  {
    id: "1877",
    cityId: "66",
    name: "Aydıncık",
    slug: "aydincik"
  },
  {
    id: "1198",
    cityId: "66",
    name: "Boğazlıyan",
    slug: "bogazliyan"
  },
  {
    id: "1895",
    cityId: "66",
    name: "Çandır",
    slug: "candir"
  },
  {
    id: "1242",
    cityId: "66",
    name: "Çayıralan",
    slug: "cayiralan"
  },
  {
    id: "1245",
    cityId: "66",
    name: "Çekerek",
    slug: "cekerek"
  },
  {
    id: "1952",
    cityId: "66",
    name: "Kadışehri",
    slug: "kadisehri"
  },
  {
    id: "1733",
    cityId: "66",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1982",
    cityId: "66",
    name: "Saraykent",
    slug: "saraykent"
  },
  {
    id: "1602",
    cityId: "66",
    name: "Sarıkaya",
    slug: "sarikaya"
  },
  {
    id: "1635",
    cityId: "66",
    name: "Sorgun",
    slug: "sorgun"
  },
  {
    id: "1655",
    cityId: "66",
    name: "Şefaatli",
    slug: "sefaatli"
  },
  {
    id: "1998",
    cityId: "66",
    name: "Yenifakılı",
    slug: "yenifakili"
  },
  {
    id: "1726",
    cityId: "66",
    name: "Yerköy",
    slug: "yerkoy"
  },
  {
    id: "1758",
    cityId: "67",
    name: "Alaplı",
    slug: "alapli"
  },
  {
    id: "1240",
    cityId: "67",
    name: "Çaycuma",
    slug: "caycuma"
  },
  {
    id: "1276",
    cityId: "67",
    name: "Devrek",
    slug: "devrek"
  },
  {
    id: "1313",
    cityId: "67",
    name: "Ereğli",
    slug: "eregli"
  },
  {
    id: "1926",
    cityId: "67",
    name: "Gökçebey",
    slug: "gokcebey"
  },
  {
    id: "2100",
    cityId: "67",
    name: "Kilimli",
    slug: "kilimli"
  },
  {
    id: "2101",
    cityId: "67",
    name: "Kozlu",
    slug: "kozlu"
  },
  {
    id: "1741",
    cityId: "67",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1860",
    cityId: "68",
    name: "Ağaçören",
    slug: "agacoren"
  },
  {
    id: "1921",
    cityId: "68",
    name: "Eskil",
    slug: "eskil"
  },
  {
    id: "1932",
    cityId: "68",
    name: "Gülağaç",
    slug: "gulagac"
  },
  {
    id: "1861",
    cityId: "68",
    name: "Güzelyurt",
    slug: "guzelyurt"
  },
  {
    id: "1120",
    cityId: "68",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1557",
    cityId: "68",
    name: "Ortaköy",
    slug: "ortakoy"
  },
  {
    id: "1866",
    cityId: "68",
    name: "Sarıyahşi",
    slug: "sariyahsi"
  },
  {
    id: "2106",
    cityId: "68",
    name: "Sultanhanı",
    slug: "sultanhani"
  },
  {
    id: "1767",
    cityId: "69",
    name: "Aydıntepe",
    slug: "aydintepe"
  },
  {
    id: "1788",
    cityId: "69",
    name: "Demirözü",
    slug: "demirozu"
  },
  {
    id: "1176",
    cityId: "69",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1768",
    cityId: "70",
    name: "Ayrancı",
    slug: "ayranci"
  },
  {
    id: "1884",
    cityId: "70",
    name: "Başyayla",
    slug: "basyayla"
  },
  {
    id: "1316",
    cityId: "70",
    name: "Ermenek",
    slug: "ermenek"
  },
  {
    id: "1862",
    cityId: "70",
    name: "Kazımkarabekir",
    slug: "kazimkarabekir"
  },
  {
    id: "1439",
    cityId: "70",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1983",
    cityId: "70",
    name: "Sarıveliler",
    slug: "sariveliler"
  },
  {
    id: "1880",
    cityId: "71",
    name: "Bahşili",
    slug: "bahsili"
  },
  {
    id: "1882",
    cityId: "71",
    name: "Balışeyh",
    slug: "baliseyh"
  },
  {
    id: "1901",
    cityId: "71",
    name: "Çelebi",
    slug: "celebi"
  },
  {
    id: "1268",
    cityId: "71",
    name: "Delice",
    slug: "delice"
  },
  {
    id: "1954",
    cityId: "71",
    name: "Karakeçili",
    slug: "karakecili"
  },
  {
    id: "1463",
    cityId: "71",
    name: "Keskin",
    slug: "keskin"
  },
  {
    id: "1469",
    cityId: "71",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1638",
    cityId: "71",
    name: "Sulakyurt",
    slug: "sulakyurt"
  },
  {
    id: "1992",
    cityId: "71",
    name: "Yahşihan",
    slug: "yahsihan"
  },
  {
    id: "1184",
    cityId: "72",
    name: "Beşiri",
    slug: "besiri"
  },
  {
    id: "1345",
    cityId: "72",
    name: "Gercüş",
    slug: "gercus"
  },
  {
    id: "1941",
    cityId: "72",
    name: "Hasankeyf",
    slug: "hasankeyf"
  },
  {
    id: "1487",
    cityId: "72",
    name: "Kozluk",
    slug: "kozluk"
  },
  {
    id: "1174",
    cityId: "72",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1607",
    cityId: "72",
    name: "Sason",
    slug: "sason"
  },
  {
    id: "1189",
    cityId: "73",
    name: "Beytüşşebap",
    slug: "beytussebap"
  },
  {
    id: "1223",
    cityId: "73",
    name: "Cizre",
    slug: "cizre"
  },
  {
    id: "1931",
    cityId: "73",
    name: "Güçlükonak",
    slug: "guclukonak"
  },
  {
    id: "1403",
    cityId: "73",
    name: "İdil",
    slug: "idil"
  },
  {
    id: "1661",
    cityId: "73",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1623",
    cityId: "73",
    name: "Silopi",
    slug: "silopi"
  },
  {
    id: "1698",
    cityId: "73",
    name: "Uludere",
    slug: "uludere"
  },
  {
    id: "1761",
    cityId: "74",
    name: "Amasra",
    slug: "amasra"
  },
  {
    id: "1496",
    cityId: "74",
    name: "Kurucaşile",
    slug: "kurucasile"
  },
  {
    id: "1172",
    cityId: "74",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1701",
    cityId: "74",
    name: "Ulus",
    slug: "ulus"
  },
  {
    id: "1252",
    cityId: "75",
    name: "Çıldır",
    slug: "cildir"
  },
  {
    id: "2008",
    cityId: "75",
    name: "Damal",
    slug: "damal"
  },
  {
    id: "1356",
    cityId: "75",
    name: "Göle",
    slug: "gole"
  },
  {
    id: "1380",
    cityId: "75",
    name: "Hanak",
    slug: "hanak"
  },
  {
    id: "1144",
    cityId: "75",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1579",
    cityId: "75",
    name: "Posof",
    slug: "posof"
  },
  {
    id: "1142",
    cityId: "76",
    name: "Aralık",
    slug: "aralik"
  },
  {
    id: "2011",
    cityId: "76",
    name: "Karakoyunlu",
    slug: "karakoyunlu"
  },
  {
    id: "1398",
    cityId: "76",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1692",
    cityId: "76",
    name: "Tuzluca",
    slug: "tuzluca"
  },
  {
    id: "2019",
    cityId: "77",
    name: "Altınova",
    slug: "altinova"
  },
  {
    id: "2020",
    cityId: "77",
    name: "Armutlu",
    slug: "armutlu"
  },
  {
    id: "2021",
    cityId: "77",
    name: "Çınarcık",
    slug: "cinarcik"
  },
  {
    id: "2022",
    cityId: "77",
    name: "Çiftlikköy",
    slug: "ciftlikkoy"
  },
  {
    id: "1716",
    cityId: "77",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "2026",
    cityId: "77",
    name: "Termal",
    slug: "termal"
  },
  {
    id: "1296",
    cityId: "78",
    name: "Eflani",
    slug: "eflani"
  },
  {
    id: "1321",
    cityId: "78",
    name: "Eskipazar",
    slug: "eskipazar"
  },
  {
    id: "1433",
    cityId: "78",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1561",
    cityId: "78",
    name: "Ovacık",
    slug: "ovacik"
  },
  {
    id: "1587",
    cityId: "78",
    name: "Safranbolu",
    slug: "safranbolu"
  },
  {
    id: "1856",
    cityId: "78",
    name: "Yenice",
    slug: "yenice"
  },
  {
    id: "2023",
    cityId: "79",
    name: "Elbeyli",
    slug: "elbeyli"
  },
  {
    id: "1476",
    cityId: "79",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "2024",
    cityId: "79",
    name: "Musabeyli",
    slug: "musabeyli"
  },
  {
    id: "2025",
    cityId: "79",
    name: "Polateli",
    slug: "polateli"
  },
  {
    id: "1165",
    cityId: "80",
    name: "Bahçe",
    slug: "bahce"
  },
  {
    id: "1743",
    cityId: "80",
    name: "Düziçi",
    slug: "duzici"
  },
  {
    id: "2027",
    cityId: "80",
    name: "Hasanbeyli",
    slug: "hasanbeyli"
  },
  {
    id: "1423",
    cityId: "80",
    name: "Kadirli",
    slug: "kadirli"
  },
  {
    id: "1560",
    cityId: "80",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "2028",
    cityId: "80",
    name: "Sumbas",
    slug: "sumbas"
  },
  {
    id: "2029",
    cityId: "80",
    name: "Toprakkale",
    slug: "toprakkale"
  },
  {
    id: "1116",
    cityId: "81",
    name: "Akçakoca",
    slug: "akcakoca"
  },
  {
    id: "1784",
    cityId: "81",
    name: "Cumayeri",
    slug: "cumayeri"
  },
  {
    id: "1905",
    cityId: "81",
    name: "Çilimli",
    slug: "cilimli"
  },
  {
    id: "1794",
    cityId: "81",
    name: "Gölyaka",
    slug: "golyaka"
  },
  {
    id: "2017",
    cityId: "81",
    name: "Gümüşova",
    slug: "gumusova"
  },
  {
    id: "2031",
    cityId: "81",
    name: "Kaynaşlı",
    slug: "kaynasli"
  },
  {
    id: "1292",
    cityId: "81",
    name: "Merkez",
    slug: "merkez"
  },
  {
    id: "1730",
    cityId: "81",
    name: "Yığılca",
    slug: "yigilca"
  }
];

const cities = [
  {
    id: "01",
    name: "Adana",
    slug: "adana"
  },
  {
    id: "02",
    name: "Adıyaman",
    slug: "adiyaman"
  },
  {
    id: "03",
    name: "Afyon",
    slug: "afyon"
  },
  {
    id: "04",
    name: "Ağrı",
    slug: "agri"
  },
  {
    id: "68",
    name: "Aksaray",
    slug: "aksaray"
  },
  {
    id: "05",
    name: "Amasya",
    slug: "amasya"
  },
  {
    id: "06",
    name: "Ankara",
    slug: "ankara"
  },
  {
    id: "07",
    name: "Antalya",
    slug: "antalya"
  },
  {
    id: "75",
    name: "Ardahan",
    slug: "ardahan"
  },
  {
    id: "08",
    name: "Artvin",
    slug: "artvin"
  },
  {
    id: "09",
    name: "Aydın",
    slug: "aydin"
  },
  {
    id: "10",
    name: "Balıkesir",
    slug: "balikesir"
  },
  {
    id: "74",
    name: "Bartın",
    slug: "bartin"
  },
  {
    id: "72",
    name: "Batman",
    slug: "batman"
  },
  {
    id: "69",
    name: "Bayburt",
    slug: "bayburt"
  },
  {
    id: "11",
    name: "Bilecik",
    slug: "bilecik"
  },
  {
    id: "12",
    name: "Bingöl",
    slug: "bingol"
  },
  {
    id: "13",
    name: "Bitlis",
    slug: "bitlis"
  },
  {
    id: "14",
    name: "Bolu",
    slug: "bolu"
  },
  {
    id: "15",
    name: "Burdur",
    slug: "burdur"
  },
  {
    id: "16",
    name: "Bursa",
    slug: "bursa"
  },
  {
    id: "17",
    name: "Çanakkale",
    slug: "canakkale"
  },
  {
    id: "18",
    name: "Çankırı",
    slug: "cankiri"
  },
  {
    id: "19",
    name: "Çorum",
    slug: "corum"
  },
  {
    id: "20",
    name: "Denizli",
    slug: "denizli"
  },
  {
    id: "21",
    name: "Diyarbakır",
    slug: "diyarbakir"
  },
  {
    id: "81",
    name: "Düzce",
    slug: "duzce"
  },
  {
    id: "22",
    name: "Edirne",
    slug: "edirne"
  },
  {
    id: "23",
    name: "Elazığ",
    slug: "elazig"
  },
  {
    id: "24",
    name: "Erzincan",
    slug: "erzincan"
  },
  {
    id: "25",
    name: "Erzurum",
    slug: "erzurum"
  },
  {
    id: "26",
    name: "Eskişehir",
    slug: "eskisehir"
  },
  {
    id: "27",
    name: "Gaziantep",
    slug: "gaziantep"
  },
  {
    id: "28",
    name: "Giresun",
    slug: "giresun"
  },
  {
    id: "29",
    name: "Gümüşhane",
    slug: "gumushane"
  },
  {
    id: "30",
    name: "Hakkari",
    slug: "hakkari"
  },
  {
    id: "31",
    name: "Hatay",
    slug: "hatay"
  },
  {
    id: "76",
    name: "Iğdır",
    slug: "igdir"
  },
  {
    id: "32",
    name: "Isparta",
    slug: "isparta"
  },
  {
    id: "34",
    name: "İstanbul",
    slug: "istanbul"
  },
  {
    id: "35",
    name: "İzmir",
    slug: "izmir"
  },
  {
    id: "46",
    name: "Kahramanmaraş",
    slug: "kahramanmaras"
  },
  {
    id: "78",
    name: "Karabük",
    slug: "karabuk"
  },
  {
    id: "70",
    name: "Karaman",
    slug: "karaman"
  },
  {
    id: "36",
    name: "Kars",
    slug: "kars"
  },
  {
    id: "37",
    name: "Kastamonu",
    slug: "kastamonu"
  },
  {
    id: "38",
    name: "Kayseri",
    slug: "kayseri"
  },
  {
    id: "79",
    name: "Kilis",
    slug: "kilis"
  },
  {
    id: "71",
    name: "Kırıkkale",
    slug: "kirikkale"
  },
  {
    id: "39",
    name: "Kırklareli",
    slug: "kirklareli"
  },
  {
    id: "40",
    name: "Kırşehir",
    slug: "kirsehir"
  },
  {
    id: "41",
    name: "Kocaeli",
    slug: "kocaeli"
  },
  {
    id: "42",
    name: "Konya",
    slug: "konya"
  },
  {
    id: "43",
    name: "Kütahya",
    slug: "kutahya"
  },
  {
    id: "44",
    name: "Malatya",
    slug: "malatya"
  },
  {
    id: "45",
    name: "Manisa",
    slug: "manisa"
  },
  {
    id: "47",
    name: "Mardin",
    slug: "mardin"
  },
  {
    id: "33",
    name: "Mersin",
    slug: "mersin"
  },
  {
    id: "48",
    name: "Muğla",
    slug: "mugla"
  },
  {
    id: "49",
    name: "Muş",
    slug: "mus"
  },
  {
    id: "50",
    name: "Nevşehir",
    slug: "nevsehir"
  },
  {
    id: "51",
    name: "Niğde",
    slug: "nigde"
  },
  {
    id: "52",
    name: "Ordu",
    slug: "ordu"
  },
  {
    id: "80",
    name: "Osmaniye",
    slug: "osmaniye"
  },
  {
    id: "53",
    name: "Rize",
    slug: "rize"
  },
  {
    id: "54",
    name: "Sakarya",
    slug: "sakarya"
  },
  {
    id: "55",
    name: "Samsun",
    slug: "samsun"
  },
  {
    id: "56",
    name: "Siirt",
    slug: "siirt"
  },
  {
    id: "57",
    name: "Sinop",
    slug: "sinop"
  },
  {
    id: "58",
    name: "Sivas",
    slug: "sivas"
  },
  {
    id: "63",
    name: "Şanlıurfa",
    slug: "sanliurfa"
  },
  {
    id: "73",
    name: "Şırnak",
    slug: "sirnak"
  },
  {
    id: "59",
    name: "Tekirdağ",
    slug: "tekirdag"
  },
  {
    id: "60",
    name: "Tokat",
    slug: "tokat"
  },
  {
    id: "61",
    name: "Trabzon",
    slug: "trabzon"
  },
  {
    id: "62",
    name: "Tunceli",
    slug: "tunceli"
  },
  {
    id: "64",
    name: "Uşak",
    slug: "usak"
  },
  {
    id: "65",
    name: "Van",
    slug: "van"
  },
  {
    id: "77",
    name: "Yalova",
    slug: "yalova"
  },
  {
    id: "66",
    name: "Yozgat",
    slug: "yozgat"
  },
  {
    id: "67",
    name: "Zonguldak",
    slug: "zonguldak"
  }
];

const tradeRegistrationOffices = [
  "Adana",
  "Adana - Ceyhan",
  "Adana - Kozan",
  "Adıyaman",
  "Afyonkarahisar",
  "Afyonkarahisar - Bolvadin",
  "Afyonkarahisar - Dinar",
  "Afyonkarahisar - Emirdağ",
  "Afyonkarahisar - Sandıklı",
  "Afyonkarahisar - Çay",
  "Aksaray",
  "Amasya",
  "Amasya - Gümüşhacıköy",
  "Amasya - Merzifon",
  "Amasya - Suluova",
  "Ankara",
  "Ankara - Beypazarı",
  "Ankara - Haymana",
  "Ankara - Polatlı",
  "Ankara - Şereflikoçhisar",
  "Antalya",
  "Antalya - Alanya",
  "Antalya - Kumluca",
  "Antalya - Manavgat",
  "Ardahan",
  "Artvin",
  "Artvin - Arhavi",
  "Artvin - Borçka",
  "Artvin - Hopa",
  "Aydın",
  "Aydın - Didim",
  "Aydın - Kuşadası",
  "Aydın - Nazilli",
  "Aydın - Söke",
  "Ağrı",
  "Ağrı - Doğubayazıt",
  "Balıkesir",
  "Balıkesir - Ayvalık",
  "Balıkesir - Bandırma",
  "Balıkesir - Burhaniye",
  "Balıkesir - Edremit",
  "Balıkesir - Erdek",
  "Balıkesir - Gönen",
  "Balıkesir - Susurluk",
  "Bartın",
  "Batman",
  "Bayburt",
  "Bilecik",
  "Bilecik - Bozüyük",
  "Bingöl",
  "Bitlis",
  "Bitlis - Tatvan",
  "Bolu",
  "Bolu - Gerede",
  "Burdur",
  "Burdur - Bucak",
  "Bursa",
  "Bursa - Gemlik",
  "Bursa - Karacabey",
  "Bursa - Mustafakemalpaşa",
  "Bursa - Orhangazi",
  "Bursa - Yenişehir",
  "Bursa - İnegöl",
  "Bursa - İznik",
  "Çanakkale",
  "Çanakkale - Biga",
  "Çanakkale - Gelibolu",
  "Çankırı",
  "Çorum",
  "Çorum - Alaca",
  "Çorum - Sungurlu",
  "Denizli",
  "Denizli - Acıpayam",
  "Denizli - Babadağ",
  "Denizli - Buldan",
  "Denizli - Sarayköy",
  "Denizli - Tavas",
  "Diyarbakır",
  "Düzce",
  "Düzce - Akçakoca",
  "Edirne",
  "Edirne - Keşan",
  "Edirne - Uzunköprü",
  "Elazığ",
  "Erzincan",
  "Erzurum",
  "Erzurum - Oltu",
  "Erzurum - Pasinler",
  "Eskişehir",
  "Gaziantep",
  "Gaziantep - Nizip",
  "Gaziantep - İslahiye",
  "Giresun",
  "Giresun - Bulancak",
  "Gümüşhane",
  "Gümüşhane - Kelkit",
  "Hakkari",
  "Hakkari - Yüksekova",
  "Hatay",
  "Hatay - Dörtyol",
  "Hatay - Erzin",
  "Hatay - Kırıkhan",
  "Hatay - Reyhanlı",
  "Hatay - İskenderun",
  "Isparta",
  "Isparta - Yalvaç",
  "Iğdır",
  "İstanbul",
  "İzmir",
  "İzmir - Aliağa",
  "İzmir - Bayındır",
  "İzmir - Bergama",
  "İzmir - Menemen",
  "İzmir - Selçuk",
  "İzmir - Tire",
  "İzmir - Torbalı",
  "İzmir - Ödemiş",
  "Kahramanmaraş",
  "Kahramanmaraş - Afşin",
  "Kahramanmaraş - Elbistan",
  "Karabük",
  "Karabük - Safranbolu",
  "Karaman",
  "Kars",
  "Kastamonu",
  "Kastamonu - Taşköprü",
  "Kastamonu - Tosya",
  "Kastamonu - İnebolu",
  "Kayseri",
  "Kayseri - Bünyan",
  "Kayseri - Develi",
  "Kayseri - Yahyalı",
  "Kilis",
  "Kocaeli",
  "Kocaeli - Gebze",
  "Kocaeli - Körfez",
  "Konya",
  "Konya - Akşehir",
  "Konya - Beyşehir",
  "Konya - Doğanhisar",
  "Konya - Ilgın",
  "Konya - Karapınar",
  "Konya - Konya Ereğli",
  "Konya - Seydişehir",
  "Konya - Çumra",
  "Kütahya",
  "Kütahya - Gediz",
  "Kütahya - Simav",
  "Kütahya - Tavşanlı",
  "Kırklareli",
  "Kırklareli - Babaeski",
  "Kırklareli - Lüleburgaz",
  "Kırıkkale",
  "Kırşehir",
  "Kırşehir - Kaman",
  "Kırşehir - Mucur",
  "Malatya",
  "Manisa",
  "Manisa - Akhisar",
  "Manisa - Alaşehir",
  "Manisa - Demirci",
  "Manisa - Gördes",
  "Manisa - Salihli",
  "Manisa - Soma",
  "Manisa - Turgutlu",
  "Mardin",
  "Mardin - Kızıltepe",
  "Mardin - Nusaybin",
  "Mersin",
  "Mersin - Anamur",
  "Mersin - Erdemli",
  "Mersin - Mut",
  "Mersin - Silifke",
  "Mersin - Tarsus",
  "Muğla",
  "Muğla - Bodrum",
  "Muğla - Fethiye",
  "Muğla - Marmaris",
  "Muğla - Milas",
  "Muş",
  "Nevşehir",
  "Nevşehir - Ürgüp",
  "Niğde",
  "Niğde - Bor",
  "Ordu",
  "Ordu - Fatsa",
  "Ordu - Ünye",
  "Osmaniye",
  "Osmaniye - Kadirli",
  "Rize",
  "Rize - Ardeşen",
  "Rize - Pazar",
  "Rize - Çayeli",
  "Sakarya",
  "Sakarya - Akyazı",
  "Samsun",
  "Samsun - Bafra",
  "Samsun - Havza",
  "Samsun - Terme",
  "Samsun - Vezirköprü",
  "Samsun - Çarşamba",
  "Siirt",
  "Sinop",
  "Sinop - Boyabat",
  "Sivas",
  "Şanlıurfa",
  "Şanlıurfa - Birecik",
  "Şanlıurfa - Siverek",
  "Şırnak",
  "Şırnak - Cizre",
  "Tekirdağ",
  "Tekirdağ - Hayrabolu",
  "Tekirdağ - Malkara",
  "Tekirdağ - Çerkezköy",
  "Tekirdağ - Çorlu",
  "Tokat",
  "Tokat - Erbaa",
  "Tokat - Niksar",
  "Tokat - Turhal",
  "Tokat - Zile",
  "Trabzon",
  "Trabzon - Of",
  "Tunceli",
  "Uşak",
  "Uşak - Karahallı",
  "Van",
  "Van - Erciş",
  "Yalova",
  "Yozgat",
  "Yozgat - Boğazlıyan",
  "Yozgat - Sorgun",
  "Yozgat - Yerköy",
  "Yozgat - Şefaatli",
  "Zonguldak",
  "Zonguldak - Alaplı",
  "Zonguldak - Devrek",
  "Zonguldak - Karadeniz Ereğli",
  "Zonguldak - Çaycuma"
];

const taxOffices = [
  {
    id: "1250",
    city: "Adana",
    county: "Merkez",
    name: "Adana İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1251",
    city: "Adana",
    county: "Merkez",
    name: "5 Ocak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1252",
    city: "Adana",
    county: "Merkez",
    name: "Yüreğir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1253",
    city: "Adana",
    county: "Merkez",
    name: "Seyhan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1254",
    city: "Adana",
    county: "Merkez",
    name: "Ziyapaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1255",
    city: "Adana",
    county: "Merkez",
    name: "Çukurova",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1201",
    city: "Adana",
    county: "Ceyhan",
    name: "Ceyhan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1203",
    city: "Adana",
    county: "Kozan",
    name: "Kozan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1205",
    city: "Adana",
    county: "Karataş",
    name: "Karataş",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "1103",
    city: "Adana",
    county: "Feke",
    name: "Feke",
    type: "Malmüdürlüğü"
  },
  {
    id: "1105",
    city: "Adana",
    county: "Karaisalı",
    name: "Karaisalı",
    type: "Malmüdürlüğü"
  },
  {
    id: "1109",
    city: "Adana",
    county: "Pozantı",
    name: "Pozantı",
    type: "Malmüdürlüğü"
  },
  {
    id: "1110",
    city: "Adana",
    county: "Saimbeyli",
    name: "Saimbeyli",
    type: "Malmüdürlüğü"
  },
  {
    id: "1111",
    city: "Adana",
    county: "Tufanbeyli",
    name: "Tufanbeyli",
    type: "Malmüdürlüğü"
  },
  {
    id: "1112",
    city: "Adana",
    county: "Yumurtalık",
    name: "Yumurtalık",
    type: "Malmüdürlüğü"
  },
  {
    id: "1114",
    city: "Adana",
    county: "Aladağ",
    name: "Aladağ",
    type: "Malmüdürlüğü"
  },
  {
    id: "1115",
    city: "Adana",
    county: "İmamoğlu",
    name: "İmamoğlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "2260",
    city: "Adıyaman",
    county: "Merkez",
    name: "Adıyaman",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "2261",
    city: "Adıyaman",
    county: "Kahta",
    name: "Kahta",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "2101",
    city: "Adıyaman",
    county: "Besni",
    name: "Besni",
    type: "Malmüdürlüğü"
  },
  {
    id: "2102",
    city: "Adıyaman",
    county: "Çelikhan",
    name: "Çelikhan",
    type: "Malmüdürlüğü"
  },
  {
    id: "2103",
    city: "Adıyaman",
    county: "Gerger",
    name: "Gerger",
    type: "Malmüdürlüğü"
  },
  {
    id: "2104",
    city: "Adıyaman",
    county: "Gölbaşı",
    name: "Gölbaşı",
    type: "Malmüdürlüğü"
  },
  {
    id: "2106",
    city: "Adıyaman",
    county: "Samsat",
    name: "Samsat",
    type: "Malmüdürlüğü"
  },
  {
    id: "2107",
    city: "Adıyaman",
    county: "Sincik",
    name: "Sincik",
    type: "Malmüdürlüğü"
  },
  {
    id: "2108",
    city: "Adıyaman",
    county: "Tut",
    name: "Tut",
    type: "Malmüdürlüğü"
  },
  {
    id: "3201",
    city: "Afyonkarahisar",
    county: "Merkez",
    name: "Tınaztepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3280",
    city: "Afyonkarahisar",
    county: "Merkez",
    name: "Kocatepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3260",
    city: "Afyonkarahisar",
    county: "Dinar",
    name: "Dinar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3261",
    city: "Afyonkarahisar",
    county: "Bolvadin",
    name: "Bolvadin",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3262",
    city: "Afyonkarahisar",
    county: "Emirdağ",
    name: "Emirdağ",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3263",
    city: "Afyonkarahisar",
    county: "Sandıklı",
    name: "Sandıklı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3264",
    city: "Afyonkarahisar",
    county: "İscehisar",
    name: "İscehisar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3202",
    city: "Afyonkarahisar",
    county: "Çay",
    name: "Çay",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "3103",
    city: "Afyonkarahisar",
    county: "Dazkırı",
    name: "Dazkırı",
    type: "Malmüdürlüğü"
  },
  {
    id: "3105",
    city: "Afyonkarahisar",
    county: "İhsaniye",
    name: "İhsaniye",
    type: "Malmüdürlüğü"
  },
  {
    id: "3107",
    city: "Afyonkarahisar",
    county: "Sinanpaşa",
    name: "Sinanpaşa",
    type: "Malmüdürlüğü"
  },
  {
    id: "3108",
    city: "Afyonkarahisar",
    county: "Sultandağı",
    name: "Sultandağı",
    type: "Malmüdürlüğü"
  },
  {
    id: "3109",
    city: "Afyonkarahisar",
    county: "Şuhut",
    name: "Şuhut",
    type: "Malmüdürlüğü"
  },
  {
    id: "3111",
    city: "Afyonkarahisar",
    county: "Başmakçı",
    name: "Başmakçı",
    type: "Malmüdürlüğü"
  },
  {
    id: "3112",
    city: "Afyonkarahisar",
    county: "Bayat",
    name: "Bayat",
    type: "Malmüdürlüğü"
  },
  {
    id: "3114",
    city: "Afyonkarahisar",
    county: "Çobanlar",
    name: "Çobanlar",
    type: "Malmüdürlüğü"
  },
  {
    id: "3115",
    city: "Afyonkarahisar",
    county: "Evciler",
    name: "Evciler",
    type: "Malmüdürlüğü"
  },
  {
    id: "3116",
    city: "Afyonkarahisar",
    county: "Hocalar",
    name: "Hocalar",
    type: "Malmüdürlüğü"
  },
  {
    id: "3117",
    city: "Afyonkarahisar",
    county: "Kızılören",
    name: "Kızılören",
    type: "Malmüdürlüğü"
  },
  {
    id: "4260",
    city: "Ağrı",
    county: "Merkez",
    name: "Ağrı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "4261",
    city: "Ağrı",
    county: "Doğubeyazıt",
    name: "Doğubeyazıt",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "4262",
    city: "Ağrı",
    county: "Patnos",
    name: "Patnos",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "4101",
    city: "Ağrı",
    county: "Diyadin",
    name: "Diyadin",
    type: "Malmüdürlüğü"
  },
  {
    id: "4103",
    city: "Ağrı",
    county: "Eleşkirt",
    name: "Eleşkirt",
    type: "Malmüdürlüğü"
  },
  {
    id: "4104",
    city: "Ağrı",
    county: "Hamur",
    name: "Hamur",
    type: "Malmüdürlüğü"
  },
  {
    id: "4106",
    city: "Ağrı",
    county: "Taşlıçay",
    name: "Taşlıçay",
    type: "Malmüdürlüğü"
  },
  {
    id: "4107",
    city: "Ağrı",
    county: "Tutak",
    name: "Tutak",
    type: "Malmüdürlüğü"
  },
  {
    id: "5260",
    city: "Amasya",
    county: "Merkez",
    name: "Amasya",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "5261",
    city: "Amasya",
    county: "Merzifon",
    name: "Merzifon",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "5262",
    city: "Amasya",
    county: "Gümüşhacıköy",
    name: "Gümüşhacıköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "5263",
    city: "Amasya",
    county: "Taşova",
    name: "Taşova",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "5264",
    city: "Amasya",
    county: "Suluova",
    name: "Suluova",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "5101",
    city: "Amasya",
    county: "Göynücek",
    name: "Göynücek",
    type: "Malmüdürlüğü"
  },
  {
    id: "5106",
    city: "Amasya",
    county: "Hamamözü",
    name: "Hamamözü",
    type: "Malmüdürlüğü"
  },
  {
    id: "6280",
    city: "Ankara",
    county: "Merkez",
    name: "Anadolu İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6281",
    city: "Ankara",
    county: "Merkez",
    name: "Ankara İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6252",
    city: "Ankara",
    county: "Merkez",
    name: "Veraset ve Harçlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6253",
    city: "Ankara",
    county: "Merkez",
    name: "Kavaklıdere",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6254",
    city: "Ankara",
    county: "Merkez",
    name: "Maltepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6255",
    city: "Ankara",
    county: "Merkez",
    name: "Yenimahalle",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6257",
    city: "Ankara",
    county: "Merkez",
    name: "Çankaya",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6258",
    city: "Ankara",
    county: "Merkez",
    name: "Kızılbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6259",
    city: "Ankara",
    county: "Merkez",
    name: "Mithatpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6260",
    city: "Ankara",
    county: "Merkez",
    name: "Ulus",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6261",
    city: "Ankara",
    county: "Merkez",
    name: "Yıldırım Beyazıt",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6262",
    city: "Ankara",
    county: "Merkez",
    name: "Seğmenler",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6264",
    city: "Ankara",
    county: "Merkez",
    name: "Dikimevi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6265",
    city: "Ankara",
    county: "Merkez",
    name: "Doğanbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6266",
    city: "Ankara",
    county: "Merkez",
    name: "Yeğenbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6268",
    city: "Ankara",
    county: "Merkez",
    name: "Hitit",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6269",
    city: "Ankara",
    county: "Merkez",
    name: "Yahya Galip",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6270",
    city: "Ankara",
    county: "Merkez",
    name: "Muhammet Karagüzel",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6271",
    city: "Ankara",
    county: "Merkez",
    name: "Ostim",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6272",
    city: "Ankara",
    county: "Merkez",
    name: "Gölbaşı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6273",
    city: "Ankara",
    county: "Merkez",
    name: "Sincan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6274",
    city: "Ankara",
    county: "Merkez",
    name: "Dışkapı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6275",
    city: "Ankara",
    county: "Merkez",
    name: "Etimesgut",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6276",
    city: "Ankara",
    county: "Merkez",
    name: "Başkent",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6277",
    city: "Ankara",
    county: "Merkez",
    name: "Cumhuriyet",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6278",
    city: "Ankara",
    county: "Merkez",
    name: "Keçiören",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6279",
    city: "Ankara",
    county: "Merkez- (5216)",
    name: "Kahramankazan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6284",
    city: "Ankara",
    county: "Yenimahalle",
    name: "İvedik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6205",
    city: "Ankara",
    county: "Polatlı",
    name: "Polatlı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6207",
    city: "Ankara",
    county: "Şereflikoçhisar",
    name: "Şereflikoçhisar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6209",
    city: "Ankara",
    county: "Beypazarı",
    name: "Beypazarı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6211",
    city: "Ankara",
    county: "Merkez- (5216)",
    name: "Çubuk",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6213",
    city: "Ankara",
    county: "Haymana",
    name: "Haymana",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6215",
    city: "Ankara",
    county: "Merkez- (5216)",
    name: "Elmadağ",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "6102",
    city: "Ankara",
    county: "Merkez- (5216)",
    name: "Ayaş",
    type: "Malmüdürlüğü"
  },
  {
    id: "6103",
    city: "Ankara",
    county: "Merkez- (5216)",
    name: "Balâ",
    type: "Malmüdürlüğü"
  },
  {
    id: "6106",
    city: "Ankara",
    county: "Çamlıdere",
    name: "Çamlıdere",
    type: "Malmüdürlüğü"
  },
  {
    id: "6110",
    city: "Ankara",
    county: "Güdül",
    name: "Güdül",
    type: "Malmüdürlüğü"
  },
  {
    id: "6112",
    city: "Ankara",
    county: "Merkez- (5216)",
    name: "Kalecik",
    type: "Malmüdürlüğü"
  },
  {
    id: "6115",
    city: "Ankara",
    county: "Kızılcahamam",
    name: "Kızılcahamam",
    type: "Malmüdürlüğü"
  },
  {
    id: "6117",
    city: "Ankara",
    county: "Nallıhan",
    name: "Nallıhan",
    type: "Malmüdürlüğü"
  },
  {
    id: "6125",
    city: "Ankara",
    county: "Merkez- (5216)",
    name: "Akyurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "6127",
    city: "Ankara",
    county: "Evren",
    name: "Evren",
    type: "Malmüdürlüğü"
  },
  {
    id: "7251",
    city: "Antalya",
    county: "Merkez",
    name: "Üçkapılar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7252",
    city: "Antalya",
    county: "Merkez",
    name: "Kalekapı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7253",
    city: "Antalya",
    county: "Merkez",
    name: "Muratpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7254",
    city: "Antalya",
    county: "Merkez",
    name: "Düden",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7255",
    city: "Antalya",
    county: "Merkez",
    name: "Antalya Kurumlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7256",
    city: "Antalya",
    county: "Merkez",
    name: "Antalya İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7257",
    city: "Antalya",
    county: "Korkuteli",
    name: "Korkuteli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7201",
    city: "Antalya",
    county: "Alanya",
    name: "Alanya",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7202",
    city: "Antalya",
    county: "Serik",
    name: "Serik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7203",
    city: "Antalya",
    county: "Manavgat",
    name: "Manavgat",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7204",
    city: "Antalya",
    county: "Elmalı",
    name: "Elmalı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7205",
    city: "Antalya",
    county: "Kemer",
    name: "Kemer",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7206",
    city: "Antalya",
    county: "Kumluca",
    name: "Kumluca",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "7101",
    city: "Antalya",
    county: "Akseki",
    name: "Akseki",
    type: "Malmüdürlüğü"
  },
  {
    id: "7104",
    city: "Antalya",
    county: "Finike",
    name: "Finike",
    type: "Malmüdürlüğü"
  },
  {
    id: "7105",
    city: "Antalya",
    county: "Gazipaşa",
    name: "Gazipaşa",
    type: "Malmüdürlüğü"
  },
  {
    id: "7106",
    city: "Antalya",
    county: "Gündoğmuş",
    name: "Gündoğmuş",
    type: "Malmüdürlüğü"
  },
  {
    id: "7107",
    city: "Antalya",
    county: "Kaş",
    name: "Kaş",
    type: "Malmüdürlüğü"
  },
  {
    id: "7112",
    city: "Antalya",
    county: "Demre",
    name: "Demre",
    type: "Malmüdürlüğü"
  },
  {
    id: "7113",
    city: "Antalya",
    county: "İbradı",
    name: "İbradı",
    type: "Malmüdürlüğü"
  },
  {
    id: "8260",
    city: "Artvin",
    county: "Merkez",
    name: "Artvin",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "8261",
    city: "Artvin",
    county: "Hopa",
    name: "Hopa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "8262",
    city: "Artvin",
    county: "Arhavi",
    name: "Arhavi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "8101",
    city: "Artvin",
    county: "Ardanuç",
    name: "Ardanuç",
    type: "Malmüdürlüğü"
  },
  {
    id: "8103",
    city: "Artvin",
    county: "Borçka",
    name: "Borçka",
    type: "Malmüdürlüğü"
  },
  {
    id: "8105",
    city: "Artvin",
    county: "Şavşat",
    name: "Şavşat",
    type: "Malmüdürlüğü"
  },
  {
    id: "8106",
    city: "Artvin",
    county: "Yusufeli",
    name: "Yusufeli",
    type: "Malmüdürlüğü"
  },
  {
    id: "8107",
    city: "Artvin",
    county: "Murgul",
    name: "Murgul",
    type: "Malmüdürlüğü"
  },
  {
    id: "9201",
    city: "Aydın",
    county: "Merkez",
    name: "Efeler",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9280",
    city: "Aydın",
    county: "Merkez",
    name: "Güzelhisar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9260",
    city: "Aydın",
    county: "Nazilli",
    name: "Nazilli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9261",
    city: "Aydın",
    county: "Söke",
    name: "Söke",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9262",
    city: "Aydın",
    county: "Çine",
    name: "Çine",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9263",
    city: "Aydın",
    county: "Germencik",
    name: "Germencik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9265",
    city: "Aydın",
    county: "Kuşadası",
    name: "Kuşadası",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9281",
    city: "Aydın",
    county: "Didim",
    name: "Didim",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "9101",
    city: "Aydın",
    county: "Bozdoğan",
    name: "Bozdoğan",
    type: "Malmüdürlüğü"
  },
  {
    id: "9104",
    city: "Aydın",
    county: "Karacasu",
    name: "Karacasu",
    type: "Malmüdürlüğü"
  },
  {
    id: "9105",
    city: "Aydın",
    county: "Koçarlı",
    name: "Koçarlı",
    type: "Malmüdürlüğü"
  },
  {
    id: "9107",
    city: "Aydın",
    county: "Kuyucak",
    name: "Kuyucak",
    type: "Malmüdürlüğü"
  },
  {
    id: "9110",
    city: "Aydın",
    county: "Sultanhisar",
    name: "Sultanhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "9111",
    city: "Aydın",
    county: "Yenipazar",
    name: "Yenipazar",
    type: "Malmüdürlüğü"
  },
  {
    id: "9112",
    city: "Aydın",
    county: "Buharkent",
    name: "Buharkent",
    type: "Malmüdürlüğü"
  },
  {
    id: "9113",
    city: "Aydın",
    county: "İncirliova",
    name: "İncirliova",
    type: "Malmüdürlüğü"
  },
  {
    id: "9114",
    city: "Aydın",
    county: "Karpuzlu",
    name: "Karpuzlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "9115",
    city: "Aydın",
    county: "Köşk",
    name: "Köşk",
    type: "Malmüdürlüğü"
  },
  {
    id: "10201",
    city: "Balıkesir",
    county: "Merkez",
    name: "Karesi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10280",
    city: "Balıkesir",
    county: "Merkez",
    name: "Kurtdereli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10260",
    city: "Balıkesir",
    county: "Ayvalık",
    name: "Ayvalık",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10261",
    city: "Balıkesir",
    county: "Bandırma",
    name: "Bandırma",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10262",
    city: "Balıkesir",
    county: "Burhaniye",
    name: "Burhaniye",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10263",
    city: "Balıkesir",
    county: "Edremit",
    name: "Edremit",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10264",
    city: "Balıkesir",
    county: "Gönen",
    name: "Gönen",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10265",
    city: "Balıkesir",
    county: "Susurluk",
    name: "Susurluk",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10266",
    city: "Balıkesir",
    county: "Erdek",
    name: "Erdek",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10267",
    city: "Balıkesir",
    county: "Bigadiç",
    name: "Bigadiç",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10268",
    city: "Balıkesir",
    county: "Sındırgı",
    name: "Sındırgı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10269",
    city: "Balıkesir",
    county: "Dursunbey",
    name: "Dursunbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "10102",
    city: "Balıkesir",
    county: "Balya",
    name: "Balya",
    type: "Malmüdürlüğü"
  },
  {
    id: "10110",
    city: "Balıkesir",
    county: "Havran",
    name: "Havran",
    type: "Malmüdürlüğü"
  },
  {
    id: "10111",
    city: "Balıkesir",
    county: "İvrindi",
    name: "İvrindi",
    type: "Malmüdürlüğü"
  },
  {
    id: "10112",
    city: "Balıkesir",
    county: "Kepsut",
    name: "Kepsut",
    type: "Malmüdürlüğü"
  },
  {
    id: "10113",
    city: "Balıkesir",
    county: "Manyas",
    name: "Manyas",
    type: "Malmüdürlüğü"
  },
  {
    id: "10114",
    city: "Balıkesir",
    county: "Savaştepe",
    name: "Savaştepe",
    type: "Malmüdürlüğü"
  },
  {
    id: "10117",
    city: "Balıkesir",
    county: "Marmara",
    name: "Marmara",
    type: "Malmüdürlüğü"
  },
  {
    id: "10118",
    city: "Balıkesir",
    county: "Gömeç",
    name: "Gömeç",
    type: "Malmüdürlüğü"
  },
  {
    id: "11260",
    city: "Bilecik",
    county: "Merkez",
    name: "Bilecik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "11261",
    city: "Bilecik",
    county: "Bozüyük",
    name: "Bozüyük",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "11102",
    city: "Bilecik",
    county: "Gölpazarı",
    name: "Gölpazarı",
    type: "Malmüdürlüğü"
  },
  {
    id: "11103",
    city: "Bilecik",
    county: "Osmaneli",
    name: "Osmaneli",
    type: "Malmüdürlüğü"
  },
  {
    id: "11104",
    city: "Bilecik",
    county: "Pazaryeri",
    name: "Pazaryeri",
    type: "Malmüdürlüğü"
  },
  {
    id: "11105",
    city: "Bilecik",
    county: "Söğüt",
    name: "Söğüt",
    type: "Malmüdürlüğü"
  },
  {
    id: "11106",
    city: "Bilecik",
    county: "Yenipazar",
    name: "Yenipazar",
    type: "Malmüdürlüğü"
  },
  {
    id: "11107",
    city: "Bilecik",
    county: "İnhisar",
    name: "İnhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "12260",
    city: "Bingöl",
    county: "Merkez",
    name: "Bingöl",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "12101",
    city: "Bingöl",
    county: "Genç",
    name: "Genç",
    type: "Malmüdürlüğü"
  },
  {
    id: "12102",
    city: "Bingöl",
    county: "Karlıova",
    name: "Karlıova",
    type: "Malmüdürlüğü"
  },
  {
    id: "12103",
    city: "Bingöl",
    county: "Kiğı",
    name: "Kiğı",
    type: "Malmüdürlüğü"
  },
  {
    id: "12104",
    city: "Bingöl",
    county: "Solhan",
    name: "Solhan",
    type: "Malmüdürlüğü"
  },
  {
    id: "12105",
    city: "Bingöl",
    county: "Adaklı",
    name: "Adaklı",
    type: "Malmüdürlüğü"
  },
  {
    id: "12106",
    city: "Bingöl",
    county: "Yayladere",
    name: "Yayladere",
    type: "Malmüdürlüğü"
  },
  {
    id: "12107",
    city: "Bingöl",
    county: "Yedisu",
    name: "Yedisu",
    type: "Malmüdürlüğü"
  },
  {
    id: "13260",
    city: "Bitlis",
    county: "Merkez",
    name: "Bitlis",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "13261",
    city: "Bitlis",
    county: "Tatvan",
    name: "Tatvan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "13101",
    city: "Bitlis",
    county: "Adilcevaz",
    name: "Adilcevaz",
    type: "Malmüdürlüğü"
  },
  {
    id: "13102",
    city: "Bitlis",
    county: "Ahlat",
    name: "Ahlat",
    type: "Malmüdürlüğü"
  },
  {
    id: "13103",
    city: "Bitlis",
    county: "Hizan",
    name: "Hizan",
    type: "Malmüdürlüğü"
  },
  {
    id: "13104",
    city: "Bitlis",
    county: "Mutki",
    name: "Mutki",
    type: "Malmüdürlüğü"
  },
  {
    id: "13106",
    city: "Bitlis",
    county: "Göroymak",
    name: "Güroymak",
    type: "Malmüdürlüğü"
  },
  {
    id: "14260",
    city: "Bolu",
    county: "Merkez",
    name: "Bolu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "14262",
    city: "Bolu",
    county: "Gerede",
    name: "Gerede",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "14104",
    city: "Bolu",
    county: "Göynük",
    name: "Göynük",
    type: "Malmüdürlüğü"
  },
  {
    id: "14105",
    city: "Bolu",
    county: "Kıbrıscık",
    name: "Kıbrıscık",
    type: "Malmüdürlüğü"
  },
  {
    id: "14106",
    city: "Bolu",
    county: "Mengen",
    name: "Mengen",
    type: "Malmüdürlüğü"
  },
  {
    id: "14107",
    city: "Bolu",
    county: "Mudurnu",
    name: "Mudurnu",
    type: "Malmüdürlüğü"
  },
  {
    id: "14108",
    city: "Bolu",
    county: "Seben",
    name: "Seben",
    type: "Malmüdürlüğü"
  },
  {
    id: "14113",
    city: "Bolu",
    county: "Dörtdivan",
    name: "Dörtdivan",
    type: "Malmüdürlüğü"
  },
  {
    id: "14114",
    city: "Bolu",
    county: "Yeniçağa",
    name: "Yeniçağa",
    type: "Malmüdürlüğü"
  },
  {
    id: "15260",
    city: "Burdur",
    county: "Merkez",
    name: "Burdur",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "15261",
    city: "Burdur",
    county: "Bucak",
    name: "Bucak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "15101",
    city: "Burdur",
    county: "Ağlasun",
    name: "Ağlasun",
    type: "Malmüdürlüğü"
  },
  {
    id: "15103",
    city: "Burdur",
    county: "Gölhisar",
    name: "Gölhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "15104",
    city: "Burdur",
    county: "Tefenni",
    name: "Tefenni",
    type: "Malmüdürlüğü"
  },
  {
    id: "15105",
    city: "Burdur",
    county: "Yeşilova",
    name: "Yeşilova",
    type: "Malmüdürlüğü"
  },
  {
    id: "15106",
    city: "Burdur",
    county: "Karamanlı",
    name: "Karamanlı",
    type: "Malmüdürlüğü"
  },
  {
    id: "15107",
    city: "Burdur",
    county: "Kemer",
    name: "Kemer",
    type: "Malmüdürlüğü"
  },
  {
    id: "15108",
    city: "Burdur",
    county: "Altınyayla",
    name: "Altınyayla",
    type: "Malmüdürlüğü"
  },
  {
    id: "15109",
    city: "Burdur",
    county: "Çavdır",
    name: "Çavdır",
    type: "Malmüdürlüğü"
  },
  {
    id: "15110",
    city: "Burdur",
    county: "Çeltikçi",
    name: "Çeltikçi",
    type: "Malmüdürlüğü"
  },
  {
    id: "16250",
    city: "Bursa",
    county: "Merkez",
    name: "Bursa İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16251",
    city: "Bursa",
    county: "Merkez",
    name: "Osmangazi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16252",
    city: "Bursa",
    county: "Merkez",
    name: "Yıldırım",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16253",
    city: "Bursa",
    county: "Merkez",
    name: "Çekirge",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16254",
    city: "Bursa",
    county: "Merkez",
    name: "Setbaşı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16255",
    city: "Bursa",
    county: "Merkez",
    name: "Uludağ",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16256",
    city: "Bursa",
    county: "Merkez",
    name: "Yeşil",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16257",
    city: "Bursa",
    county: "Merkez",
    name: "Nilüfer",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16258",
    city: "Bursa",
    county: "Merkez",
    name: "Ertuğrulgazi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16259",
    city: "Bursa",
    county: "Merkez",
    name: "Gökdere",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16260",
    city: "Bursa",
    county: "Merkez",
    name: "Bursa Veraset ve Harçlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16201",
    city: "Bursa",
    county: "Merkez- (5216)",
    name: "Gemlik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16202",
    city: "Bursa",
    county: "İnegöl",
    name: "İnegöl",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16203",
    city: "Bursa",
    county: "Karacabey",
    name: "Karacabey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16204",
    city: "Bursa",
    county: "Mustafakemalpaşa",
    name: "Mustafakemalpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16205",
    city: "Bursa",
    county: "Merkez- (5216)",
    name: "Mudanya",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16206",
    city: "Bursa",
    county: "Orhangazi",
    name: "Orhangazi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16207",
    city: "Bursa",
    county: "İznik",
    name: "İznik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16208",
    city: "Bursa",
    county: "Yenişehir",
    name: "Yenişehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "16105",
    city: "Bursa",
    county: "Keles",
    name: "Keles",
    type: "Malmüdürlüğü"
  },
  {
    id: "16108",
    city: "Bursa",
    county: "Orhaneli",
    name: "Orhaneli",
    type: "Malmüdürlüğü"
  },
  {
    id: "16111",
    city: "Bursa",
    county: "Harmancık",
    name: "Harmancık",
    type: "Malmüdürlüğü"
  },
  {
    id: "16112",
    city: "Bursa",
    county: "Büyükorhan",
    name: "Büyükorhan",
    type: "Malmüdürlüğü"
  },
  {
    id: "17260",
    city: "Çanakkale",
    county: "Merkez",
    name: "Çanakkale",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "17261",
    city: "Çanakkale",
    county: "Biga",
    name: "Biga",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "17262",
    city: "Çanakkale",
    county: "Çan",
    name: "Çan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "17263",
    city: "Çanakkale",
    county: "Gelibolu",
    name: "Gelibolu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "17101",
    city: "Çanakkale",
    county: "Ayvacık",
    name: "Ayvacık",
    type: "Malmüdürlüğü"
  },
  {
    id: "17102",
    city: "Çanakkale",
    county: "Bayramiç",
    name: "Bayramiç",
    type: "Malmüdürlüğü"
  },
  {
    id: "17104",
    city: "Çanakkale",
    county: "Bozcaada",
    name: "Bozcaada",
    type: "Malmüdürlüğü"
  },
  {
    id: "17106",
    city: "Çanakkale",
    county: "Eceabat",
    name: "Eceabat",
    type: "Malmüdürlüğü"
  },
  {
    id: "17107",
    city: "Çanakkale",
    county: "Ezine",
    name: "Ezine",
    type: "Malmüdürlüğü"
  },
  {
    id: "17109",
    city: "Çanakkale",
    county: "Gökçeada",
    name: "Gökçeada",
    type: "Malmüdürlüğü"
  },
  {
    id: "17110",
    city: "Çanakkale",
    county: "Lapseki",
    name: "Lapseki",
    type: "Malmüdürlüğü"
  },
  {
    id: "17111",
    city: "Çanakkale",
    county: "Yenice",
    name: "Yenice",
    type: "Malmüdürlüğü"
  },
  {
    id: "18260",
    city: "Çankırı",
    county: "Merkez",
    name: "Çankırı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "18101",
    city: "Çankırı",
    county: "Çerkeş",
    name: "Çerkeş",
    type: "Malmüdürlüğü"
  },
  {
    id: "18102",
    city: "Çankırı",
    county: "Eldivan",
    name: "Eldivan",
    type: "Malmüdürlüğü"
  },
  {
    id: "18104",
    city: "Çankırı",
    county: "Ilgaz",
    name: "Ilgaz",
    type: "Malmüdürlüğü"
  },
  {
    id: "18105",
    city: "Çankırı",
    county: "Kurşunlu",
    name: "Kurşunlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "18106",
    city: "Çankırı",
    county: "Orta",
    name: "Orta",
    type: "Malmüdürlüğü"
  },
  {
    id: "18108",
    city: "Çankırı",
    county: "Şabanözü",
    name: "Şabanözü",
    type: "Malmüdürlüğü"
  },
  {
    id: "18109",
    city: "Çankırı",
    county: "Yapraklı",
    name: "Yapraklı",
    type: "Malmüdürlüğü"
  },
  {
    id: "18110",
    city: "Çankırı",
    county: "Atkaracalar",
    name: "Atkaracalar",
    type: "Malmüdürlüğü"
  },
  {
    id: "18111",
    city: "Çankırı",
    county: "Kızılırmak",
    name: "Kızılırmak",
    type: "Malmüdürlüğü"
  },
  {
    id: "18112",
    city: "Çankırı",
    county: "Bayramören",
    name: "Bayramören",
    type: "Malmüdürlüğü"
  },
  {
    id: "18113",
    city: "Çankırı",
    county: "Korgun",
    name: "Korgun",
    type: "Malmüdürlüğü"
  },
  {
    id: "19260",
    city: "Çorum",
    county: "Merkez",
    name: "Çorum",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "19261",
    city: "Çorum",
    county: "Sungurlu",
    name: "Sungurlu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "19101",
    city: "Çorum",
    county: "Alaca",
    name: "Alaca",
    type: "Malmüdürlüğü"
  },
  {
    id: "19102",
    city: "Çorum",
    county: "Bayat",
    name: "Bayat",
    type: "Malmüdürlüğü"
  },
  {
    id: "19103",
    city: "Çorum",
    county: "İskilip",
    name: "İskilip",
    type: "Malmüdürlüğü"
  },
  {
    id: "19104",
    city: "Çorum",
    county: "Kargı",
    name: "Kargı",
    type: "Malmüdürlüğü"
  },
  {
    id: "19105",
    city: "Çorum",
    county: "Mecitözü",
    name: "Mecitözü",
    type: "Malmüdürlüğü"
  },
  {
    id: "19106",
    city: "Çorum",
    county: "Ortaköy",
    name: "Ortaköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "19107",
    city: "Çorum",
    county: "Osmancık",
    name: "Osmancık",
    type: "Malmüdürlüğü"
  },
  {
    id: "19109",
    city: "Çorum",
    county: "Boğazkale",
    name: "Boğazkale",
    type: "Malmüdürlüğü"
  },
  {
    id: "19110",
    city: "Çorum",
    county: "Uğurludağ",
    name: "Uğurludağ",
    type: "Malmüdürlüğü"
  },
  {
    id: "19111",
    city: "Çorum",
    county: "Dodurga",
    name: "Dodurga",
    type: "Malmüdürlüğü"
  },
  {
    id: "19112",
    city: "Çorum",
    county: "Oğuzlar",
    name: "Oğuzlar",
    type: "Malmüdürlüğü"
  },
  {
    id: "19113",
    city: "Çorum",
    county: "Laçin",
    name: "Laçin",
    type: "Malmüdürlüğü"
  },
  {
    id: "20201",
    city: "Denizli",
    county: "Merkez",
    name: "Saraylar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20202",
    city: "Denizli",
    county: "Merkez",
    name: "Çınar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20203",
    city: "Denizli",
    county: "Merkez",
    name: "Gökpınar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20250",
    city: "Denizli",
    county: "Merkez",
    name: "Denizli İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20280",
    city: "Denizli",
    county: "Merkez",
    name: "Pamukkale",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20260",
    city: "Denizli",
    county: "Sarayköy",
    name: "Sarayköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20261",
    city: "Denizli",
    county: "Acıpayam",
    name: "Acıpayam",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20262",
    city: "Denizli",
    county: "Tavas",
    name: "Tavas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20263",
    city: "Denizli",
    county: "Buldan",
    name: "Buldan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20264",
    city: "Denizli",
    county: "Çal",
    name: "Çal",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20265",
    city: "Denizli",
    county: "Çivril",
    name: "Çivril",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "20104",
    city: "Denizli",
    county: "Çameli",
    name: "Çameli",
    type: "Malmüdürlüğü"
  },
  {
    id: "20105",
    city: "Denizli",
    county: "Çardak",
    name: "Çardak",
    type: "Malmüdürlüğü"
  },
  {
    id: "20107",
    city: "Denizli",
    county: "Güney",
    name: "Güney",
    type: "Malmüdürlüğü"
  },
  {
    id: "20108",
    city: "Denizli",
    county: "Kale",
    name: "Kale",
    type: "Malmüdürlüğü"
  },
  {
    id: "20111",
    city: "Denizli",
    county: "Babadağ",
    name: "Babadağ",
    type: "Malmüdürlüğü"
  },
  {
    id: "20112",
    city: "Denizli",
    county: "Bekilli",
    name: "Bekilli",
    type: "Malmüdürlüğü"
  },
  {
    id: "20113",
    city: "Denizli",
    county: "Honaz",
    name: "Honaz",
    type: "Malmüdürlüğü"
  },
  {
    id: "20114",
    city: "Denizli",
    county: "Serinhisar",
    name: "Serinhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "20115",
    city: "Denizli",
    county: "Pamukkale",
    name: "Pamukkale (Akköy)",
    type: "Malmüdürlüğü"
  },
  {
    id: "20116",
    city: "Denizli",
    county: "Baklan",
    name: "Baklan",
    type: "Malmüdürlüğü"
  },
  {
    id: "20117",
    city: "Denizli",
    county: "Beyağaç",
    name: "Beyağaç",
    type: "Malmüdürlüğü"
  },
  {
    id: "20118",
    city: "Denizli",
    county: "Bozkurt",
    name: "Bozkurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "21251",
    city: "Diyarbakır",
    county: "Merkez",
    name: "Gökalp",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "21281",
    city: "Diyarbakır",
    county: "Merkez",
    name: "Süleyman Nazif",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "21282",
    city: "Diyarbakır",
    county: "Merkez",
    name: "Cahit Sıtkı Tarancı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "21283",
    city: "Diyarbakır",
    county: "Ergani",
    name: "Ergani",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "21284",
    city: "Diyarbakır",
    county: "Bismil",
    name: "Bismil",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "21102",
    city: "Diyarbakır",
    county: "Çermik",
    name: "Çermik",
    type: "Malmüdürlüğü"
  },
  {
    id: "21103",
    city: "Diyarbakır",
    county: "Çınar",
    name: "Çınar",
    type: "Malmüdürlüğü"
  },
  {
    id: "21104",
    city: "Diyarbakır",
    county: "Çüngüş",
    name: "Çüngüş",
    type: "Malmüdürlüğü"
  },
  {
    id: "21105",
    city: "Diyarbakır",
    county: "Dicle",
    name: "Dicle",
    type: "Malmüdürlüğü"
  },
  {
    id: "21107",
    city: "Diyarbakır",
    county: "Hani",
    name: "Hani",
    type: "Malmüdürlüğü"
  },
  {
    id: "21108",
    city: "Diyarbakır",
    county: "Hazro",
    name: "Hazro",
    type: "Malmüdürlüğü"
  },
  {
    id: "21109",
    city: "Diyarbakır",
    county: "Kulp",
    name: "Kulp",
    type: "Malmüdürlüğü"
  },
  {
    id: "21110",
    city: "Diyarbakır",
    county: "Lice",
    name: "Lice",
    type: "Malmüdürlüğü"
  },
  {
    id: "21111",
    city: "Diyarbakır",
    county: "Silvan",
    name: "Silvan",
    type: "Malmüdürlüğü"
  },
  {
    id: "21112",
    city: "Diyarbakır",
    county: "Eğil",
    name: "Eğil",
    type: "Malmüdürlüğü"
  },
  {
    id: "21113",
    city: "Diyarbakır",
    county: "Kocaköy",
    name: "Kocaköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "22201",
    city: "Edirne",
    county: "Merkez",
    name: "Arda",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "22260",
    city: "Edirne",
    county: "Merkez",
    name: "Kırkpınar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "22261",
    city: "Edirne",
    county: "Keşan",
    name: "Keşan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "22262",
    city: "Edirne",
    county: "Uzunköprü",
    name: "Uzunköprü",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "22263",
    city: "Edirne",
    county: "Havsa",
    name: "Havsa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "22264",
    city: "Edirne",
    county: "İpsala",
    name: "İpsala",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "22101",
    city: "Edirne",
    county: "Enez",
    name: "Enez",
    type: "Malmüdürlüğü"
  },
  {
    id: "22105",
    city: "Edirne",
    county: "Lalapaşa",
    name: "Lalapaşa",
    type: "Malmüdürlüğü"
  },
  {
    id: "22106",
    city: "Edirne",
    county: "Meriç",
    name: "Meriç",
    type: "Malmüdürlüğü"
  },
  {
    id: "22108",
    city: "Edirne",
    county: "Süloğlu",
    name: "Süloğlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "23201",
    city: "Elazığ",
    county: "Merkez",
    name: "Harput",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "23280",
    city: "Elazığ",
    county: "Merkez",
    name: "Hazar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "23101",
    city: "Elazığ",
    county: "Ağın",
    name: "Ağın",
    type: "Malmüdürlüğü"
  },
  {
    id: "23102",
    city: "Elazığ",
    county: "Baskil",
    name: "Baskil",
    type: "Malmüdürlüğü"
  },
  {
    id: "23103",
    city: "Elazığ",
    county: "Karakoçan",
    name: "Karakoçan",
    type: "Malmüdürlüğü"
  },
  {
    id: "23104",
    city: "Elazığ",
    county: "Keban",
    name: "Keban",
    type: "Malmüdürlüğü"
  },
  {
    id: "23105",
    city: "Elazığ",
    county: "Maden",
    name: "Maden",
    type: "Malmüdürlüğü"
  },
  {
    id: "23106",
    city: "Elazığ",
    county: "Palu",
    name: "Palu",
    type: "Malmüdürlüğü"
  },
  {
    id: "23107",
    city: "Elazığ",
    county: "Sivrice",
    name: "Sivrice",
    type: "Malmüdürlüğü"
  },
  {
    id: "23108",
    city: "Elazığ",
    county: "Arıcak",
    name: "Arıcak",
    type: "Malmüdürlüğü"
  },
  {
    id: "23109",
    city: "Elazığ",
    county: "Kovancılar",
    name: "Kovancılar",
    type: "Malmüdürlüğü"
  },
  {
    id: "23110",
    city: "Elazığ",
    county: "Alacakaya",
    name: "Alacakaya",
    type: "Malmüdürlüğü"
  },
  {
    id: "24260",
    city: "Erzincan",
    county: "Merkez",
    name: "Fevzipaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "24101",
    city: "Erzincan",
    county: "Çayırlı",
    name: "Çayırlı",
    type: "Malmüdürlüğü"
  },
  {
    id: "24102",
    city: "Erzincan",
    county: "İliç",
    name: "İliç",
    type: "Malmüdürlüğü"
  },
  {
    id: "24103",
    city: "Erzincan",
    county: "Kemah",
    name: "Kemah",
    type: "Malmüdürlüğü"
  },
  {
    id: "24104",
    city: "Erzincan",
    county: "Kemaliye",
    name: "Kemaliye",
    type: "Malmüdürlüğü"
  },
  {
    id: "24105",
    city: "Erzincan",
    county: "Refahiye",
    name: "Refahiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "24106",
    city: "Erzincan",
    county: "Tercan",
    name: "Tercan",
    type: "Malmüdürlüğü"
  },
  {
    id: "24107",
    city: "Erzincan",
    county: "Üzümlü",
    name: "Üzümlü",
    type: "Malmüdürlüğü"
  },
  {
    id: "24108",
    city: "Erzincan",
    county: "Otlukbeli",
    name: "Otlukbeli",
    type: "Malmüdürlüğü"
  },
  {
    id: "25251",
    city: "Erzurum",
    county: "Merkez",
    name: "Aziziye",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "25280",
    city: "Erzurum",
    county: "Merkez",
    name: "Kazımkarabekir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "25101",
    city: "Erzurum",
    county: "Aşkale",
    name: "Aşkale",
    type: "Malmüdürlüğü"
  },
  {
    id: "25102",
    city: "Erzurum",
    county: "Çat",
    name: "Çat",
    type: "Malmüdürlüğü"
  },
  {
    id: "25103",
    city: "Erzurum",
    county: "Hınıs",
    name: "Hınıs",
    type: "Malmüdürlüğü"
  },
  {
    id: "25104",
    city: "Erzurum",
    county: "Horasan",
    name: "Horasan",
    type: "Malmüdürlüğü"
  },
  {
    id: "25105",
    city: "Erzurum",
    county: "İspir",
    name: "İspir",
    type: "Malmüdürlüğü"
  },
  {
    id: "25106",
    city: "Erzurum",
    county: "Karayazı",
    name: "Karayazı",
    type: "Malmüdürlüğü"
  },
  {
    id: "25107",
    city: "Erzurum",
    county: "Narman",
    name: "Narman",
    type: "Malmüdürlüğü"
  },
  {
    id: "25108",
    city: "Erzurum",
    county: "Oltu",
    name: "Oltu",
    type: "Malmüdürlüğü"
  },
  {
    id: "25109",
    city: "Erzurum",
    county: "Olur",
    name: "Olur",
    type: "Malmüdürlüğü"
  },
  {
    id: "25110",
    city: "Erzurum",
    county: "Pasinler",
    name: "Pasinler",
    type: "Malmüdürlüğü"
  },
  {
    id: "25111",
    city: "Erzurum",
    county: "Şenkaya",
    name: "Şenkaya",
    type: "Malmüdürlüğü"
  },
  {
    id: "25112",
    city: "Erzurum",
    county: "Tekman",
    name: "Tekman",
    type: "Malmüdürlüğü"
  },
  {
    id: "25113",
    city: "Erzurum",
    county: "Tortum",
    name: "Tortum",
    type: "Malmüdürlüğü"
  },
  {
    id: "25114",
    city: "Erzurum",
    county: "Karaçoban",
    name: "Karaçoban",
    type: "Malmüdürlüğü"
  },
  {
    id: "25115",
    city: "Erzurum",
    county: "Uzundere",
    name: "Uzundere",
    type: "Malmüdürlüğü"
  },
  {
    id: "25116",
    city: "Erzurum",
    county: "Pazaryolu",
    name: "Pazaryolu",
    type: "Malmüdürlüğü"
  },
  {
    id: "25117",
    city: "Erzurum",
    county: "Merkez- (5216)",
    name: "Aziziye (Ilıca)",
    type: "Malmüdürlüğü"
  },
  {
    id: "25118",
    city: "Erzurum",
    county: "Köprüköy",
    name: "Köprüköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "26250",
    city: "Eskişehir",
    county: "Merkez Başkanlık",
    name: "Eskişehir",
    type: "Vergi Dairesi Başkanlığı"
  },
  {
    id: "26102",
    city: "Eskişehir",
    county: "Mahmudiye",
    name: "Mahmudiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "26103",
    city: "Eskişehir",
    county: "Mihalıççık",
    name: "Mihalıççık",
    type: "Malmüdürlüğü"
  },
  {
    id: "26104",
    city: "Eskişehir",
    county: "Sarıcakaya",
    name: "Sarıcakaya",
    type: "Malmüdürlüğü"
  },
  {
    id: "26105",
    city: "Eskişehir",
    county: "Seyitgazi",
    name: "Seyitgazi",
    type: "Malmüdürlüğü"
  },
  {
    id: "26107",
    city: "Eskişehir",
    county: "Alpu",
    name: "Alpu",
    type: "Malmüdürlüğü"
  },
  {
    id: "26108",
    city: "Eskişehir",
    county: "Beylikova",
    name: "Beylikova",
    type: "Malmüdürlüğü"
  },
  {
    id: "26109",
    city: "Eskişehir",
    county: "İnönü",
    name: "İnönü",
    type: "Malmüdürlüğü"
  },
  {
    id: "26110",
    city: "Eskişehir",
    county: "Günyüzü",
    name: "Günyüzü",
    type: "Malmüdürlüğü"
  },
  {
    id: "26111",
    city: "Eskişehir",
    county: "Han",
    name: "Han",
    type: "Malmüdürlüğü"
  },
  {
    id: "26112",
    city: "Eskişehir",
    county: "Mihalgazi",
    name: "Mihalgazi",
    type: "Malmüdürlüğü"
  },
  {
    id: "27250",
    city: "Gaziantep",
    county: "Merkez",
    name: "Gaziantep İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27251",
    city: "Gaziantep",
    county: "Merkez",
    name: "Suburcu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27252",
    city: "Gaziantep",
    county: "Merkez",
    name: "Şehitkâmil",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27253",
    city: "Gaziantep",
    county: "Merkez",
    name: "Şahinbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27254",
    city: "Gaziantep",
    county: "Merkez",
    name: "Gazikent",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27255",
    city: "Gaziantep",
    county: "Merkez",
    name: "Kozanlı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27202",
    city: "Gaziantep",
    county: "Nizip",
    name: "Nizip",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27203",
    city: "Gaziantep",
    county: "İslahiye",
    name: "İslahiye",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "27101",
    city: "Gaziantep",
    county: "Araban",
    name: "Araban",
    type: "Malmüdürlüğü"
  },
  {
    id: "27105",
    city: "Gaziantep",
    county: "Merkez- (5216)",
    name: "Oğuzeli",
    type: "Malmüdürlüğü"
  },
  {
    id: "27106",
    city: "Gaziantep",
    county: "Yavuzeli",
    name: "Yavuzeli",
    type: "Malmüdürlüğü"
  },
  {
    id: "27107",
    city: "Gaziantep",
    county: "Karkamış",
    name: "Karkamış",
    type: "Malmüdürlüğü"
  },
  {
    id: "27108",
    city: "Gaziantep",
    county: "Nurdağı",
    name: "Nurdağı",
    type: "Malmüdürlüğü"
  },
  {
    id: "28260",
    city: "Giresun",
    county: "Merkez",
    name: "Giresun",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "28261",
    city: "Giresun",
    county: "Bulancak",
    name: "Bulancak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "28101",
    city: "Giresun",
    county: "Alucra",
    name: "Alucra",
    type: "Malmüdürlüğü"
  },
  {
    id: "28103",
    city: "Giresun",
    county: "Dereli",
    name: "Dereli",
    type: "Malmüdürlüğü"
  },
  {
    id: "28104",
    city: "Giresun",
    county: "Espiye",
    name: "Espiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "28105",
    city: "Giresun",
    county: "Eynesil",
    name: "Eynesil",
    type: "Malmüdürlüğü"
  },
  {
    id: "28106",
    city: "Giresun",
    county: "Görele",
    name: "Görele",
    type: "Malmüdürlüğü"
  },
  {
    id: "28107",
    city: "Giresun",
    county: "Keşap",
    name: "Keşap",
    type: "Malmüdürlüğü"
  },
  {
    id: "28108",
    city: "Giresun",
    county: "Şebinkarahisar",
    name: "Şebinkarahisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "28109",
    city: "Giresun",
    county: "Tirebolu",
    name: "Tirebolu",
    type: "Malmüdürlüğü"
  },
  {
    id: "28110",
    city: "Giresun",
    county: "Piraziz",
    name: "Piraziz",
    type: "Malmüdürlüğü"
  },
  {
    id: "28111",
    city: "Giresun",
    county: "Yağlıdere",
    name: "Yağlıdere",
    type: "Malmüdürlüğü"
  },
  {
    id: "28112",
    city: "Giresun",
    county: "Çanakçı",
    name: "Çanakçı",
    type: "Malmüdürlüğü"
  },
  {
    id: "28113",
    city: "Giresun",
    county: "Güce",
    name: "Güce",
    type: "Malmüdürlüğü"
  },
  {
    id: "28114",
    city: "Giresun",
    county: "Çamoluk",
    name: "Çamoluk",
    type: "Malmüdürlüğü"
  },
  {
    id: "28115",
    city: "Giresun",
    county: "Doğankent",
    name: "Doğankent",
    type: "Malmüdürlüğü"
  },
  {
    id: "29260",
    city: "Gümüşhane",
    county: "Merkez",
    name: "Gümüşhane",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "29102",
    city: "Gümüşhane",
    county: "Kelkit",
    name: "Kelkit",
    type: "Malmüdürlüğü"
  },
  {
    id: "29103",
    city: "Gümüşhane",
    county: "Şiran",
    name: "Şiran",
    type: "Malmüdürlüğü"
  },
  {
    id: "29104",
    city: "Gümüşhane",
    county: "Torul",
    name: "Torul",
    type: "Malmüdürlüğü"
  },
  {
    id: "29107",
    city: "Gümüşhane",
    county: "Köse",
    name: "Köse",
    type: "Malmüdürlüğü"
  },
  {
    id: "29108",
    city: "Gümüşhane",
    county: "Kürtün",
    name: "Kürtün",
    type: "Malmüdürlüğü"
  },
  {
    id: "30260",
    city: "Hakkari",
    county: "Merkez",
    name: "Hakkari",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "30261",
    city: "Hakkari",
    county: "Yüksekova",
    name: "Yüksekova",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "30102",
    city: "Hakkari",
    county: "Çukurca",
    name: "Çukurca",
    type: "Malmüdürlüğü"
  },
  {
    id: "30103",
    city: "Hakkari",
    county: "Şemdinli",
    name: "Şemdinli",
    type: "Malmüdürlüğü"
  },
  {
    id: "30104",
    city: "Hakkari",
    county: "Derecik",
    name: "Derecik",
    type: "Malmüdürlüğü"
  },
  {
    id: "31201",
    city: "Hatay",
    county: "Merkez",
    name: "23 Temmuz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31203",
    city: "Hatay",
    county: "Merkez",
    name: "Antakya",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31280",
    city: "Hatay",
    county: "Merkez",
    name: "Şükrükanatlı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31202",
    city: "Hatay",
    county: "İskenderun",
    name: "Sahil",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31281",
    city: "Hatay",
    county: "İskenderun",
    name: "Akdeniz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31290",
    city: "Hatay",
    county: "İskenderun",
    name: "Asım Gündüz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31260",
    city: "Hatay",
    county: "Dörtyol",
    name: "Dörtyol",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31261",
    city: "Hatay",
    county: "Kırıkhan",
    name: "Kırıkhan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31262",
    city: "Hatay",
    county: "Reyhanlı",
    name: "Reyhanlı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31263",
    city: "Hatay",
    county: "Samandağ",
    name: "Samandağ",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "31101",
    city: "Hatay",
    county: "Altınözü",
    name: "Altınözü",
    type: "Malmüdürlüğü"
  },
  {
    id: "31103",
    city: "Hatay",
    county: "Hassa",
    name: "Hassa",
    type: "Malmüdürlüğü"
  },
  {
    id: "31108",
    city: "Hatay",
    county: "Yayladağı",
    name: "Yayladağı",
    type: "Malmüdürlüğü"
  },
  {
    id: "31109",
    city: "Hatay",
    county: "Erzin",
    name: "Erzin",
    type: "Malmüdürlüğü"
  },
  {
    id: "31110",
    city: "Hatay",
    county: "Belen",
    name: "Belen",
    type: "Malmüdürlüğü"
  },
  {
    id: "31111",
    city: "Hatay",
    county: "Kumlu",
    name: "Kumlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "32201",
    city: "Isparta",
    county: "Merkez",
    name: "Davraz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "32260",
    city: "Isparta",
    county: "Merkez",
    name: "Kaymakkapı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "32261",
    city: "Isparta",
    county: "Eğirdir",
    name: "Eğirdir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "32262",
    city: "Isparta",
    county: "Yalvaç",
    name: "Yalvaç",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "32101",
    city: "Isparta",
    county: "Atabey",
    name: "Atabey",
    type: "Malmüdürlüğü"
  },
  {
    id: "32103",
    city: "Isparta",
    county: "Gelendost",
    name: "Gelendost",
    type: "Malmüdürlüğü"
  },
  {
    id: "32104",
    city: "Isparta",
    county: "Keçiborlu",
    name: "Keçiborlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "32105",
    city: "Isparta",
    county: "Senirkent",
    name: "Senirkent",
    type: "Malmüdürlüğü"
  },
  {
    id: "32106",
    city: "Isparta",
    county: "Sütçüler",
    name: "Sütçüler",
    type: "Malmüdürlüğü"
  },
  {
    id: "32107",
    city: "Isparta",
    county: "Şarkikaraağaç",
    name: "Şarkikaraağaç",
    type: "Malmüdürlüğü"
  },
  {
    id: "32108",
    city: "Isparta",
    county: "Uluborlu",
    name: "Uluborlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "32110",
    city: "Isparta",
    county: "Aksu",
    name: "Aksu",
    type: "Malmüdürlüğü"
  },
  {
    id: "32111",
    city: "Isparta",
    county: "Gönen",
    name: "Gönen",
    type: "Malmüdürlüğü"
  },
  {
    id: "32112",
    city: "Isparta",
    county: "Yenişarbademli",
    name: "Yenişarbademli",
    type: "Malmüdürlüğü"
  },
  {
    id: "33250",
    city: "Mersin",
    county: "Merkez",
    name: "İstiklâl",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33252",
    city: "Mersin",
    county: "Merkez",
    name: "Uray",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33254",
    city: "Mersin",
    county: "Merkez",
    name: "Liman",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33255",
    city: "Mersin",
    county: "Merkez",
    name: "Toros",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33256",
    city: "Mersin",
    county: "Merkez",
    name: "Mersin İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33201",
    city: "Mersin",
    county: "Erdemli",
    name: "Erdemli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33202",
    city: "Mersin",
    county: "Silifke",
    name: "Silifke",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33203",
    city: "Mersin",
    county: "Anamur",
    name: "Anamur",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33204",
    city: "Mersin",
    county: "Tarsus",
    name: "Kızılmurat",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33205",
    city: "Mersin",
    county: "Tarsus",
    name: "Şehitkerim",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "33103",
    city: "Mersin",
    county: "Gülnar",
    name: "Gülnar",
    type: "Malmüdürlüğü"
  },
  {
    id: "33104",
    city: "Mersin",
    county: "Mut",
    name: "Mut",
    type: "Malmüdürlüğü"
  },
  {
    id: "33107",
    city: "Mersin",
    county: "Aydıncık",
    name: "Aydıncık",
    type: "Malmüdürlüğü"
  },
  {
    id: "33108",
    city: "Mersin",
    county: "Bozyazı",
    name: "Bozyazı",
    type: "Malmüdürlüğü"
  },
  {
    id: "33109",
    city: "Mersin",
    county: "Çamlıyayla",
    name: "Çamlıyayla",
    type: "Malmüdürlüğü"
  },
  {
    id: "34220",
    city: "İstanbul",
    county: "Merkez",
    name: "Haliç İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34221",
    city: "İstanbul",
    county: "Merkez",
    name: "Vatan İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34222",
    city: "İstanbul",
    county: "Merkez",
    name: "Çamlıca İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34223",
    city: "İstanbul",
    county: "Merkez",
    name: "Alemdağ",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34224",
    city: "İstanbul",
    county: "Merkez",
    name: "Yenikapı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34230",
    city: "İstanbul",
    county: "Merkez Başkanlık",
    name: "Büyük Mükellefler",
    type: "Vergi Dairesi Başkanlığı"
  },
  {
    id: "34231",
    city: "İstanbul",
    county: "Merkez",
    name: "Boğaziçi Kurumlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34232",
    city: "İstanbul",
    county: "Merkez",
    name: "Marmara Kurumlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34234",
    city: "İstanbul",
    county: "Merkez",
    name: "Davutpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34235",
    city: "İstanbul",
    county: "Merkez",
    name: "Esenler",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34236",
    city: "İstanbul",
    county: "Merkez",
    name: "Fatih",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34237",
    city: "İstanbul",
    county: "Merkez",
    name: "Küçükköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34239",
    city: "İstanbul",
    county: "Merkez",
    name: "Merter",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34242",
    city: "İstanbul",
    county: "Merkez",
    name: "Sultanbeyli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34244",
    city: "İstanbul",
    county: "Merkez",
    name: "Anadolu Kurumlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34245",
    city: "İstanbul",
    county: "Merkez",
    name: "Tuzla",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34246",
    city: "İstanbul",
    county: "Merkez",
    name: "Kozyatağı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34247",
    city: "İstanbul",
    county: "Merkez",
    name: "Maslak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34248",
    city: "İstanbul",
    county: "Merkez",
    name: "Zincirlikuyu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34249",
    city: "İstanbul",
    county: "Merkez",
    name: "İkitelli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34251",
    city: "İstanbul",
    county: "Merkez",
    name: "Beşiktaş",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34252",
    city: "İstanbul",
    county: "Merkez",
    name: "Ümraniye",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34253",
    city: "İstanbul",
    county: "Merkez",
    name: "Yeditepe Veraset ve Harçlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34254",
    city: "İstanbul",
    county: "Merkez",
    name: "Kasımpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34255",
    city: "İstanbul",
    county: "Merkez",
    name: "Erenköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34256",
    city: "İstanbul",
    county: "Merkez",
    name: "Hisar Veraset ve Harçlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34257",
    city: "İstanbul",
    county: "Merkez",
    name: "Tuna",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34258",
    city: "İstanbul",
    county: "Merkez",
    name: "Rıhtım Veraset ve Harçlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34259",
    city: "İstanbul",
    county: "Merkez",
    name: "Güngören",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34260",
    city: "İstanbul",
    county: "Merkez",
    name: "Kocasinan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34261",
    city: "İstanbul",
    county: "Merkez",
    name: "Güneşli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34262",
    city: "İstanbul",
    county: "Merkez",
    name: "Küçükyalı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34263",
    city: "İstanbul",
    county: "Merkez",
    name: "Pendik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34264",
    city: "İstanbul",
    county: "Merkez",
    name: "Bayrampaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34265",
    city: "İstanbul",
    county: "Merkez",
    name: "Beyazıt",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34266",
    city: "İstanbul",
    county: "Merkez",
    name: "Beyoğlu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34269",
    city: "İstanbul",
    county: "Merkez",
    name: "Gaziosmanpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34270",
    city: "İstanbul",
    county: "Merkez",
    name: "Göztepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34271",
    city: "İstanbul",
    county: "Merkez",
    name: "Hocapaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34272",
    city: "İstanbul",
    county: "Merkez",
    name: "Kadıköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34273",
    city: "İstanbul",
    county: "Merkez",
    name: "Kocamustafapaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34274",
    city: "İstanbul",
    county: "Merkez",
    name: "Mecidiyeköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34276",
    city: "İstanbul",
    county: "Merkez",
    name: "Şişli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34277",
    city: "İstanbul",
    county: "Merkez",
    name: "Üsküdar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34278",
    city: "İstanbul",
    county: "Merkez",
    name: "Halkalı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34279",
    city: "İstanbul",
    county: "Merkez",
    name: "Kağıthane",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34280",
    city: "İstanbul",
    county: "Merkez",
    name: "Zeytinburnu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34281",
    city: "İstanbul",
    county: "Merkez",
    name: "Beykoz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34283",
    city: "İstanbul",
    county: "Merkez",
    name: "Sarıyer",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34284",
    city: "İstanbul",
    county: "Merkez",
    name: "Bakırköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34285",
    city: "İstanbul",
    county: "Merkez",
    name: "Kartal",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34287",
    city: "İstanbul",
    county: "Merkez",
    name: "Nakil Vasıtaları",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34288",
    city: "İstanbul",
    county: "Merkez",
    name: "Sarıgazi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34291",
    city: "İstanbul",
    county: "Merkez",
    name: "Atışalanı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34292",
    city: "İstanbul",
    county: "Merkez",
    name: "Yakacık",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34293",
    city: "İstanbul",
    county: "Merkez",
    name: "Yenibosna",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34294",
    city: "İstanbul",
    county: "Merkez",
    name: "Avcılar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34295",
    city: "İstanbul",
    county: "Merkez- (5216)",
    name: "Adalar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34296",
    city: "İstanbul",
    county: "Merkez",
    name: "Küçükçekmece",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34297",
    city: "İstanbul",
    county: "Merkez",
    name: "Beylikdüzü",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34298",
    city: "İstanbul",
    county: "Merkez",
    name: "Esenyurt",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34299",
    city: "İstanbul",
    county: "Küçükçekmece",
    name: "Başakşehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34203",
    city: "İstanbul",
    county: "Merkez- (5216)",
    name: "Silivri",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34204",
    city: "İstanbul",
    county: "Merkez- (5216)",
    name: "Büyükçekmece",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "34205",
    city: "İstanbul",
    county: "Merkez- (5216)",
    name: "Şile",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35250",
    city: "İzmir",
    county: "Merkez",
    name: "İzmir İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35251",
    city: "İzmir",
    county: "Merkez",
    name: "9 Eylül",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35252",
    city: "İzmir",
    county: "Merkez",
    name: "Yamanlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35254",
    city: "İzmir",
    county: "Merkez",
    name: "Belkahve",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35256",
    city: "İzmir",
    county: "Merkez",
    name: "Karşıyaka",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35257",
    city: "İzmir",
    county: "Merkez",
    name: "Kemeraltı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35258",
    city: "İzmir",
    county: "Merkez",
    name: "Konak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35259",
    city: "İzmir",
    county: "Merkez",
    name: "Kordon",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35260",
    city: "İzmir",
    county: "Merkez",
    name: "Şirinyer",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35261",
    city: "İzmir",
    county: "Merkez",
    name: "Kadifekale",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35262",
    city: "İzmir",
    county: "Merkez",
    name: "Taşıtlar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35263",
    city: "İzmir",
    county: "Merkez",
    name: "Hasan Tahsin",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35264",
    city: "İzmir",
    county: "Merkez",
    name: "Bornova",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35266",
    city: "İzmir",
    county: "Merkez",
    name: "Balçova",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35267",
    city: "İzmir",
    county: "Merkez",
    name: "Gaziemir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35268",
    city: "İzmir",
    county: "Merkez",
    name: "Ege",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35269",
    city: "İzmir",
    county: "Merkez",
    name: "Çiğli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35270",
    city: "İzmir",
    county: "Merkez",
    name: "Çakabey İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35201",
    city: "İzmir",
    county: "Bayındır",
    name: "Bayındır",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35202",
    city: "İzmir",
    county: "Bergama",
    name: "Bergama",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35203",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Menemen",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35204",
    city: "İzmir",
    county: "Ödemiş",
    name: "Ödemiş",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35205",
    city: "İzmir",
    county: "Tire",
    name: "Tire",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35206",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Torbalı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35207",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Kemalpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35208",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Urla",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35209",
    city: "İzmir",
    county: "Selçuk",
    name: "Selçuk",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35210",
    city: "İzmir",
    county: "Kınık",
    name: "Kınık",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35211",
    city: "İzmir",
    county: "Kiraz",
    name: "Kiraz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35212",
    city: "İzmir",
    county: "Çeşme",
    name: "Çeşme",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35213",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Aliağa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35215",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Menderes",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "35105",
    city: "İzmir",
    county: "Dikili",
    name: "Dikili",
    type: "Malmüdürlüğü"
  },
  {
    id: "35106",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Foça",
    type: "Malmüdürlüğü"
  },
  {
    id: "35107",
    city: "İzmir",
    county: "Karaburun",
    name: "Karaburun",
    type: "Malmüdürlüğü"
  },
  {
    id: "35114",
    city: "İzmir",
    county: "Merkez- (5216)",
    name: "Seferihisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "35120",
    city: "İzmir",
    county: "Beydağ",
    name: "Beydağ",
    type: "Malmüdürlüğü"
  },
  {
    id: "36260",
    city: "Kars",
    county: "Merkez",
    name: "Kars",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "36103",
    city: "Kars",
    county: "Arpaçay",
    name: "Arpaçay",
    type: "Malmüdürlüğü"
  },
  {
    id: "36105",
    city: "Kars",
    county: "Digor",
    name: "Digor",
    type: "Malmüdürlüğü"
  },
  {
    id: "36109",
    city: "Kars",
    county: "Kağızman",
    name: "Kağızman",
    type: "Malmüdürlüğü"
  },
  {
    id: "36111",
    city: "Kars",
    county: "Sarıkamış",
    name: "Sarıkamış",
    type: "Malmüdürlüğü"
  },
  {
    id: "36112",
    city: "Kars",
    county: "Selim",
    name: "Selim",
    type: "Malmüdürlüğü"
  },
  {
    id: "36113",
    city: "Kars",
    county: "Susuz",
    name: "Susuz",
    type: "Malmüdürlüğü"
  },
  {
    id: "36115",
    city: "Kars",
    county: "Akyaka",
    name: "Akyaka",
    type: "Malmüdürlüğü"
  },
  {
    id: "37260",
    city: "Kastamonu",
    county: "Merkez",
    name: "Kastamonu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "37261",
    city: "Kastamonu",
    county: "Tosya",
    name: "Tosya",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "37262",
    city: "Kastamonu",
    county: "Taşköprü",
    name: "Taşköprü",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "37101",
    city: "Kastamonu",
    county: "Araç",
    name: "Araç",
    type: "Malmüdürlüğü"
  },
  {
    id: "37102",
    city: "Kastamonu",
    county: "Azdavay",
    name: "Azdavay",
    type: "Malmüdürlüğü"
  },
  {
    id: "37103",
    city: "Kastamonu",
    county: "Bozkurt",
    name: "Bozkurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "37104",
    city: "Kastamonu",
    county: "Cide",
    name: "Cide",
    type: "Malmüdürlüğü"
  },
  {
    id: "37105",
    city: "Kastamonu",
    county: "Çatalzeytin",
    name: "Çatalzeytin",
    type: "Malmüdürlüğü"
  },
  {
    id: "37106",
    city: "Kastamonu",
    county: "Daday",
    name: "Daday",
    type: "Malmüdürlüğü"
  },
  {
    id: "37107",
    city: "Kastamonu",
    county: "Devrekani",
    name: "Devrekani",
    type: "Malmüdürlüğü"
  },
  {
    id: "37108",
    city: "Kastamonu",
    county: "İnebolu",
    name: "İnebolu",
    type: "Malmüdürlüğü"
  },
  {
    id: "37109",
    city: "Kastamonu",
    county: "Küre",
    name: "Küre",
    type: "Malmüdürlüğü"
  },
  {
    id: "37112",
    city: "Kastamonu",
    county: "Abana",
    name: "Abana",
    type: "Malmüdürlüğü"
  },
  {
    id: "37113",
    city: "Kastamonu",
    county: "İhsangazi",
    name: "İhsangazi",
    type: "Malmüdürlüğü"
  },
  {
    id: "37114",
    city: "Kastamonu",
    county: "Pınarbaşı",
    name: "Pınarbaşı",
    type: "Malmüdürlüğü"
  },
  {
    id: "37115",
    city: "Kastamonu",
    county: "Şenpazar",
    name: "Şenpazar",
    type: "Malmüdürlüğü"
  },
  {
    id: "37116",
    city: "Kastamonu",
    county: "Ağlı",
    name: "Ağlı",
    type: "Malmüdürlüğü"
  },
  {
    id: "37117",
    city: "Kastamonu",
    county: "Doğanyurt",
    name: "Doğanyurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "37118",
    city: "Kastamonu",
    county: "Hanönü",
    name: "Hanönü",
    type: "Malmüdürlüğü"
  },
  {
    id: "37119",
    city: "Kastamonu",
    county: "Seydiler",
    name: "Seydiler",
    type: "Malmüdürlüğü"
  },
  {
    id: "38250",
    city: "Kayseri",
    county: "Merkez",
    name: "Kayseri İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38251",
    city: "Kayseri",
    county: "Merkez",
    name: "Mimar Sinan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38252",
    city: "Kayseri",
    county: "Merkez",
    name: "Erciyes",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38253",
    city: "Kayseri",
    county: "Merkez",
    name: "Kaleönü",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38254",
    city: "Kayseri",
    county: "Merkez",
    name: "Gevher Nesibe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38201",
    city: "Kayseri",
    county: "Develi",
    name: "Develi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38202",
    city: "Kayseri",
    county: "Pınarbaşı",
    name: "Pınarbaşı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38203",
    city: "Kayseri",
    county: "Bünyan",
    name: "Bünyan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "38103",
    city: "Kayseri",
    county: "Felahiye",
    name: "Felahiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "38104",
    city: "Kayseri",
    county: "İncesu",
    name: "İncesu",
    type: "Malmüdürlüğü"
  },
  {
    id: "38106",
    city: "Kayseri",
    county: "Sarıoğlan",
    name: "Sarıoğlan",
    type: "Malmüdürlüğü"
  },
  {
    id: "38107",
    city: "Kayseri",
    county: "Sarız",
    name: "Sarız",
    type: "Malmüdürlüğü"
  },
  {
    id: "38108",
    city: "Kayseri",
    county: "Tomarza",
    name: "Tomarza",
    type: "Malmüdürlüğü"
  },
  {
    id: "38109",
    city: "Kayseri",
    county: "Yahyalı",
    name: "Yahyalı",
    type: "Malmüdürlüğü"
  },
  {
    id: "38110",
    city: "Kayseri",
    county: "Yeşilhisar",
    name: "Yeşilhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "38111",
    city: "Kayseri",
    county: "Akkışla",
    name: "Akkışla",
    type: "Malmüdürlüğü"
  },
  {
    id: "38113",
    city: "Kayseri",
    county: "Merkez- (5216)",
    name: "Hacılar",
    type: "Malmüdürlüğü"
  },
  {
    id: "38114",
    city: "Kayseri",
    county: "Özvatan",
    name: "Özvatan",
    type: "Malmüdürlüğü"
  },
  {
    id: "39260",
    city: "Kırklareli",
    county: "Merkez",
    name: "Kırklareli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "39261",
    city: "Kırklareli",
    county: "Lüleburgaz",
    name: "Lüleburgaz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "39262",
    city: "Kırklareli",
    county: "Babaeski",
    name: "Babaeski",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "39102",
    city: "Kırklareli",
    county: "Demirköy",
    name: "Demirköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "39103",
    city: "Kırklareli",
    county: "Kofçaz",
    name: "Kofçaz",
    type: "Malmüdürlüğü"
  },
  {
    id: "39105",
    city: "Kırklareli",
    county: "Pehlivanköy",
    name: "Pehlivanköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "39106",
    city: "Kırklareli",
    county: "Pınarhisar",
    name: "Pınarhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "39107",
    city: "Kırklareli",
    county: "Vize",
    name: "Vize",
    type: "Malmüdürlüğü"
  },
  {
    id: "40260",
    city: "Kırşehir",
    county: "Merkez",
    name: "Kırşehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "40261",
    city: "Kırşehir",
    county: "Kaman",
    name: "Kaman",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "40101",
    city: "Kırşehir",
    county: "Çiçekdağı",
    name: "Çiçekdağı",
    type: "Malmüdürlüğü"
  },
  {
    id: "40103",
    city: "Kırşehir",
    county: "Mucur",
    name: "Mucur",
    type: "Malmüdürlüğü"
  },
  {
    id: "40104",
    city: "Kırşehir",
    county: "Akpınar",
    name: "Akpınar",
    type: "Malmüdürlüğü"
  },
  {
    id: "40105",
    city: "Kırşehir",
    county: "Akçakent",
    name: "Akçakent",
    type: "Malmüdürlüğü"
  },
  {
    id: "40106",
    city: "Kırşehir",
    county: "Boztepe",
    name: "Boztepe",
    type: "Malmüdürlüğü"
  },
  {
    id: "41250",
    city: "Kocaeli",
    county: "Merkez",
    name: "Kocaeli İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41252",
    city: "Kocaeli",
    county: "Merkez",
    name: "Tepecik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41253",
    city: "Kocaeli",
    county: "Merkez",
    name: "Alemdar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41254",
    city: "Kocaeli",
    county: "Merkez Gebze",
    name: "Gebze İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41290",
    city: "Kocaeli",
    county: "Merkez",
    name: "Acısu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41202",
    city: "Kocaeli",
    county: "Merkez- (5216)",
    name: "Uluçınar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41203",
    city: "Kocaeli",
    county: "Merkez- (5216)",
    name: "İlyasbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41204",
    city: "Kocaeli",
    county: "Merkez- (5216)",
    name: "Gölcük",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41205",
    city: "Kocaeli",
    county: "Merkez- (5216)",
    name: "Karamürsel",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41206",
    city: "Kocaeli",
    county: "Merkez- (5216)",
    name: "Körfez",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41207",
    city: "Kocaeli",
    county: "Merkez- (5216)",
    name: "Derince",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "41103",
    city: "Kocaeli",
    county: "Merkez- (5216)",
    name: "Kandıra",
    type: "Malmüdürlüğü"
  },
  {
    id: "42250",
    city: "Konya",
    county: "Merkez",
    name: "Konya İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42251",
    city: "Konya",
    county: "Merkez",
    name: "Selçuk",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42252",
    city: "Konya",
    county: "Merkez",
    name: "Mevlana",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42253",
    city: "Konya",
    county: "Merkez",
    name: "Meram",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42254",
    city: "Konya",
    county: "Merkez",
    name: "Alaaddin",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42201",
    city: "Konya",
    county: "Akşehir",
    name: "Akşehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42202",
    city: "Konya",
    county: "Ereğli",
    name: "Ereğli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42204",
    city: "Konya",
    county: "Beyşehir",
    name: "Beyşehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42205",
    city: "Konya",
    county: "Cihanbeyli",
    name: "Cihanbeyli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42206",
    city: "Konya",
    county: "Çumra",
    name: "Çumra",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42207",
    city: "Konya",
    county: "Seydişehir",
    name: "Seydişehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42208",
    city: "Konya",
    county: "Ilgın",
    name: "Ilgın",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42209",
    city: "Konya",
    county: "Kulu",
    name: "Kulu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42210",
    city: "Konya",
    county: "Karapınar",
    name: "Karapınar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "42103",
    city: "Konya",
    county: "Bozkır",
    name: "Bozkır",
    type: "Malmüdürlüğü"
  },
  {
    id: "42106",
    city: "Konya",
    county: "Doğanhisar",
    name: "Doğanhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "42109",
    city: "Konya",
    county: "Hadim",
    name: "Hadim",
    type: "Malmüdürlüğü"
  },
  {
    id: "42111",
    city: "Konya",
    county: "Kadınhanı",
    name: "Kadınhanı",
    type: "Malmüdürlüğü"
  },
  {
    id: "42115",
    city: "Konya",
    county: "Sarayönü",
    name: "Sarayönü",
    type: "Malmüdürlüğü"
  },
  {
    id: "42117",
    city: "Konya",
    county: "Yunak",
    name: "Yunak",
    type: "Malmüdürlüğü"
  },
  {
    id: "42118",
    city: "Konya",
    county: "Akören",
    name: "Akören",
    type: "Malmüdürlüğü"
  },
  {
    id: "42119",
    city: "Konya",
    county: "Altınekin",
    name: "Altınekin",
    type: "Malmüdürlüğü"
  },
  {
    id: "42121",
    city: "Konya",
    county: "Derebucak",
    name: "Derebucak",
    type: "Malmüdürlüğü"
  },
  {
    id: "42122",
    city: "Konya",
    county: "Hüyük",
    name: "Hüyük",
    type: "Malmüdürlüğü"
  },
  {
    id: "42123",
    city: "Konya",
    county: "Taşkent",
    name: "Taşkent",
    type: "Malmüdürlüğü"
  },
  {
    id: "42124",
    city: "Konya",
    county: "Ahırlı",
    name: "Ahırlı",
    type: "Malmüdürlüğü"
  },
  {
    id: "42125",
    city: "Konya",
    county: "Çeltik",
    name: "Çeltik",
    type: "Malmüdürlüğü"
  },
  {
    id: "42126",
    city: "Konya",
    county: "Derbent",
    name: "Derbent",
    type: "Malmüdürlüğü"
  },
  {
    id: "42127",
    city: "Konya",
    county: "Emirgazi",
    name: "Emirgazi",
    type: "Malmüdürlüğü"
  },
  {
    id: "42128",
    city: "Konya",
    county: "Güneysınır",
    name: "Güneysınır",
    type: "Malmüdürlüğü"
  },
  {
    id: "42129",
    city: "Konya",
    county: "Halkapınar",
    name: "Halkapınar",
    type: "Malmüdürlüğü"
  },
  {
    id: "42130",
    city: "Konya",
    county: "Tuzlukçu",
    name: "Tuzlukçu",
    type: "Malmüdürlüğü"
  },
  {
    id: "42131",
    city: "Konya",
    county: "Yalıhüyük",
    name: "Yalıhüyük",
    type: "Malmüdürlüğü"
  },
  {
    id: "43201",
    city: "Kütahya",
    county: "Merkez",
    name: "30 Ağustos",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "43280",
    city: "Kütahya",
    county: "Merkez",
    name: "Çinili",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "43260",
    city: "Kütahya",
    county: "Gediz",
    name: "Gediz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "43261",
    city: "Kütahya",
    county: "Simav",
    name: "Simav",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "43262",
    city: "Kütahya",
    county: "Tavşanlı",
    name: "Tavşanlı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "43263",
    city: "Kütahya",
    county: "Emet",
    name: "Emet",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "43101",
    city: "Kütahya",
    county: "Altıntaş",
    name: "Altıntaş",
    type: "Malmüdürlüğü"
  },
  {
    id: "43102",
    city: "Kütahya",
    county: "Domaniç",
    name: "Domaniç",
    type: "Malmüdürlüğü"
  },
  {
    id: "43107",
    city: "Kütahya",
    county: "Aslanapa",
    name: "Aslanapa",
    type: "Malmüdürlüğü"
  },
  {
    id: "43108",
    city: "Kütahya",
    county: "Dumlupınar",
    name: "Dumlupınar",
    type: "Malmüdürlüğü"
  },
  {
    id: "43109",
    city: "Kütahya",
    county: "Hisarcık",
    name: "Hisarcık",
    type: "Malmüdürlüğü"
  },
  {
    id: "43110",
    city: "Kütahya",
    county: "Şaphane",
    name: "Şaphane",
    type: "Malmüdürlüğü"
  },
  {
    id: "43111",
    city: "Kütahya",
    county: "Çavdarhisar",
    name: "Çavdarhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "43112",
    city: "Kütahya",
    county: "Pazarlar",
    name: "Pazarlar",
    type: "Malmüdürlüğü"
  },
  {
    id: "44251",
    city: "Malatya",
    county: "Merkez",
    name: "Fırat",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "44252",
    city: "Malatya",
    county: "Merkez",
    name: "Beydağı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "44101",
    city: "Malatya",
    county: "Akçadağ",
    name: "Akçadağ",
    type: "Malmüdürlüğü"
  },
  {
    id: "44102",
    city: "Malatya",
    county: "Arapgir",
    name: "Arapgir",
    type: "Malmüdürlüğü"
  },
  {
    id: "44103",
    city: "Malatya",
    county: "Arguvan",
    name: "Arguvan",
    type: "Malmüdürlüğü"
  },
  {
    id: "44104",
    city: "Malatya",
    county: "Darende",
    name: "Darende",
    type: "Malmüdürlüğü"
  },
  {
    id: "44105",
    city: "Malatya",
    county: "Doğanşehir",
    name: "Doğanşehir",
    type: "Malmüdürlüğü"
  },
  {
    id: "44106",
    city: "Malatya",
    county: "Hekimhan",
    name: "Hekimhan",
    type: "Malmüdürlüğü"
  },
  {
    id: "44107",
    city: "Malatya",
    county: "Pütürge",
    name: "Pütürge",
    type: "Malmüdürlüğü"
  },
  {
    id: "44108",
    city: "Malatya",
    county: "Yeşilyurt",
    name: "Yeşilyurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "44109",
    city: "Malatya",
    county: "Battalgazi",
    name: "Battalgazi",
    type: "Malmüdürlüğü"
  },
  {
    id: "44110",
    city: "Malatya",
    county: "Doğanyol",
    name: "Doğanyol",
    type: "Malmüdürlüğü"
  },
  {
    id: "44111",
    city: "Malatya",
    county: "Kale",
    name: "Kale",
    type: "Malmüdürlüğü"
  },
  {
    id: "44112",
    city: "Malatya",
    county: "Kuluncak",
    name: "Kuluncak",
    type: "Malmüdürlüğü"
  },
  {
    id: "44113",
    city: "Malatya",
    county: "Yazıhan",
    name: "Yazıhan",
    type: "Malmüdürlüğü"
  },
  {
    id: "45250",
    city: "Manisa",
    county: "Merkez",
    name: "Manisa İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45251",
    city: "Manisa",
    county: "Merkez",
    name: "Şehit Cihan Güneş",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45252",
    city: "Manisa",
    county: "Merkez",
    name: "Mesir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45201",
    city: "Manisa",
    county: "Akhisar",
    name: "Akhisar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45202",
    city: "Manisa",
    county: "Alaşehir",
    name: "Alaşehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45203",
    city: "Manisa",
    county: "Demirci",
    name: "Demirci",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45204",
    city: "Manisa",
    county: "Kırkağaç",
    name: "Kırkağaç",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45205",
    city: "Manisa",
    county: "Salihli",
    name: "Salihli Adil Oral",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45206",
    city: "Manisa",
    county: "Sarıgöl",
    name: "Sarıgöl",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45207",
    city: "Manisa",
    county: "Saruhanlı",
    name: "Saruhanlı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45208",
    city: "Manisa",
    county: "Soma",
    name: "Soma",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45209",
    city: "Manisa",
    county: "Turgutlu",
    name: "Turgutlu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45210",
    city: "Manisa",
    county: "Gördes",
    name: "Gördes",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45211",
    city: "Manisa",
    county: "Kula",
    name: "Kula",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "45110",
    city: "Manisa",
    county: "Selendi",
    name: "Selendi",
    type: "Malmüdürlüğü"
  },
  {
    id: "45113",
    city: "Manisa",
    county: "Ahmetli",
    name: "Ahmetli",
    type: "Malmüdürlüğü"
  },
  {
    id: "45114",
    city: "Manisa",
    county: "Gölmarmara",
    name: "Gölmarmara",
    type: "Malmüdürlüğü"
  },
  {
    id: "45115",
    city: "Manisa",
    county: "Köprübaşı",
    name: "Köprübaşı",
    type: "Malmüdürlüğü"
  },
  {
    id: "46201",
    city: "Kahramanmaraş",
    county: "Merkez",
    name: "Aslanbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "46280",
    city: "Kahramanmaraş",
    county: "Merkez",
    name: "Aksu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "46260",
    city: "Kahramanmaraş",
    county: "Elbistan",
    name: "Elbistan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "46261",
    city: "Kahramanmaraş",
    county: "Afşin",
    name: "Afşin",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "46262",
    city: "Kahramanmaraş",
    county: "Pazarcık",
    name: "Pazarcık",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "46102",
    city: "Kahramanmaraş",
    county: "Andırın",
    name: "Andırın",
    type: "Malmüdürlüğü"
  },
  {
    id: "46104",
    city: "Kahramanmaraş",
    county: "Göksun",
    name: "Göksun",
    type: "Malmüdürlüğü"
  },
  {
    id: "46106",
    city: "Kahramanmaraş",
    county: "Türkoğlu",
    name: "Türkoğlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "46107",
    city: "Kahramanmaraş",
    county: "Çağlayancerit",
    name: "Çağlayancerit",
    type: "Malmüdürlüğü"
  },
  {
    id: "46108",
    city: "Kahramanmaraş",
    county: "Ekinözü",
    name: "Ekinözü",
    type: "Malmüdürlüğü"
  },
  {
    id: "46109",
    city: "Kahramanmaraş",
    county: "Nurhak",
    name: "Nurhak",
    type: "Malmüdürlüğü"
  },
  {
    id: "47260",
    city: "Mardin",
    county: "Merkez",
    name: "Mardin",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "47261",
    city: "Mardin",
    county: "Kızıltepe",
    name: "Kızıltepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "47262",
    city: "Mardin",
    county: "Nusaybin",
    name: "Nusaybin",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "47102",
    city: "Mardin",
    county: "Derik",
    name: "Derik",
    type: "Malmüdürlüğü"
  },
  {
    id: "47106",
    city: "Mardin",
    county: "Mazıdağı",
    name: "Mazıdağı",
    type: "Malmüdürlüğü"
  },
  {
    id: "47107",
    city: "Mardin",
    county: "Midyat",
    name: "Midyat",
    type: "Malmüdürlüğü"
  },
  {
    id: "47109",
    city: "Mardin",
    county: "Ömerli",
    name: "Ömerli",
    type: "Malmüdürlüğü"
  },
  {
    id: "47110",
    city: "Mardin",
    county: "Savur",
    name: "Savur",
    type: "Malmüdürlüğü"
  },
  {
    id: "47112",
    city: "Mardin",
    county: "Dargeçit",
    name: "Dargeçit",
    type: "Malmüdürlüğü"
  },
  {
    id: "47113",
    city: "Mardin",
    county: "Yeşilli",
    name: "Yeşilli",
    type: "Malmüdürlüğü"
  },
  {
    id: "48260",
    city: "Muğla",
    county: "Merkez",
    name: "Muğla",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "48261",
    city: "Muğla",
    county: "Bodrum",
    name: "Bodrum",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "48262",
    city: "Muğla",
    county: "Fethiye",
    name: "Fethiye",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "48263",
    city: "Muğla",
    county: "Köyceğiz",
    name: "Köyceğiz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "48264",
    city: "Muğla",
    county: "Milas",
    name: "Milas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "48265",
    city: "Muğla",
    county: "Marmaris",
    name: "Marmaris",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "48266",
    city: "Muğla",
    county: "Yatağan",
    name: "Yatağan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "48102",
    city: "Muğla",
    county: "Datça",
    name: "Datça",
    type: "Malmüdürlüğü"
  },
  {
    id: "48108",
    city: "Muğla",
    county: "Ula",
    name: "Ula",
    type: "Malmüdürlüğü"
  },
  {
    id: "48109",
    city: "Muğla",
    county: "Dalaman",
    name: "Dalaman",
    type: "Malmüdürlüğü"
  },
  {
    id: "48110",
    city: "Muğla",
    county: "Ortaca",
    name: "Ortaca",
    type: "Malmüdürlüğü"
  },
  {
    id: "48111",
    city: "Muğla",
    county: "Kavaklıdere",
    name: "Kavaklıdere",
    type: "Malmüdürlüğü"
  },
  {
    id: "48113",
    city: "Muğla",
    county: "Seydiemer",
    name: "Seydikemer",
    type: "Malmüdürlüğü"
  },
  {
    id: "49260",
    city: "Muş",
    county: "Merkez",
    name: "Muş",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "49101",
    city: "Muş",
    county: "Bulanık",
    name: "Bulanık",
    type: "Malmüdürlüğü"
  },
  {
    id: "49102",
    city: "Muş",
    county: "Malazgirt",
    name: "Malazgirt",
    type: "Malmüdürlüğü"
  },
  {
    id: "49103",
    city: "Muş",
    county: "Varto",
    name: "Varto",
    type: "Malmüdürlüğü"
  },
  {
    id: "49104",
    city: "Muş",
    county: "Hasköy",
    name: "Hasköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "49105",
    city: "Muş",
    county: "Korkut",
    name: "Korkut",
    type: "Malmüdürlüğü"
  },
  {
    id: "50260",
    city: "Nevşehir",
    county: "Merkez",
    name: "Nevşehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "50101",
    city: "Nevşehir",
    county: "Avanos",
    name: "Avanos",
    type: "Malmüdürlüğü"
  },
  {
    id: "50102",
    city: "Nevşehir",
    county: "Derinkuyu",
    name: "Derinkuyu",
    type: "Malmüdürlüğü"
  },
  {
    id: "50103",
    city: "Nevşehir",
    county: "Gülşehir",
    name: "Gülşehir",
    type: "Malmüdürlüğü"
  },
  {
    id: "50104",
    city: "Nevşehir",
    county: "Hacıbektaş",
    name: "Hacıbektaş",
    type: "Malmüdürlüğü"
  },
  {
    id: "50105",
    city: "Nevşehir",
    county: "Kozaklı",
    name: "Kozaklı",
    type: "Malmüdürlüğü"
  },
  {
    id: "50106",
    city: "Nevşehir",
    county: "Ürgüp",
    name: "Ürgüp",
    type: "Malmüdürlüğü"
  },
  {
    id: "50107",
    city: "Nevşehir",
    county: "Acıgöl",
    name: "Acıgöl",
    type: "Malmüdürlüğü"
  },
  {
    id: "51260",
    city: "Niğde",
    county: "Merkez",
    name: "Niğde",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "51262",
    city: "Niğde",
    county: "Bor",
    name: "Bor",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "51103",
    city: "Niğde",
    county: "Çamardı",
    name: "Çamardı",
    type: "Malmüdürlüğü"
  },
  {
    id: "51105",
    city: "Niğde",
    county: "Ulukışla",
    name: "Ulukışla",
    type: "Malmüdürlüğü"
  },
  {
    id: "51106",
    city: "Niğde",
    county: "Altunhisar",
    name: "Altunhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "51107",
    city: "Niğde",
    county: "Çiftlik",
    name: "Çiftlik",
    type: "Malmüdürlüğü"
  },
  {
    id: "52201",
    city: "Ordu",
    county: "Merkez",
    name: "Boztepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "52260",
    city: "Ordu",
    county: "Merkez",
    name: "Köprübaşı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "52261",
    city: "Ordu",
    county: "Fatsa",
    name: "Fatsa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "52262",
    city: "Ordu",
    county: "Ünye",
    name: "Ünye",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "52101",
    city: "Ordu",
    county: "Akkuş",
    name: "Akkuş",
    type: "Malmüdürlüğü"
  },
  {
    id: "52102",
    city: "Ordu",
    county: "Aybastı",
    name: "Aybastı",
    type: "Malmüdürlüğü"
  },
  {
    id: "52104",
    city: "Ordu",
    county: "Gölköy",
    name: "Gölköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "52105",
    city: "Ordu",
    county: "Korgan",
    name: "Korgan",
    type: "Malmüdürlüğü"
  },
  {
    id: "52106",
    city: "Ordu",
    county: "Kumru",
    name: "Kumru",
    type: "Malmüdürlüğü"
  },
  {
    id: "52107",
    city: "Ordu",
    county: "Mesudiye",
    name: "Mesudiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "52108",
    city: "Ordu",
    county: "Perşembe",
    name: "Perşembe",
    type: "Malmüdürlüğü"
  },
  {
    id: "52109",
    city: "Ordu",
    county: "Ulubey",
    name: "Ulubey",
    type: "Malmüdürlüğü"
  },
  {
    id: "52111",
    city: "Ordu",
    county: "Gülyalı",
    name: "Gülyalı",
    type: "Malmüdürlüğü"
  },
  {
    id: "52112",
    city: "Ordu",
    county: "Gürgentepe",
    name: "Gürgentepe",
    type: "Malmüdürlüğü"
  },
  {
    id: "52113",
    city: "Ordu",
    county: "Çamaş",
    name: "Çamaş",
    type: "Malmüdürlüğü"
  },
  {
    id: "52114",
    city: "Ordu",
    county: "Çatalpınar",
    name: "Çatalpınar",
    type: "Malmüdürlüğü"
  },
  {
    id: "52115",
    city: "Ordu",
    county: "Çaybaşı",
    name: "Çaybaşı",
    type: "Malmüdürlüğü"
  },
  {
    id: "52116",
    city: "Ordu",
    county: "İkizce",
    name: "İkizce",
    type: "Malmüdürlüğü"
  },
  {
    id: "52117",
    city: "Ordu",
    county: "Kabadüz",
    name: "Kabadüz",
    type: "Malmüdürlüğü"
  },
  {
    id: "52118",
    city: "Ordu",
    county: "Kabataş",
    name: "Kabataş",
    type: "Malmüdürlüğü"
  },
  {
    id: "53201",
    city: "Rize",
    county: "Merkez",
    name: "Kaçkar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "53260",
    city: "Rize",
    county: "Merkez",
    name: "Yeşilçay",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "53261",
    city: "Rize",
    county: "Çayeli",
    name: "Çayeli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "53262",
    city: "Rize",
    county: "Pazar",
    name: "Pazar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "53263",
    city: "Rize",
    county: "Ardeşen",
    name: "Ardeşen",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "53102",
    city: "Rize",
    county: "Çamlıhemşin",
    name: "Çamlıhemşin",
    type: "Malmüdürlüğü"
  },
  {
    id: "53104",
    city: "Rize",
    county: "Fındıklı",
    name: "Fındıklı",
    type: "Malmüdürlüğü"
  },
  {
    id: "53105",
    city: "Rize",
    county: "İkizdere",
    name: "İkizdere",
    type: "Malmüdürlüğü"
  },
  {
    id: "53106",
    city: "Rize",
    county: "Kalkandere",
    name: "Kalkandere",
    type: "Malmüdürlüğü"
  },
  {
    id: "53108",
    city: "Rize",
    county: "Güneysu",
    name: "Güneysu",
    type: "Malmüdürlüğü"
  },
  {
    id: "53109",
    city: "Rize",
    county: "Derepazarı",
    name: "Derepazarı",
    type: "Malmüdürlüğü"
  },
  {
    id: "53110",
    city: "Rize",
    county: "Hemşin",
    name: "Hemşin",
    type: "Malmüdürlüğü"
  },
  {
    id: "53111",
    city: "Rize",
    county: "İyidere",
    name: "İyidere",
    type: "Malmüdürlüğü"
  },
  {
    id: "54250",
    city: "Sakarya",
    county: "Merkez",
    name: "Sakarya İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54251",
    city: "Sakarya",
    county: "Merkez",
    name: "Gümrükönü",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54252",
    city: "Sakarya",
    county: "Merkez",
    name: "Ali Fuat Cebesoy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54253",
    city: "Sakarya",
    county: "Merkez- (5216)",
    name: "Sapanca",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54201",
    city: "Sakarya",
    county: "Merkez- (5216)",
    name: "Akyazı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54202",
    city: "Sakarya",
    county: "Geyve",
    name: "Geyve",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54203",
    city: "Sakarya",
    county: "Merkez- (5216)",
    name: "Hendek",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54204",
    city: "Sakarya",
    county: "Karasu",
    name: "Karasu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "54105",
    city: "Sakarya",
    county: "Kaynarca",
    name: "Kaynarca",
    type: "Malmüdürlüğü"
  },
  {
    id: "54107",
    city: "Sakarya",
    county: "Kocaali",
    name: "Kocaali",
    type: "Malmüdürlüğü"
  },
  {
    id: "54108",
    city: "Sakarya",
    county: "Pamukova",
    name: "Pamukova",
    type: "Malmüdürlüğü"
  },
  {
    id: "54109",
    city: "Sakarya",
    county: "Taraklı",
    name: "Taraklı",
    type: "Malmüdürlüğü"
  },
  {
    id: "54111",
    city: "Sakarya",
    county: "Merkez- (5216)",
    name: "Karapürçek",
    type: "Malmüdürlüğü"
  },
  {
    id: "55251",
    city: "Samsun",
    county: "Merkez",
    name: "19 Mayıs",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "55252",
    city: "Samsun",
    county: "Merkez",
    name: "Gaziler",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "55290",
    city: "Samsun",
    county: "Merkez",
    name: "Zafer",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "55202",
    city: "Samsun",
    county: "Bafra",
    name: "Bafra",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "55203",
    city: "Samsun",
    county: "Çarşamba",
    name: "Çarşamba",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "55204",
    city: "Samsun",
    county: "Terme",
    name: "Terme",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "55205",
    city: "Samsun",
    county: "Havza",
    name: "Havza",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "55101",
    city: "Samsun",
    county: "Alaçam",
    name: "Alaçam",
    type: "Malmüdürlüğü"
  },
  {
    id: "55105",
    city: "Samsun",
    county: "Kavak",
    name: "Kavak",
    type: "Malmüdürlüğü"
  },
  {
    id: "55106",
    city: "Samsun",
    county: "Ladik",
    name: "Ladik",
    type: "Malmüdürlüğü"
  },
  {
    id: "55108",
    city: "Samsun",
    county: "Vezirköprü",
    name: "Vezirköprü",
    type: "Malmüdürlüğü"
  },
  {
    id: "55109",
    city: "Samsun",
    county: "Asarcık",
    name: "Asarcık",
    type: "Malmüdürlüğü"
  },
  {
    id: "55110",
    city: "Samsun",
    county: "Ondokuz Mayıs",
    name: "Ondokuz Mayıs",
    type: "Malmüdürlüğü"
  },
  {
    id: "55111",
    city: "Samsun",
    county: "Salıpazarı",
    name: "Salıpazarı",
    type: "Malmüdürlüğü"
  },
  {
    id: "55112",
    city: "Samsun",
    county: "Merkez- (5216)",
    name: "Tekkeköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "55113",
    city: "Samsun",
    county: "Ayvacık",
    name: "Ayvacık",
    type: "Malmüdürlüğü"
  },
  {
    id: "55114",
    city: "Samsun",
    county: "Yakakent",
    name: "Yakakent",
    type: "Malmüdürlüğü"
  },
  {
    id: "56260",
    city: "Siirt",
    county: "Merkez",
    name: "Siirt",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "56102",
    city: "Siirt",
    county: "Baykan",
    name: "Baykan",
    type: "Malmüdürlüğü"
  },
  {
    id: "56104",
    city: "Siirt",
    county: "Eruh",
    name: "Eruh",
    type: "Malmüdürlüğü"
  },
  {
    id: "56106",
    city: "Siirt",
    county: "Kurtalan",
    name: "Kurtalan",
    type: "Malmüdürlüğü"
  },
  {
    id: "56107",
    city: "Siirt",
    county: "Pervari",
    name: "Pervari",
    type: "Malmüdürlüğü"
  },
  {
    id: "56110",
    city: "Siirt",
    county: "Şirvan",
    name: "Şirvan",
    type: "Malmüdürlüğü"
  },
  {
    id: "57260",
    city: "Sinop",
    county: "Merkez",
    name: "Sinop",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "57261",
    city: "Sinop",
    county: "Boyabat",
    name: "Boyabat",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "57101",
    city: "Sinop",
    county: "Ayancık",
    name: "Ayancık",
    type: "Malmüdürlüğü"
  },
  {
    id: "57103",
    city: "Sinop",
    county: "Durağan",
    name: "Durağan",
    type: "Malmüdürlüğü"
  },
  {
    id: "57104",
    city: "Sinop",
    county: "Erfelek",
    name: "Erfelek",
    type: "Malmüdürlüğü"
  },
  {
    id: "57105",
    city: "Sinop",
    county: "Gerze",
    name: "Gerze",
    type: "Malmüdürlüğü"
  },
  {
    id: "57106",
    city: "Sinop",
    county: "Türkeli",
    name: "Türkeli",
    type: "Malmüdürlüğü"
  },
  {
    id: "57107",
    city: "Sinop",
    county: "Dikmen",
    name: "Dikmen",
    type: "Malmüdürlüğü"
  },
  {
    id: "57108",
    city: "Sinop",
    county: "Saraydüzü",
    name: "Saraydüzü",
    type: "Malmüdürlüğü"
  },
  {
    id: "58201",
    city: "Sivas",
    county: "Merkez",
    name: "Kale",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "58280",
    city: "Sivas",
    county: "Merkez",
    name: "Site",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "58260",
    city: "Sivas",
    county: "Şarkışla",
    name: "Şarkışla",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "58101",
    city: "Sivas",
    county: "Divriği",
    name: "Divriği",
    type: "Malmüdürlüğü"
  },
  {
    id: "58102",
    city: "Sivas",
    county: "Gemerek",
    name: "Gemerek",
    type: "Malmüdürlüğü"
  },
  {
    id: "58103",
    city: "Sivas",
    county: "Gürün",
    name: "Gürün",
    type: "Malmüdürlüğü"
  },
  {
    id: "58104",
    city: "Sivas",
    county: "Hafik",
    name: "Hafik",
    type: "Malmüdürlüğü"
  },
  {
    id: "58105",
    city: "Sivas",
    county: "İmranlı",
    name: "İmranlı",
    type: "Malmüdürlüğü"
  },
  {
    id: "58106",
    city: "Sivas",
    county: "Kangal",
    name: "Kangal",
    type: "Malmüdürlüğü"
  },
  {
    id: "58107",
    city: "Sivas",
    county: "Koyulhisar",
    name: "Koyulhisar",
    type: "Malmüdürlüğü"
  },
  {
    id: "58109",
    city: "Sivas",
    county: "Suşehri",
    name: "Suşehri",
    type: "Malmüdürlüğü"
  },
  {
    id: "58110",
    city: "Sivas",
    county: "Yıldızeli",
    name: "Yıldızeli",
    type: "Malmüdürlüğü"
  },
  {
    id: "58111",
    city: "Sivas",
    county: "Zara",
    name: "Zara",
    type: "Malmüdürlüğü"
  },
  {
    id: "58112",
    city: "Sivas",
    county: "Akıncılar",
    name: "Akıncılar",
    type: "Malmüdürlüğü"
  },
  {
    id: "58113",
    city: "Sivas",
    county: "Altınyayla",
    name: "Altınyayla",
    type: "Malmüdürlüğü"
  },
  {
    id: "58114",
    city: "Sivas",
    county: "Doğanşar",
    name: "Doğanşar",
    type: "Malmüdürlüğü"
  },
  {
    id: "58115",
    city: "Sivas",
    county: "Gölova",
    name: "Gölova",
    type: "Malmüdürlüğü"
  },
  {
    id: "58116",
    city: "Sivas",
    county: "Ulaş",
    name: "Ulaş",
    type: "Malmüdürlüğü"
  },
  {
    id: "59201",
    city: "Tekirdağ",
    county: "Merkez",
    name: "Süleymanpaşa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59260",
    city: "Tekirdağ",
    county: "Merkez",
    name: "Namık Kemal",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59250",
    city: "Tekirdağ",
    county: "Çorlu",
    name: "Çorlu İhtisas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59261",
    city: "Tekirdağ",
    county: "Çerkezköy",
    name: "Çerkezköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59262",
    city: "Tekirdağ",
    county: "Çorlu",
    name: "Çorlu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59263",
    city: "Tekirdağ",
    county: "Hayrabolu",
    name: "Hayrabolu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59264",
    city: "Tekirdağ",
    county: "Malkara",
    name: "Malkara",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59265",
    city: "Tekirdağ",
    county: "Muratlı",
    name: "Muratlı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59266",
    city: "Tekirdağ",
    county: "Şarköy",
    name: "Şarköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59267",
    city: "Tekirdağ",
    county: "Kapaklı",
    name: "Kapaklı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "59106",
    city: "Tekirdağ",
    county: "Saray",
    name: "Saray",
    type: "Malmüdürlüğü"
  },
  {
    id: "59108",
    city: "Tekirdağ",
    county: "Marmara Ereğlisi",
    name: "Marmara Ereğlisi",
    type: "Malmüdürlüğü"
  },
  {
    id: "60260",
    city: "Tokat",
    county: "Merkez",
    name: "Tokat",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "60261",
    city: "Tokat",
    county: "Erbaa",
    name: "Erbaa",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "60262",
    city: "Tokat",
    county: "Niksar",
    name: "Niksar",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "60263",
    city: "Tokat",
    county: "Turhal",
    name: "Turhal",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "60264",
    city: "Tokat",
    county: "Zile",
    name: "Zile",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "60101",
    city: "Tokat",
    county: "Almus",
    name: "Almus",
    type: "Malmüdürlüğü"
  },
  {
    id: "60102",
    city: "Tokat",
    county: "Artova",
    name: "Artova",
    type: "Malmüdürlüğü"
  },
  {
    id: "60105",
    city: "Tokat",
    county: "Reşadiye",
    name: "Reşadiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "60108",
    city: "Tokat",
    county: "Pazar",
    name: "Pazar",
    type: "Malmüdürlüğü"
  },
  {
    id: "60109",
    city: "Tokat",
    county: "Yeşilyurt",
    name: "Yeşilyurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "60110",
    city: "Tokat",
    county: "Başçiftlik",
    name: "Başçiftlik",
    type: "Malmüdürlüğü"
  },
  {
    id: "60111",
    city: "Tokat",
    county: "Sulusaray",
    name: "Sulusaray",
    type: "Malmüdürlüğü"
  },
  {
    id: "61201",
    city: "Trabzon",
    county: "Merkez",
    name: "Hızırbey",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "61280",
    city: "Trabzon",
    county: "Merkez",
    name: "Karadeniz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "61260",
    city: "Trabzon",
    county: "Akçaabat",
    name: "Akçaabat",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "61261",
    city: "Trabzon",
    county: "Of",
    name: "Of",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "61262",
    city: "Trabzon",
    county: "Vakfıkebir",
    name: "Vakfıkebir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "61102",
    city: "Trabzon",
    county: "Araklı",
    name: "Araklı",
    type: "Malmüdürlüğü"
  },
  {
    id: "61103",
    city: "Trabzon",
    county: "Arsin",
    name: "Arsin",
    type: "Malmüdürlüğü"
  },
  {
    id: "61104",
    city: "Trabzon",
    county: "Çaykara",
    name: "Çaykara",
    type: "Malmüdürlüğü"
  },
  {
    id: "61105",
    city: "Trabzon",
    county: "Maçka",
    name: "Maçka",
    type: "Malmüdürlüğü"
  },
  {
    id: "61107",
    city: "Trabzon",
    county: "Sürmene",
    name: "Sürmene",
    type: "Malmüdürlüğü"
  },
  {
    id: "61108",
    city: "Trabzon",
    county: "Tonya",
    name: "Tonya",
    type: "Malmüdürlüğü"
  },
  {
    id: "61110",
    city: "Trabzon",
    county: "Yomra",
    name: "Yomra",
    type: "Malmüdürlüğü"
  },
  {
    id: "61111",
    city: "Trabzon",
    county: "Beşikdüzü",
    name: "Beşikdüzü",
    type: "Malmüdürlüğü"
  },
  {
    id: "61112",
    city: "Trabzon",
    county: "Şalpazarı",
    name: "Şalpazarı",
    type: "Malmüdürlüğü"
  },
  {
    id: "61113",
    city: "Trabzon",
    county: "Çarşıbaşı",
    name: "Çarşıbaşı",
    type: "Malmüdürlüğü"
  },
  {
    id: "61114",
    city: "Trabzon",
    county: "Dernekpazarı",
    name: "Dernekpazarı",
    type: "Malmüdürlüğü"
  },
  {
    id: "61115",
    city: "Trabzon",
    county: "Düzköy",
    name: "Düzköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "61116",
    city: "Trabzon",
    county: "Hayrat",
    name: "Hayrat",
    type: "Malmüdürlüğü"
  },
  {
    id: "61117",
    city: "Trabzon",
    county: "Köprübaşı",
    name: "Köprübaşı",
    type: "Malmüdürlüğü"
  },
  {
    id: "62260",
    city: "Tunceli",
    county: "Merkez",
    name: "Tunceli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "62101",
    city: "Tunceli",
    county: "Çemişgezek",
    name: "Çemişgezek",
    type: "Malmüdürlüğü"
  },
  {
    id: "62102",
    city: "Tunceli",
    county: "Hozat",
    name: "Hozat",
    type: "Malmüdürlüğü"
  },
  {
    id: "62103",
    city: "Tunceli",
    county: "Mazgirt",
    name: "Mazgirt",
    type: "Malmüdürlüğü"
  },
  {
    id: "62104",
    city: "Tunceli",
    county: "Nazimiye",
    name: "Nazimiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "62105",
    city: "Tunceli",
    county: "Ovacık",
    name: "Ovacık",
    type: "Malmüdürlüğü"
  },
  {
    id: "62106",
    city: "Tunceli",
    county: "Pertek",
    name: "Pertek",
    type: "Malmüdürlüğü"
  },
  {
    id: "62107",
    city: "Tunceli",
    county: "Pülümür",
    name: "Pülümür",
    type: "Malmüdürlüğü"
  },
  {
    id: "63201",
    city: "Şanlıurfa",
    county: "Merkez",
    name: "Şehitlik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "63280",
    city: "Şanlıurfa",
    county: "Merkez",
    name: "Topçu Meydanı",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "63281",
    city: "Şanlıurfa",
    county: "Merkez",
    name: "Göbeklitepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "63260",
    city: "Şanlıurfa",
    county: "Siverek",
    name: "Siverek",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "63261",
    city: "Şanlıurfa",
    county: "Viranşehir",
    name: "Viranşehir",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "63262",
    city: "Şanlıurfa",
    county: "Birecik",
    name: "Birecik",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "63263",
    city: "Şanlıurfa",
    county: "Suruç",
    name: "Suruç",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "63101",
    city: "Şanlıurfa",
    county: "Akçakale",
    name: "Akçakale",
    type: "Malmüdürlüğü"
  },
  {
    id: "63103",
    city: "Şanlıurfa",
    county: "Bozova",
    name: "Bozova",
    type: "Malmüdürlüğü"
  },
  {
    id: "63104",
    city: "Şanlıurfa",
    county: "Halfeti",
    name: "Halfeti",
    type: "Malmüdürlüğü"
  },
  {
    id: "63105",
    city: "Şanlıurfa",
    county: "Hilvan",
    name: "Hilvan",
    type: "Malmüdürlüğü"
  },
  {
    id: "63109",
    city: "Şanlıurfa",
    county: "Ceylanpınar",
    name: "Ceylanpınar",
    type: "Malmüdürlüğü"
  },
  {
    id: "63110",
    city: "Şanlıurfa",
    county: "Harran",
    name: "Harran",
    type: "Malmüdürlüğü"
  },
  {
    id: "64260",
    city: "Uşak",
    county: "Merkez",
    name: "Uşak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "64261",
    city: "Uşak",
    county: "Banaz",
    name: "Banaz",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "64262",
    city: "Uşak",
    county: "Eşme",
    name: "Eşme",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "64103",
    city: "Uşak",
    county: "Karahallı",
    name: "Karahallı",
    type: "Malmüdürlüğü"
  },
  {
    id: "64104",
    city: "Uşak",
    county: "Ulubey",
    name: "Ulubey",
    type: "Malmüdürlüğü"
  },
  {
    id: "64105",
    city: "Uşak",
    county: "Sivaslı",
    name: "Sivaslı",
    type: "Malmüdürlüğü"
  },
  {
    id: "65260",
    city: "Van",
    county: "Merkez",
    name: "Van",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "65201",
    city: "Van",
    county: "Erciş",
    name: "Erciş",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "65101",
    city: "Van",
    county: "Başkale",
    name: "Başkale",
    type: "Malmüdürlüğü"
  },
  {
    id: "65102",
    city: "Van",
    county: "Çatak",
    name: "Çatak",
    type: "Malmüdürlüğü"
  },
  {
    id: "65104",
    city: "Van",
    county: "Gevaş",
    name: "Gevaş",
    type: "Malmüdürlüğü"
  },
  {
    id: "65105",
    city: "Van",
    county: "Gürpınar",
    name: "Gürpınar",
    type: "Malmüdürlüğü"
  },
  {
    id: "65106",
    city: "Van",
    county: "Muradiye",
    name: "Muradiye",
    type: "Malmüdürlüğü"
  },
  {
    id: "65107",
    city: "Van",
    county: "Özalp",
    name: "Özalp",
    type: "Malmüdürlüğü"
  },
  {
    id: "65108",
    city: "Van",
    county: "Bahçesaray",
    name: "Bahçesaray",
    type: "Malmüdürlüğü"
  },
  {
    id: "65109",
    city: "Van",
    county: "Çaldıran",
    name: "Çaldıran",
    type: "Malmüdürlüğü"
  },
  {
    id: "65110",
    city: "Van",
    county: "Edremit",
    name: "Edremit",
    type: "Malmüdürlüğü"
  },
  {
    id: "65111",
    city: "Van",
    county: "Saray",
    name: "Saray",
    type: "Malmüdürlüğü"
  },
  {
    id: "66260",
    city: "Yozgat",
    county: "Merkez",
    name: "Yozgat",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "66261",
    city: "Yozgat",
    county: "Boğazlıyan",
    name: "Boğazlıyan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "66262",
    city: "Yozgat",
    county: "Sorgun",
    name: "Sorgun",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "66263",
    city: "Yozgat",
    county: "Yerköy",
    name: "Yerköy",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "66101",
    city: "Yozgat",
    county: "Akdağmadeni",
    name: "Akdağmadeni",
    type: "Malmüdürlüğü"
  },
  {
    id: "66103",
    city: "Yozgat",
    county: "Çayıralan",
    name: "Çayıralan",
    type: "Malmüdürlüğü"
  },
  {
    id: "66104",
    city: "Yozgat",
    county: "Çekerek",
    name: "Çekerek",
    type: "Malmüdürlüğü"
  },
  {
    id: "66105",
    city: "Yozgat",
    county: "Sarıkaya",
    name: "Sarıkaya",
    type: "Malmüdürlüğü"
  },
  {
    id: "66106",
    city: "Yozgat",
    county: "Şefaatli",
    name: "Şefaatli",
    type: "Malmüdürlüğü"
  },
  {
    id: "66109",
    city: "Yozgat",
    county: "Aydıncık",
    name: "Aydıncık",
    type: "Malmüdürlüğü"
  },
  {
    id: "66110",
    city: "Yozgat",
    county: "Çandır",
    name: "Çandır",
    type: "Malmüdürlüğü"
  },
  {
    id: "66111",
    city: "Yozgat",
    county: "Kadışehri",
    name: "Kadışehri",
    type: "Malmüdürlüğü"
  },
  {
    id: "66112",
    city: "Yozgat",
    county: "Saraykent",
    name: "Saraykent",
    type: "Malmüdürlüğü"
  },
  {
    id: "66113",
    city: "Yozgat",
    county: "Yenifakılı",
    name: "Yenifakılı",
    type: "Malmüdürlüğü"
  },
  {
    id: "67201",
    city: "Zonguldak",
    county: "Merkez",
    name: "Uzunmehmet",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "67280",
    city: "Zonguldak",
    county: "Merkez",
    name: "Kara Elmas",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "67261",
    city: "Zonguldak",
    county: "Ereğli",
    name: "Ereğli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "67263",
    city: "Zonguldak",
    county: "Çaycuma",
    name: "Çaycuma",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "67264",
    city: "Zonguldak",
    county: "Devrek",
    name: "Devrek",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "67110",
    city: "Zonguldak",
    county: "Alaplı",
    name: "Alaplı",
    type: "Malmüdürlüğü"
  },
  {
    id: "67113",
    city: "Zonguldak",
    county: "Gökçebey",
    name: "Gökçebey",
    type: "Malmüdürlüğü"
  },
  {
    id: "68201",
    city: "Aksaray",
    county: "Merkez",
    name: "Aksaray",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "68101",
    city: "Aksaray",
    county: "Ağaçören",
    name: "Ağaçören",
    type: "Malmüdürlüğü"
  },
  {
    id: "68102",
    city: "Aksaray",
    county: "Güzelyurt",
    name: "Güzelyurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "68103",
    city: "Aksaray",
    county: "Ortaköy",
    name: "Ortaköy",
    type: "Malmüdürlüğü"
  },
  {
    id: "68104",
    city: "Aksaray",
    county: "Sarıyahşi",
    name: "Sarıyahşi",
    type: "Malmüdürlüğü"
  },
  {
    id: "68105",
    city: "Aksaray",
    county: "Eskil",
    name: "Eskil",
    type: "Malmüdürlüğü"
  },
  {
    id: "68106",
    city: "Aksaray",
    county: "Gülağaç",
    name: "Gülağaç",
    type: "Malmüdürlüğü"
  },
  {
    id: "69201",
    city: "Bayburt",
    county: "Merkez",
    name: "Bayburt",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "69101",
    city: "Bayburt",
    county: "Aydıntepe",
    name: "Aydıntepe",
    type: "Malmüdürlüğü"
  },
  {
    id: "69102",
    city: "Bayburt",
    county: "Demirözü",
    name: "Demirözü",
    type: "Malmüdürlüğü"
  },
  {
    id: "70201",
    city: "Karaman",
    county: "Merkez",
    name: "Karaman",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "70101",
    city: "Karaman",
    county: "Ayrancı",
    name: "Ayrancı",
    type: "Malmüdürlüğü"
  },
  {
    id: "70102",
    city: "Karaman",
    county: "Ermenek",
    name: "Ermenek",
    type: "Malmüdürlüğü"
  },
  {
    id: "70103",
    city: "Karaman",
    county: "Kazım Karabekir",
    name: "Kazım Karabekir",
    type: "Malmüdürlüğü"
  },
  {
    id: "70104",
    city: "Karaman",
    county: "Başyayla",
    name: "Başyayla",
    type: "Malmüdürlüğü"
  },
  {
    id: "70105",
    city: "Karaman",
    county: "Sarıveliler",
    name: "Sarıveliler",
    type: "Malmüdürlüğü"
  },
  {
    id: "71201",
    city: "Kırıkkale",
    county: "Merkez",
    name: "Irmak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "71202",
    city: "Kırıkkale",
    county: "Merkez",
    name: "Kaletepe",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "71101",
    city: "Kırıkkale",
    county: "Delice",
    name: "Delice",
    type: "Malmüdürlüğü"
  },
  {
    id: "71102",
    city: "Kırıkkale",
    county: "Keskin",
    name: "Keskin",
    type: "Malmüdürlüğü"
  },
  {
    id: "71103",
    city: "Kırıkkale",
    county: "Sulakyurt",
    name: "Sulakyurt",
    type: "Malmüdürlüğü"
  },
  {
    id: "71105",
    city: "Kırıkkale",
    county: "Balışeyh",
    name: "Balışeyh",
    type: "Malmüdürlüğü"
  },
  {
    id: "71106",
    city: "Kırıkkale",
    county: "Çelebi",
    name: "Çelebi",
    type: "Malmüdürlüğü"
  },
  {
    id: "71107",
    city: "Kırıkkale",
    county: "Karakeçili",
    name: "Karakeçili",
    type: "Malmüdürlüğü"
  },
  {
    id: "72260",
    city: "Batman",
    county: "Merkez",
    name: "Batman",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "72101",
    city: "Batman",
    county: "Beşiri",
    name: "Beşiri",
    type: "Malmüdürlüğü"
  },
  {
    id: "72102",
    city: "Batman",
    county: "Gercüş",
    name: "Gercüş",
    type: "Malmüdürlüğü"
  },
  {
    id: "72103",
    city: "Batman",
    county: "Hasankeyf",
    name: "Hasankeyf",
    type: "Malmüdürlüğü"
  },
  {
    id: "72104",
    city: "Batman",
    county: "Kozluk",
    name: "Kozluk",
    type: "Malmüdürlüğü"
  },
  {
    id: "72105",
    city: "Batman",
    county: "Sason",
    name: "Sason",
    type: "Malmüdürlüğü"
  },
  {
    id: "73260",
    city: "Şırnak",
    county: "Merkez",
    name: "Şırnak",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "73261",
    city: "Şırnak",
    county: "Cizre",
    name: "Cizre",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "73262",
    city: "Şırnak",
    county: "Silopi",
    name: "Silopi",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "73101",
    city: "Şırnak",
    county: "Beytüşşebap",
    name: "Beytüşşebap",
    type: "Malmüdürlüğü"
  },
  {
    id: "73103",
    city: "Şırnak",
    county: "Güçlükonak",
    name: "Güçlükonak",
    type: "Malmüdürlüğü"
  },
  {
    id: "73104",
    city: "Şırnak",
    county: "İdil",
    name: "İdil",
    type: "Malmüdürlüğü"
  },
  {
    id: "73106",
    city: "Şırnak",
    county: "Uludere",
    name: "Uludere",
    type: "Malmüdürlüğü"
  },
  {
    id: "74260",
    city: "Bartın",
    county: "Merkez",
    name: "Bartın",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "74101",
    city: "Bartın",
    county: "Amasra",
    name: "Amasra",
    type: "Malmüdürlüğü"
  },
  {
    id: "74102",
    city: "Bartın",
    county: "Kurucaşile",
    name: "Kurucaşile",
    type: "Malmüdürlüğü"
  },
  {
    id: "74103",
    city: "Bartın",
    county: "Ulus",
    name: "Ulus",
    type: "Malmüdürlüğü"
  },
  {
    id: "75201",
    city: "Ardahan",
    county: "Merkez",
    name: "Ardahan",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "75101",
    city: "Ardahan",
    county: "Çıldır",
    name: "Çıldır",
    type: "Malmüdürlüğü"
  },
  {
    id: "75102",
    city: "Ardahan",
    county: "Damal",
    name: "Damal",
    type: "Malmüdürlüğü"
  },
  {
    id: "75103",
    city: "Ardahan",
    county: "Göle",
    name: "Göle",
    type: "Malmüdürlüğü"
  },
  {
    id: "75104",
    city: "Ardahan",
    county: "Hanak",
    name: "Hanak",
    type: "Malmüdürlüğü"
  },
  {
    id: "75105",
    city: "Ardahan",
    county: "Posof",
    name: "Posof",
    type: "Malmüdürlüğü"
  },
  {
    id: "76201",
    city: "Iğdır",
    county: "Merkez",
    name: "Iğdır",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "76101",
    city: "Iğdır",
    county: "Aralık",
    name: "Aralık",
    type: "Malmüdürlüğü"
  },
  {
    id: "76102",
    city: "Iğdır",
    county: "Karakoyunlu",
    name: "Karakoyunlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "76103",
    city: "Iğdır",
    county: "Tuzluca",
    name: "Tuzluca",
    type: "Malmüdürlüğü"
  },
  {
    id: "77201",
    city: "Yalova",
    county: "Merkez",
    name: "Yalova",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "77101",
    city: "Yalova",
    county: "Altınova",
    name: "Altınova",
    type: "Malmüdürlüğü"
  },
  {
    id: "77102",
    city: "Yalova",
    county: "Armutlu",
    name: "Armutlu",
    type: "Malmüdürlüğü"
  },
  {
    id: "77103",
    city: "Yalova",
    county: "Çınarcık",
    name: "Çınarcık",
    type: "Malmüdürlüğü"
  },
  {
    id: "78201",
    city: "Karabük",
    county: "Merkez",
    name: "Karabük",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "78260",
    city: "Karabük",
    county: "Safranbolu",
    name: "Safranbolu",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "78101",
    city: "Karabük",
    county: "Eflani",
    name: "Eflani",
    type: "Malmüdürlüğü"
  },
  {
    id: "78102",
    city: "Karabük",
    county: "Eskipazar",
    name: "Eskipazar",
    type: "Malmüdürlüğü"
  },
  {
    id: "78103",
    city: "Karabük",
    county: "Ovacık",
    name: "Ovacık",
    type: "Malmüdürlüğü"
  },
  {
    id: "78105",
    city: "Karabük",
    county: "Yenice",
    name: "Yenice",
    type: "Malmüdürlüğü"
  },
  {
    id: "79201",
    city: "Kilis",
    county: "Merkez",
    name: "Kilis",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "80201",
    city: "Osmaniye",
    county: "Merkez",
    name: "Osmaniye",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "80260",
    city: "Osmaniye",
    county: "Kadirli",
    name: "Kadirli",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "80101",
    city: "Osmaniye",
    county: "Bahçe",
    name: "Bahçe",
    type: "Malmüdürlüğü"
  },
  {
    id: "80102",
    city: "Osmaniye",
    county: "Düziçi",
    name: "Düziçi",
    type: "Malmüdürlüğü"
  },
  {
    id: "80104",
    city: "Osmaniye",
    county: "Hasanbeyli",
    name: "Hasanbeyli",
    type: "Malmüdürlüğü"
  },
  {
    id: "80105",
    city: "Osmaniye",
    county: "Sumbas",
    name: "Sumbas",
    type: "Malmüdürlüğü"
  },
  {
    id: "80106",
    city: "Osmaniye",
    county: "Toprakkale",
    name: "Toprakkale",
    type: "Malmüdürlüğü"
  },
  {
    id: "81260",
    city: "Düzce",
    county: "Merkez",
    name: "Düzce",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "81261",
    city: "Düzce",
    county: "Akçakoca",
    name: "Akçakoca",
    type: "Vergi Dairesi Müdürlüğü"
  },
  {
    id: "81102",
    city: "Düzce",
    county: "Cumayeri",
    name: "Cumayeri",
    type: "Malmüdürlüğü"
  },
  {
    id: "81103",
    city: "Düzce",
    county: "Çilimli",
    name: "Çilimli",
    type: "Malmüdürlüğü"
  },
  {
    id: "81104",
    city: "Düzce",
    county: "Gölyaka",
    name: "Gölyaka",
    type: "Malmüdürlüğü"
  },
  {
    id: "81105",
    city: "Düzce",
    county: "Gümüşova",
    name: "Gümüşova",
    type: "Malmüdürlüğü"
  },
  {
    id: "81106",
    city: "Düzce",
    county: "Kaynaşlı",
    name: "Kaynaşlı",
    type: "Malmüdürlüğü"
  },
  {
    id: "81107",
    city: "Düzce",
    county: "Yığılca",
    name: "Yığılca",
    type: "Malmüdürlüğü"
  }
];

const getCity = val =>
  cities.find(x => x.slug === val || x.id == val || x.name == val);

const getCounty = val =>
  counties.find(x => x.slug === val || x.id == val || x.name == val);

const getCounties = val => {
  if (!val) return counties;

  const city = getCity(val);

  if (!city) return [];

  return counties.filter(x => x.cityId === city.id);
};

export {
  cities,
  getCity,
  counties,
  getCounty,
  getCounties,
  tradeRegistrationOffices,
  taxOffices
};
