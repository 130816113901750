import { createStore } from "vuex";
import { settings, setLocalStorageState, applicationStatus } from "../shared";
import apiClient from "../services";
import * as moment from "moment";
import { local } from "../shared";

const steps = [
  {
    title: "E-posta Doğrulama",
    name: "validation",
    required: true
  },
  {
    title: "Üyelik Kontrolü",
    name: "check-membership",
    required: true
  },
  {
    title: "Birlik Belirleme",
    name: "choose-association",
    required: true
  },
  {
    title: "Başvuru Sahibi",
    name: "applicant",
    required: true
  },
  {
    title: "Firma Bilgileri",
    name: "company-info",
    required: true
  },
  {
    title: "Merkez Adresi",
    name: "company-address-hq",
    required: true
  },
  {
    title: "Şube Adresi",
    name: "company-address-branch",
    required: true
  },
  {
    title: "Firma Yetkilileri",
    name: "company-contacts",
    required: true
  },
  {
    title: "Bildirim Ayarları",
    name: "notification-settings",
    required: true
  },
  {
    title: "Başvuru Onayı",
    name: "review",
    required: true
  },
  {
    title: "Evrak Yükleme",
    name: "upload",
    required: true
  }
];

export default createStore({
  state() {
    return {
      progress: 0,
      routeStateId: null,
      pageTitle: null,
      registrationType: null,
      isPageLoading: false,
      pageLoadingMessage: null,
      applicationData: {
        approvalRequest: null,
        /*
          code: "TIM20-121216-56176";
          id: "08e85d2a-b38c-4d65-861d-4cd7a8161b40";
          isCargoReceived: false;
          isPaymentReceived: false;
          rejectReason: null;
          requestTypeId: 2017;
          requestTypeText: "Yeni Birlik Üyeliği Başvurusu";
          statusId: 2101;
          statusText: "Hazırlanıyor";
          whereRequestAwait: "";
          appData.approvalRequest = ar;
          */
        completedSteps: [],
        progress: 0,
        stateId: null,
        registrationType: null,
        email: null,
        isEmailValidated: false,
        isEsignRequested: false,
        memberships: [],
        taxNumber: null,
        exportType: null,
        sector: null,
        hsCode: null,
        hqCity: null,
        isOnlineUser: false,
        hasOnlineMembership: false,
        branchOfficeCities: [],
        hqAddress: null,
        branchOfficeAddress: null,
        validAssociations: null,
        selectedAssociation: null,
        applicant: null,
        companyInfo: {
          taxNumber: null,
          soleCompanyTaxNumber: null,
          companyName: null,
          taxOffice: {
            id: null,
            city: null,
            county: null,
            name: null,
            type: null
          },
          naceCodes: [],
          mersisNumber: null,
          tradeRegistrationType: null,
          tradeRegistrationOffice: null,
          tradeRegistrationNumber: null,
          companyType: {
            name: null,
            value: null,
            requiredContacts: [],
            parent: null
          },
          companyCategory: [],
          grossSales: null
        },
        contacts: [],
        emailAddresses: [],
        currentStep: {
          path: "",
          view: 0
        },
        previousStep: {
          path: "",
          view: ""
        },
        fromReview: false,
        createdOn: null,
        expires: null,
        missingDocumentDescription: null,
        missingDocuments: [],
        statusId: applicationStatus.HAZIRLANIYOR.value,
        createUserAccount: true,
        notificationSettings: []
      }
    };
  },
  mutations: {
    SET_ROUTE_STATE_ID: (state, payload) => {
      state.routeStateId = payload;
    },
    SET_PAGE_TITLE: (state, payload) => {
      state.pageTitle = payload;
    },
    SET_REGISTRATION_TYPE: (state, payload) => {
      state.registrationType = payload;
    },
    SET_IS_PAGE_LOADING: (state, payload) => {
      state.isPageLoading = payload;
    },
    SET_PAGE_LOADING_MESSAGE: (state, payload) => {
      state.pageLoadingMessage = payload;
    },
    UPDATE_STATE: (state, payload) => {
      Object.keys(payload).forEach((key) => {
        if (key in state) state[key] = payload[key];
      });
    },
    SET_APPLICATION_DATA: (state, payload) => {
      state.applicationData = payload;
    },
    UPDATE_APPLICATION_DATA: (state, payload) => {
      Object.keys(payload).forEach((key) => {
        if (key in state.applicationData)
          state.applicationData[key] = payload[key];
      });
    }
  },
  actions: {
    setRouteStateId: ({ commit }, payload) => {
      commit("SET_ROUTE_STATE_ID", payload);
    },
    setRegistrationType: ({ commit }, payload) => {
      let regType = settings.registrationTypes[payload];
      commit("SET_REGISTRATION_TYPE", regType);
      commit("SET_PAGE_TITLE", regType.title);
      commit("UPDATE_APPLICATION_DATA", { registrationType: regType.name });
    },
    showPageLoader: ({ commit }, payload) => {
      commit("SET_PAGE_LOADING_MESSAGE", payload || "");
      commit("SET_IS_PAGE_LOADING", true);
    },
    hidePageLoader: ({ commit }) => {
      commit("SET_IS_PAGE_LOADING", false);
    },
    updateState: ({ commit }, payload) => {
      commit("UPDATE_STATE", payload);
    },
    loadApplicationData: ({ dispatch }, { email, code }) => {
      return apiClient.loadApplication(email, code).then((response) => {
        if (
          response &&
          response.success &&
          response.data &&
          response.data.applicationData &&
          response.data.approvalRequest
        ) {
          const appData = response.data.applicationData || {};
          appData.approvalRequest = response.data.approvalRequest || {};
          appData.fromReview = false;
          dispatch("updateApplicationData", appData);

          local.token = response.data.au.t;
          local.refreshToken = response.data.au.r;

          return appData;
        } else {
          throw new Error(response);
        }
      });
    },
    updateApplicationData: ({ commit, getters }, payload) => {
      commit("UPDATE_APPLICATION_DATA", payload);
      const appData = getters.getApplicationData || {};

      commit("UPDATE_APPLICATION_DATA", {
        createdOn: !appData.createdOn ? moment().format() : appData.createdOn
      });

      if (appData.stateId && !appData.fromReview) {
        setLocalStorageState(getters.getApplicationData);
      }
    },
    markStep: ({ commit, getters }, { name, isCompleted }) => {
      const completedSteps = getters.getApplicationData?.completedSteps || [];

      const index = completedSteps.indexOf(name);

      if (isCompleted) {
        if (index < 0) completedSteps.push(name);
      } else {
        if (index >= 0) completedSteps.splice(index, 1);
      }

      commit("UPDATE_APPLICATION_DATA", { completedSteps });
    },
    findAssociationToRegister: (
      { commit },
      { hsCode, hqCity, branchOfficeCities }
    ) => {
      return new Promise((resolve, reject) => {
        const cities = [hqCity, ...branchOfficeCities];

        apiClient.findAssociationToRegister(hsCode, cities).then((response) => {
          if (response && response.success) {
            commit("UPDATE_APPLICATION_DATA", {
              hsCode,
              hqCity,
              branchOfficeCities: [...branchOfficeCities]
            });
            resolve(response);
          } else {
            reject(response);
          }
        });
      });
    }
  },
  getters: {
    getPageTitle: (state, getters) => {
      return settings.registrationTypes[
        getters.getApplicationData.registrationType
      ]?.title;
    },
    getRegistrationType: (state) => state.registrationType,
    hasRegistrationType: (state) => !!state.registrationType,
    getIsPageLoading: (state) => state.isPageLoading,
    getPageLoadingMessage: (state) => state.pageLoadingMessage,
    getTimOnlineUrl: () => "https://online.tim.org.tr",
    getApplicationData: (state) => {
      return state.applicationData;
    },
    getProgress: (state, getters) => {
      return (
        (state.applicationData.completedSteps.length * 100) /
        getters.getSteps.length
      );
    },
    getSteps: (state) => {
      const isBrokerApplication =
        state.applicationData.registrationType === "broker";

      const hasBranchAddress =
        !isBrokerApplication &&
        state.applicationData.selectedAssociation &&
        state.applicationData.selectedAssociation.isAppliedFromBranch;

      return steps.filter((step) => {
        if (!hasBranchAddress && step.name === "company-address-branch")
          return false;

        if (isBrokerApplication && step.name === "choose-association")
          return false;

        return true;
      });
    }
  }
});
