import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AppHeader from "./components/AppHeader.vue";
import Tooltip from "primevue/tooltip";
import CardWizard from "@/components/UI/CardWizard.vue";
import WizardButtons from "@/components/UI/WizardButtons.vue";
import TitleDesc from "@/components/UI/TitleDesc.vue";
import Card from "@/components/UI/Card.vue";
import AppFormGroup from "@/components/FormGroup.vue";
import AppDebugger from "@/components/Debugger.vue";
import ProgressDialog from "./components/ProgressDialog.vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import VueSweetalert2 from "vue-sweetalert2";
import Ripple from "primevue/ripple";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import Dropdown from "primevue/dropdown";
import Chips from "primevue/chips";
import AutoComplete from "primevue/autocomplete";
import * as moment from "moment";
import "moment/locale/tr.js";
import { VuelidatePlugin } from "@vuelidate/core";
import { numeric } from "@/directives/index.js";
import { mapActions, mapGetters } from "vuex";
import { UploadInstaller } from "@progress/kendo-upload-vue-wrapper";

moment.locale("tr");

const swal2options = {
  buttonsStyling: false,
  reverseButtons: true,
  customClass: {
    confirmButton: "btn btn-primary",
    confirmButtonText: "Tamam",
    cancelButton: "btn btn-outline-turquoise",
    cancelButtonText: "Vazgeç",
    loader: "custom-loader"
  }
};

const app = createApp(App)
  .use(VuelidatePlugin)
  .use(UploadInstaller)
  .use(store)
  .use(router)
  .use(VueSweetalert2, swal2options);

app
  .component("app-header", AppHeader)
  .component("app-title-desc", TitleDesc)
  .component("app-card", Card)
  .component("app-wizard-buttons", WizardButtons)
  .component("app-card-wizard", CardWizard)
  .component("app-progress-dialog", ProgressDialog)
  .component("app-form-group", AppFormGroup)
  .component("app-debugger", AppDebugger)
  .component("v-form", Form)
  .component("v-field", Field)
  .component("v-error-message", ErrorMessage)
  .component("p-dialog", Dialog)
  .component("p-progress-spinner", ProgressSpinner)
  .component("p-dropdown", Dropdown)
  .component("p-auto-complete", AutoComplete)
  .component("chips", Chips)
  .directive("tooltip", Tooltip)
  .directive("ripple", Ripple)
  .directive("numeric", numeric);

app.config.globalProperties.$primevue = { ripple: true };

app.config.globalProperties.$moment = moment;

app.mixin({
  methods: {
    isLetter(e) {
      var charCode = e.which ? event.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    updateStore($event, name, value) {
      name = name || $event.target.name;

      if (name) {
        this.updateApplicationData({
          [name]: value || $event.target.value
        });
      }
    },
    openUrl(url) {
      if (url) window.open(url);
    },
    redirect(url) {
      if (url) window.location.href = url;
    },
    goTo(route) {
      if (route) {
        if (Number.isInteger(route)) {
          this.$router.go(route);
        } else {
          this.$router.push(route);
        }
      }
    },
    scrollToError() {
      this.$nextTick(() => {
        const el = document.querySelector(".form-group--error");

        if (!el) return;

        const domRect = el.getBoundingClientRect();

        window.scrollTo(
          0,
          domRect.top - 120 + document.documentElement.scrollTop
        );
      });
    },
    markStepAsCompleted(step) {
      this.markStep({ name: step, isCompleted: true });
    },
    markStepAsInCompleted(step) {
      this.markStep({ name: step, isCompleted: false });
    },
    findRouteToGo() {
      const appData = this.applicationData || {};
      const ar = appData.approvalRequest;
      let path = "/status/";

      if (ar && ar.statusId) {
        switch (ar.statusId) {
          case 2101:
          case 2104:
            // Henüz dosya yüklenmemiş
            path = "/upload/";
            break;
        }
      }

      this.goTo(path + appData.stateId);
    },
    ...mapActions([
      "updateApplicationData",
      "markStep",
      "showPageLoader",
      "hidePageLoader"
    ])
  },
  computed: {
    isBrokerRegistration() {
      return this.applicationData.registrationType === "broker";
    },
    ...mapGetters({
      applicationData: "getApplicationData",
      token: "getToken"
    })
  }
});

app.mount("#app");
