import { email, required, helpers, numeric } from "@vuelidate/validators";
import { stripNumbers } from "./utils.js";

const errorMessages = {
  required: "Bu alan boş olamaz",
  select: "Bir seçim yapın",
  email: "Geçerli bir e-posta adresi belirtin",
  numeric: "Bu alan sadece sayı içermelidir",
  taxNumber: "Geçerli bir vergi numarası girin",
  hsCode: "Bir GTİP seçin",
  gsm:
    "Cep telefonu numarası başında sıfır olmadan 10 hane olarak girilmelidir",
  landLine:
    "Sabit hat/faks numarası cep telefonu alan kodu (5xx) ile başlayamaz",
  identityNumber: "T.C. kimlik numarası 11 haneli ve sadece rakam içermelidir",
  mersisNumber: "Mersis numarası 16 hane ve sadece rakam içermelidir",
  zipCode: "Geçerli bir posta kodu girin",
  emails: "En az 1 e-posta adresi belirtin",
  url: "Geçerli bir web adresi girin",
  atLeastOnePhone: "En az bir telefon numarası belirtin",
  kep: "Geçerli bir KEP adresi girin"
};

const isMobilePhone = (value) => {
  const val = stripNumbers(value || "");

  return helpers.req(val) && helpers.len(val) === 10 && val.startsWith("5");
};

const isLandLine = (value) => {
  const val = stripNumbers(value || "");

  return helpers.req(val) && helpers.len(val) === 10 && !val.startsWith("5");
};

const isEmail = (value) => {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

  return emailRegex.test(value);
};

const rules = {
  required: {
    required
  },
  select: {
    required
  },
  email: {
    required,
    email
  },
  taxNumber: {
    required,
    numeric,
    taxNumber(value) {
      return (
        helpers.req(value) &&
        helpers.len(value) >= 10 &&
        helpers.len(value) <= 11
      );
    }
  },
  taxNumberStrict: {
    required,
    numeric,
    taxNumber(value) {
      return helpers.req(value) && helpers.len(value) === 10;
    }
  },
  mersisNumber: {
    numeric,
    mersisNumber(value) {
      return !helpers.req(value) || helpers.len(value) === 16;
    }
  },
  hsCode: {
    required,
    hsCode(value) {
      return value && value.gtip ? true : false;
    }
  },
  gsm: {
    required,
    gsm: isMobilePhone
  },
  identityNumber: {
    required,
    numeric,
    identityNumber(value) {
      return helpers.req(value) && helpers.len(value) == 11;
    }
  },
  grossSales: {
    required,
    grossSales(value) {
      return helpers.req(value);
    }
  },
  zipCode: {
    numeric,
    zipCode(value) {
      return !helpers.req(value) || helpers.len(value) == 5;
    }
  },
  kep: {
    email,
    kep(value) {
      return !helpers.req(value) || value.endsWith("kep.tr");
    }
  },
  emails: {
    emails(value) {
      return helpers.req(value) && helpers.len(1);
    },
    $each: {
      email
    }
  },
  phones: {
    required,
    atLeastOnePhone(value) {
      return value.some(
        (x) => (x.type === "phone" || x.type === "gsm") && helpers.req(x.phone)
      );
    },
    gsm(values) {
      for (let i = 0; i < values.length; i++) {
        const { type, phone } = values[i];

        if (type === "gsm") {
          const _gsm = stripNumbers(phone);

          if (!(!helpers.req(_gsm) || isMobilePhone(phone))) return false;
        }
      }

      return true;
    },
    landLine(values) {
      for (let i = 0; i < values.length; i++) {
        const { type, phone } = values[i];

        if (type !== "gsm") {
          const _landLine = stripNumbers(phone);

          if (!(!helpers.req(_landLine) || isLandLine(phone))) return false;
        }
      }

      return true;
    }
  }
};

export { rules, errorMessages, isEmail };
