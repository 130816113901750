<template>
  <div class="home">
    <app-card class="card-clean">
      <app-title-desc title="BİRLİK ÜYELİĞİ BAŞVURUSU" class="dark lg">
        <p>İhracatçı birlikleri üyelik başvuru sistemine hoş geldiniz.</p>
        <div>
          Online başvuru sistemi üzerinden gerek ihracatçı firma kaydınızı
          gerekse gümrük müşaviri olarak kaydınızı gerçekleştirebilirsiniz.
        </div>
      </app-title-desc>
    </app-card>
    <app-card v-for="item in cards" v-bind:key="item">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <app-title-desc
          :title="item.title"
          :class="[item.titleClass]"
          style="max-width:440px;"
        >
          <span v-html="item.description"></span>
        </app-title-desc>
        <div class="pt-4 pt-md-0">
          <button
            style="width:215px"
            :class="['btn', item.buttonClass]"
            @click="start(item.route, item.registrationType)"
          >
            {{ item.buttonText }}
          </button>
          <div v-if="item.edevletButton">
            <a
              class="btn btn-danger"
              style="padding:8px 10px; width:215px; margin-top:10px;"
              v-if="showEdevletLogin"
              :href="eDevletLoginUrl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="30"
                viewBox="0 0 40 30"
              >
                <path
                  fill="#fff"
                  d="M31.671,0h0c3.662.206,6.6,1.29,7.375,4.386a7.216,7.216,0,0,1-.671,4.847,15.691,15.691,0,0,1-2.321,3.559,27.805,27.805,0,0,1-6.344,5.365,42.132,42.132,0,0,1-7.737,3.972,30.814,30.814,0,0,1-4.489,1.393,11.8,11.8,0,0,1-5.209.206c-1.6-.36-2.837-1.187-3.1-2.785a6.1,6.1,0,0,1,1.393-4.332,17.35,17.35,0,0,1,6.344-5.365A16.968,16.968,0,0,1,26.2,8.872,16.266,16.266,0,0,0,18.827,11.5c-1.806,1.187-3.97,2.941-4.641,5.365a2.6,2.6,0,0,0,1.444,3.3,9.436,9.436,0,0,0,4.384.413A18.8,18.8,0,0,0,27.543,18.1a20.676,20.676,0,0,0,7.842-7.221,12.7,12.7,0,0,0,1.5-3.509c.722-3.506-.981-5.416-3.507-6.188S27.131.825,24.552,1.5a35,35,0,0,0-13.1,6.912,35.3,35.3,0,0,0-5,5,23.035,23.035,0,0,0-3.713,6.24,9.737,9.737,0,0,0-.568,4.281c.516,4.127,4.849,5.365,9.749,5.106,6.19-.36,10.884-2.475,15.165-4.847A37.7,37.7,0,0,1,13.05,29.66C6.912,30.69.413,29.555,0,23.832V22.8a17.457,17.457,0,0,1,4.127-9.08,33.239,33.239,0,0,1,6.758-6.445,37.025,37.025,0,0,1,8.408-4.8A31.844,31.844,0,0,1,29.968,0h1.7m1.6,7.169c-.309.722-.774,1.5-1.134,2.269.981.1,2.063.1,3.094.206-1.237.465-2.526.878-3.816,1.292-.412.825-.876,1.65-1.29,2.477-.412-.516-.722-1.135-1.135-1.652L25.274,13c1.033-.722,2.063-1.5,3.1-2.219-.31-.514-.671-1.031-.979-1.547.72.051,1.442.1,2.166.1a3.785,3.785,0,0,0,.72.051,3.34,3.34,0,0,0,.774-.516c.724-.568,1.5-1.187,2.218-1.7"
                />
              </svg>

              <span>&nbsp;e-Devlet ile Giriş</span>
            </a>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { local, makeId, settings } from "../shared";
import apiClient from "../services";

export default {
  name: "Home",
  data() {
    return {
      eDevletLoginUrl: "#",
      showEdevletLogin: false,
      cards: [
        {
          name: "",
          registrationType: settings.registrationTypes.exporter.name,
          title: "İhracatçı Firma Başvurusu Yapın",
          description:
            "Bu adımdan imza yetkilisi/çalışanı olduğunuz veya <b>gümrük müşaviriyseniz</b> vekaletnamesine sahip olduğunuz bir firma için ihracatçı birliğine <b>yeni üyelik kaydı için</b> başvuru yapabilirsiniz.",
          buttonText: "Başvuruya Başla",
          titleClass: "primary-alt",
          buttonClass: "btn-primary-alt",
          route: "/validation",
          edevletButton: true
        },
        {
          name: "",
          registrationType: settings.registrationTypes.broker.name,
          title: "Gümrük Müşaviri Başvurusu Yapın",
          description:
            "Bu adımdan sadece gümrük müşaviri firmanızın e-birlik sistemine kayıt başvurusunu yapabilirsiniz. <b>(Yeni üye kaydı için kullanmayınız)</b>",
          buttonText: "Başvuruya Başla",
          titleClass: "turquoise",
          buttonClass: "btn-outline-turquoise",
          route: "/validation"
        },
        {
          name: "",
          title: "Mevcut Başvurunuza Devam Edin",
          registrationType: null,
          description:
            "Daha önce yapmış olduğunuz bir başvuruya devam etmek ya da durumunu sorgulamak için buradan devam edin. ",
          buttonText: "Başvuruya Devam Et",
          titleClass: "gold",
          buttonClass: "btn-outline-gold",
          route: "/continue"
        }
      ],
      startPayload: null
    };
  },
  methods: {
    start(route, regType) {
      if (regType) {
        this.setRegistrationType(regType);
        const stateId = makeId(128);

        this.updateApplicationData({ stateId });

        this.goTo(`${route}/${stateId}`);
      } else {
        this.goTo(route);
      }
    },
    ...mapActions(["setRegistrationType"])
  },
  beforeMount() {
    this.startPayload = this.$route.params.payload;

    apiClient.getEdevletLoginUrl().then((response) => {
      if (response && response.success && response.data) {
        this.eDevletLoginUrl = response.data;
        this.showEdevletLogin = true;
      } else {
        this.eDevletLoginUrl = false;
      }
    });

    if (!this.startPayload) {
      this.goTo("/");
    } else {
      this.showPageLoader("İşleminiz başlatılıyor...");

      apiClient
        .getUserToken(this.startPayload)
        .then((response) => {
          if (
            response &&
            response.success &&
            response.data &&
            response.data.token &&
            response.data.user
          ) {
            local.token = response.data.token;
            local.refreshToken = response.data.refreshToken;

            const user = response.data.user;

            const applicant = this.applicationData.applicant || {};
            applicant.fullName = user.fullName;
            applicant.identityNumber = user.identityNumber;
            applicant.gsm = user.gsm;
            applicant.email = user.email;
            applicant.type = user.type === "broker" ? "broker" : "company";

            this.updateApplicationData({
              email: user.email,
              isEmailValidated: true,
              isOnlineUser: true,
              applicant
            });

            this.markStepAsCompleted("validation");
            this.markStepAsCompleted("applicant");

            this.start(
              "/check-membership",
              settings.registrationTypes.exporter.name
            );

            console.log(response.data.token);
          } else {
            throw new Error("Invalid token");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.hidePageLoader();
        });

      console.log(this.startPayload);
    }
  }
};
</script>
