<template>
  <p-dialog
    class="app-progress-dialog"
    :visible="isLoading"
    :modal="true"
    :fill="fill"
    :showHeader="false"
  >
    <div class="text-center p-progress-dialog">
      <p-progress-spinner
        class="p-progress-spinner-primary"
        :style="{ width: size + 'px', height: size + 'px' }"
        :strokeWidth="strokeWidth"
        :animationDuration="animationDuration"
      />
      <div v-if="hasMessage" v-html="message"></div>
    </div>
  </p-dialog>
</template>

<script>
import PDialog from "primevue/dialog";
import PProgressSpinner from "primevue/progressspinner";

export default {
  name: "ProgressDialog",
  components: {
    PDialog,
    PProgressSpinner
  },
  props: {
    message: String,
    isLoading: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 50
    },
    strokeWidth: {
      type: String,
      default: "6"
    },
    animationDuration: {
      type: String,
      default: ".7s"
    },
    fill: String
  },
  computed: {
    hasMessage() {
      return !!this.message;
    }
  }
};
</script>

<style lang="scss">
@import "../scss/_variables";

.p-progress-dialog {
  .p-progress-spinner {
    .p-progress-spinner-svg {
      .p-progress-spinner-circle {
        animation-name: p-progress-spinner-dash, p-progress-spinner-primary;
      }
    }

    @keyframes p-progress-spinner-primary {
      0%,
      100% {
        stroke: #ffd;
      }
    }
  }
}
</style>
