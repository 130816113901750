import {
  cities,
  getCity,
  counties,
  getCounty,
  getCounties,
  tradeRegistrationOffices,
  taxOffices
} from "./cities.js";

const roles = {
  frmort: "Firma Ortağı",
  frmshb: "Firma Sahibi",
  gnlmdr: "Genel Müdür",
  gnlmdryrd: "Genel Müdür Yardımcısı",
  konsort: "Konsorsiyum Ortağı",
  srktmdr: "Şirket Müdürü",
  ykbaskan: "Yönetim Kurulu Başkanı",
  ykuye: "Yönetim Kurulu Üyesi"
};

const companyTypes = [
  {
    name: "Şahıs Firması",
    value: "shsfrm",
    requiredContacts: ["frmshb"],
    parent: null
  },
  {
    name: "Şirket / Tüzel Kişilik",
    parent: null,
    disabled: true
  },
  {
    name: "Adi Ortaklık",
    value: "adiortaklk",
    requiredContacts: ["srktmdr", "frmort"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Anonim",
    value: "anonim",
    requiredContacts: ["ykbaskan", "gnlmdr"],
    parent: "tuzel-kisilik"
  },
  {
    name: "İş Ortaklığı / Ortak Girişim",
    value: "ortaklik",
    requiredContacts: ["ykbaskan", "gnlmdr"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Joint Venture",
    value: "jointvent",
    requiredContacts: ["frmort", "frmort"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Kamu",
    value: "kamu",
    requiredContacts: ["gnlmdr", "gnlmdryrd"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Kollektif",
    value: "kollektif",
    requiredContacts: ["frmort", "frmort"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Komandit",
    value: "komandit",
    requiredContacts: ["frmort", "frmort"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Konsorsiyum",
    value: "konsorsym",
    requiredContacts: ["konsort", "konsort"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Kooperatif",
    value: "kooperatif",
    requiredContacts: ["ykbaskan", "ykuye"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Limited",
    value: "limited",
    requiredContacts: ["srktmdr", "frmort"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Yabancı Şahıs Firması",
    value: "ybncshsfrm",
    requiredContacts: ["frmshb"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Yabancı Firma İrtibat Bürosu",
    value: "yfirmirtbt",
    requiredContacts: ["frmort"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Vakıf",
    value: "vakif",
    requiredContacts: ["gnlmdr", "gnlmdryrd"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Holding",
    value: "holding",
    requiredContacts: ["ykbaskan"],
    parent: "tuzel-kisilik"
  },
  {
    name: "Diğer",
    value: "dgr",
    requiredContacts: ["frmshb"],
    parent: "tuzel-kisilik"
  }
];

const companyCategories = [
  { name: "İhracatçı", value: "ihracatci" },
  { name: "İmalatçı", value: "imalatçı" },
  { name: "İthalatçı", value: "ithalatçı" },
  { name: "Gümrük Müşaviri", value: "gumruk-musaviri" },
  { name: "Mali Müşavir", value: "mali-musavir" },
  { name: "Diğer", value: "diger" }
];

export default {
  registrationTypes: {
    exporter: {
      id: 1,
      name: "exporter",
      title: "İhracatçı Firma Üyelik Başvurusu"
    },
    broker: {
      id: 2,
      name: "broker",
      title: "Gümrük Müşaviri Üyelik Başvurusu"
    }
  },
  applicantTypes: {
    company: {
      id: 1,
      name: "company",
      title: "Firma İmza Yetkilisi/Çalışanı",
      description:
        "Eğer imza yetkilisi ya da çalışanı olduğunuz firma adına başvuruda bulunuyorsanız bu seçeneği seçin",
      allowed: true
    },
    broker: {
      id: 2,
      name: "broker",
      title: "Vekaleten Gümrük Müşaviri",
      description:
        "Eğer gümrük müşaviri iseniz ve başvurusunu yaptığınız firmadan alınmış geçerli bir vekaletiniz var ise bu seçeneği seçin. Gümrük Müşaviri olarka başka bir firma adına üyelik başvurusu yapabilmek için başvuru sürecini Online İşlemlerden başlatmanız gerekmektedir.",
      allowed: false
    },
    other: {
      id: 3,
      name: "other",
      title: "Vekaleten Diğer",
      description:
        "Eğer başvurusunu yapacağınız firma adına işlemleri başlatmak için geçerli bir vekaletnameniz varsa bu seçeneği seçmeniz gerekmektedir.",
      allowed: true
    }
  },
  exportTypes: [
    {
      id: 1,
      name: "mal",
      text: "Mal"
    },
    {
      id: 2,
      name: "hizmet",
      text: "Hizmet"
    }
  ],
  serviceSectors: [
    { id: 1, name: "", text: "Eğitim Hizmetleri" },
    { id: 2, name: "", text: "Eğlence ve Kültür Hizmetleri" },
    { id: 3, name: "", text: "Liman İşletmeciliği ve Yer Hizmetleri" },
    { id: 4, name: "", text: "Müteahitlik ve Teknik Müşavirlik Hizmetleri" },
    { id: 5, name: "", text: "Sağlık Hizmetleri" },
    { id: 6, name: "", text: "Turizm ve Seyahat ile İlgili Hizmetler" },
    { id: 7, name: "", text: "Yazılım ve Bilişim Hizmetleri" },
    { id: 8, name: "", text: "Yolcu Taşımacılığı Hizmetleri" },
    { id: 9, name: "", text: "Yük Taşımacılığı ve Lojistik Hizmetleri" },
    {
      id: 10,
      name: "",
      text: "Diğer İş Hizmetleri (Perakende, Gastronomi, Danışmanlık vb.)"
    }
  ],
  getCity,
  cities,
  companyTypes,
  validRoles: [
    {
      name: "Firma Ortağı", value: "frmort"
    },
    {
      name: "Firma Sahibi",
      value: "frmshb"
    },
    {
      name: "Genel Müdür", value: "gnlmdr"
    },
    {
      name: "Genel Müdür Yardımcısı", value: "gnlmdryrd"
    },
    {
      name: "Şirket Müdürü", value: "srktmdr"
    },
    {
      name: "YK Başkanı", value: "ykbaskan"
    },
    {
      name: "YK Başkan Yardımcısı", value: "ykbaskanyar"
    },
    {
      name: "YK Üyesi", value: "ykuye"
    }
  ]
};

export const applicationStatus = {
  HAZIRLANIYOR: {
    value: 2101,
    name: "Hazırlanıyor"
  },
  ONAY_BEKLIYOR: {
    value: 2102,
    name: "Onay bekliyor"
  },
  YENI_BASVURU: {
    value: 2103,
    name: "Yeni Başvuru"
  },
  EKSIK_EVRAK_BEKLENIYOR: {
    value: 2104,
    name: "Eksik Evrak Bekleniyor"
  },
  GIRIS_AIDATI_ODEMESI_VE_EVRAKLARIN_ORIJINALLERI_BEKLENIYOR: {
    value: 2105,
    name: "Giriş Aidatı Ödemesi ve Evrakların Orijinalleri Bekleniyor"
  },
  ONAYLANDI: {
    value: 2108,
    name: "Onaylandı"
  },
  REDDEDILDI: {
    value: 2109,
    name: "Reddedildi"
  }
};

export const errorMessages = {
  required: "Bu alan boş olamaz",
  email: "Geçerli bir e-posta adresi belirtin"
};

export {
  companyTypes,
  companyCategories,
  roles,
  cities,
  getCity,
  counties,
  getCounty,
  getCounties,
  tradeRegistrationOffices,
  taxOffices
};
