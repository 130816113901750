<template>
  <div class="form-group" :class="{ 'form-group--error': error }">
    <app-label :for="name + id" :help="help" v-if="label">{{
      label
    }}</app-label>
    <slot></slot>
    <div class="field-validation-error" v-if="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { errorMessages } from "../shared";
import AppLabel from "@/components/UI/Label.vue";

export default {
  components: {
    AppLabel
  },
  props: {
    validator: Object,
    name: {
      type: String,
      required: true
    },
    id: String,
    help: String,
    label: String,
    validationPath: String
  },
  computed: {
    $vl() {
      return this.$v || this.validator;
    },
    field() {
      if (!this.$vl) return null;

      const field =
        this.$vl[this.name] ||
        (this.$vl.modelValue ? this.$vl.modelValue[this.name] : null) ||
        (this.$vl.value && this.id ? this.$vl.value[this.id][this.name] : null);

      // const field = this.validationPath
      //   ? this.$vl[this.validationPath][this.name]
      //   : this.$vl[this.name] ||
      //     (this.$vl.modelValue ? this.$vl.modelValue[this.name] : null);

      return field;
    },
    error() {
      return this.field ? this.field.$error : false;
    },
    errorMessage() {
      return this.error
        ? errorMessages[this.field.$errors[0].$validator] ||
            this.field.$errors[0].$message
        : "";
    }
  }
};
</script>

<style></style>
