<template>
  <div class="card" :class="className">
    <slot name="pre"></slot>
    <div class="card-body">
      <slot></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<style lang="scss">
@import "../../scss/_mixins.scss";
@import "../../scss/_variables.scss";

.card {
  border: none;
  border-radius: $border-radius-lg;

  //overflow: hidden;
  margin-bottom: 30px;

  @include box-shadow();

  &.card-clean {
    box-shadow: none !important;
    background: transparent !important;
  }

  .card-body {
    padding: 27px 30px;
    border-radius: $border-radius-lg;
  }

  &.card-primary {
    background: $primary;
  }

  &.card-blue {
    background: $primary-alt;
  }

  &.card-gold {
    background: $gold;
  }

  &.card-turquoise {
    background: $turquoise;
  }

  &.card-danger {
    background: $danger;
  }

  .title-desc {
    .desc {
      color: rgba(0, 0, 0, 0.7);
      b,
      strong,
      .text-bold,
      .text-medium {
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  &.card-blue,
  &.card-gold,
  &.card-turquoise,
  &.card-primary,
  &.card-danger {
    color: #fff;
    .title-desc {
      .title {
        color: #fff;
      }

      .desc {
        color: rgba(255, 255, 255, 0.7);
        b,
        strong,
        .text-bold,
        .text-medium {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  &.card-title-lg {
    .title-desc {
      .title {
        font-weight: $font-weight-extra-light;
        font-size: 28px;
      }
    }
  }

  .title-lg.title-desc {
    .title {
      font-weight: $font-weight-extra-light;
      font-size: 28px;
    }
  }
}
</style>

<script>
export default {
  name: "card",
  props: {
    type: String
  },
  computed: {
    className() {
      return "card-" + this.type;
    }
  }
};
</script>
