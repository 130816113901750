import axios from "axios";
import { local, getEnvironment } from "../shared";

const settings = {
  apiServers: {
    //dev: "http://localhost:9999",
    dev: "https://localhost:5001",
    staging: "https://staging-online.tim.org.tr",
    production: "https://online.tim.org.tr"
  },
  apiBaseUrl: "/api/v1/external/membership-application"
};

const env = getEnvironment();

const _axios = axios.create({
  baseURL: settings.apiServers[env] + settings.apiBaseUrl,
  headers: { "Content-Type": "application/json;charset=utf-8" }
});

_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response?.data;
  },
  function(error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return _axios
        .post("/refresh-token", {
          email: null,
          code: local.refreshToken
        })
        .then(res => {
          if (res && res.success) {
            // 1) put token to LocalStorage
            local.token = res.data.token;
            local.refreshToken = res.data.refreshToken;

            // 2) Change Authorization header
            _axios.defaults.headers.common["Authorization"] =
              "Bearer " + local.token;

            // 3) return originalRequest object with Axios.
            return _axios(originalRequest);
          }
        });
    } else {
      return error?.response?.data;
    }
  }
);

_axios.interceptors.request.use(
  config => {
    const token = local.token;
    getEnvironment();
    if (token) config.headers["Authorization"] = "Bearer " + token;

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default {
  axios: _axios,
  sendValidationCode(email) {
    return _axios.post("/send-validation-code", { email });
  },
  getUserToken(payload) {
    return _axios.get("/get-user-token/" + payload);
  },
  validateEmailAndCode(email, code) {
    return _axios.post("/validate-code", { email, code });
  },
  checkMemberships(email, taxNumber, registrationType, soleCompanyTaxNumber) {
    return _axios.post("/check-membership", {
      email,
      taxNumber,
      registrationType,
      soleCompanyTaxNumber
    });
  },
  checkTradeRegistration(tradeRegistrationOffice, tradeRegistrationNumber) {
    return _axios.post("/get-company", {
      tradeRegistrationOffice,
      tradeRegistrationNumber
    });
  },
  searchHsCodes(query) {
    return _axios.get("/hs-codes?q=" + (query || ""));
  },
  searchNaceCodes(query, selectedItems) {
    return _axios.post("/nace-codes", { query, selectedItems });
  },
  findAssociationToRegister(hsCode, cities) {
    return _axios.post("/find-association", { hsCode, cities });
  },
  saveApplication(data) {
    return _axios.post("/save", data);
  },
  loadApplication(email, applicationCode) {
    return _axios.post("/load/", { email, code: applicationCode });
  },
  upload(url, file, onUploadProgress, additionalFormData) {
    let formData = new FormData();

    formData.append("files", file);

    if (additionalFormData) {
      Object.keys(additionalFormData).forEach(key => {
        formData.append(key, additionalFormData[key]);
      });
    }

    return _axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  },
  refreshToken(data) {
    return _axios.post("/refresh-token", data);
  },
  complete(id, files, esign) {
    return _axios.post("/complete", { id, files, esign });
  },
  getEdevletLoginUrl() {
    var returnUrl = "https://" + window.location.host + "/edl";

    return _axios.post("/get-edevlet-login-url", { returnUrl });
  },
  isEsignRequired(associationId) {
    const url = "/is-esign-requested/" + associationId;
    return _axios.get(url);
  }
};
