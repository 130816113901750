import { local } from "brownies";
import * as moment from "moment";

const getSavedStates = function() {
  let states = local.appData;

  if (!states) {
    local.appData = [];
    return [];
  } else {
    const validItems = [];

    for (let item of states) {
      if (moment(item.createdOn) > moment().subtract(1, "months")) {
        validItems.push(item);
      }
    }

    local.appData = validItems;
    return validItems;
  }
};

const getLocalStorageState = function(stateId) {
  return getSavedStates().find((state) => state.stateId === stateId);
};

const setLocalStorageState = function(appData) {
  const states = getSavedStates();
  const storeIndex = states.findIndex((x) => x.stateId === appData.stateId);

  if (storeIndex >= 0) {
    states[storeIndex] = appData;
  } else {
    states.push(appData);
  }

  local.appData = states;
};

const removeLocalStorageState = function(stateId) {
  const states = getSavedStates();
  const storeIndex = states.findIndex((x) => x.stateId === stateId);

  if (storeIndex >= 0) {
    states.splice(storeIndex, 1);
  }

  local.appData = states;
};

export {
  local,
  getSavedStates,
  getLocalStorageState,
  setLocalStorageState,
  removeLocalStorageState
};
