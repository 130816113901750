<template>
  <div class="container">
    <ul class="steps">
      <li
        v-for="(step, index) in steps"
        :key="step"
        :class="{ active: isActive(step), completed: isCompleted(step) }"
      >
        <div class="number">{{ index + 1 }}</div>
        <div class="title">{{ step.title }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  setup() {
    return {};
  },
  methods: {
    isCompleted(step) {
      //this.applicationData.compleded
      return this.applicationData.completedSteps.includes(step.name);
    },
    isActive(step) {
      return this.$router.currentRoute?.value?.name === step.name;
    }
  },
  computed: {
    currentRoute() {
      return this.$router.currentRoute?.value?.name;
    },
    ...mapGetters({
      steps: "getSteps"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/_variables";
@import "../../scss/_mixins";

ul.steps {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 50px;

  li {
    text-align: center;
    line-height: normal;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 10px;

    .number {
      width: 40px;
      height: 40px;
      line-height: 34px;
      border-radius: 50%;
      border: solid 4px #999;
      font-size: 16px;
      font-weight: $font-weight-bold;
      padding: 0;
      margin: 0 auto;
      background: #fff;
      margin-bottom: 5px;
    }

    .title {
      font-size: 12px;
      max-width: 100px;
      margin: 0 auto;
    }

    &.completed {
      color: $turquoise;

      .number {
        background-color: $turquoise;
        border-color: $turquoise;
        color: #fff;
      }
    }

    &.active {
      color: $primary;

      .number {
        background-color: $color-app-blue;
        border-color: $color-app-blue;
        color: #fff;
      }

      .title {
        padding: 3px 5px;
        background-color: $color-app-blue;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}
</style>
