<template>
  <app-card>
    <button class="btn btn-primary mb-4" @click="touch" v-if="validator">
      Validate
    </button>
    <div class="log">
      <pre>{{
        {
          model,
          validator
        }
      }}</pre>
      <pre></pre>
    </div>
  </app-card>
</template>

<script>
export default {
  props: ["validator", "model"],
  methods: {
    touch() {
      if (this.validator) this.validator.$touch();
    }
  }
};
</script>

<style scoped>
.log {
  background: #000;
  color: #fff;
  height: 600px;
  overflow: auto;
  padding: 20px;
  border: solid 2px #eee;
}

.log pre {
  color: #fff;
  opacity: 0.7;
}
</style>
