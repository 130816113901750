<template>
  <app-header :title="title"></app-header>
  <div class="app-content" v-cloak>
    <app-wizard-steps v-if="false"></app-wizard-steps>

    <div class="container">
      <router-view />
    </div>
    <app-progress-dialog
      :isLoading="isPageLoadingVisible"
      :message="pageLoadingMessage"
    ></app-progress-dialog>
    <app-debugger
      v-if="environment === 'dev'"
      :validator="validator"
      :model="{
        applicationData
      }"
    ></app-debugger>

    <div class="container">
      <div class="footer">
        <a href="https://tim.org.tr/tr/cerez-politikasi" target="_blank"
          >Çerez Politikası
        </a>
        <a href="https://tim.org.tr/tr/kvkk-aydinlatma-metni" target="_blank"
          >KVKK Aydınlatma Metni
        </a>
        <a
          href="https://tim.org.tr/tr/bilgi-guvenligi-politikasi"
          target="_blank"
          >Bilgi Güvenliği Politikası
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppWizardSteps from "./components/UI/WizardSteps.vue";
import { getEnvironment } from "./shared";

export default {
  name: "App",
  components: {
    AppWizardSteps
  },
  computed: {
    environment() {
      return getEnvironment();
    },
    isStepsVisible() {
      return this.$router.currentRoute.path != "home";
    },
    validator() {
      return this.$v;
    },
    ...mapGetters({
      title: "getPageTitle",
      isPageLoadingVisible: "getIsPageLoading",
      pageLoadingMessage: "getPageLoadingMessage"
    })
  }
};
</script>

<style lang="scss">
@import "./scss/styles";

#app {
  .container {
    max-width: 830px;
  }

  .app-content {
    padding-top: 140px;
  }

  .footer {
    display: flex;
    justify-content: center;
    font-size: 13px;
    a {
      color: rgba($color: #000000, $alpha: 0.7);
      display: block;
      margin: 10px;

      &:hover,
      &:active,
      &:focus {
        color: rgb(119, 0, 255);
      }
    }
  }
}
</style>
